import React from 'react';
import {AddCircle} from '@mui/icons-material';
import {Box, Grid, Pagination} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import FormButton, {FormButtonProps} from '../../form/form_inputs/form_button';

export interface ListButtonsProps<T> {
    appendable?: boolean;
    actions?: FormButtonProps[];
    onClick: string | ((bean: T) => void);
    totalPages?: number;
    setCurrentPage: (n: number) => void;
    currentPage?: number;
    onAppend?: () => void;
}


function ListButtons<T>({
                            appendable,
                            actions,
                            onClick,
                            totalPages,
                            currentPage,
                            onAppend,
                            setCurrentPage
                        }: ListButtonsProps<T>) {
    const navigate = useNavigate();

    return (
        <Box my={3}>
            <Grid container>
                {appendable && (
                    <Grid item>
                        <FormButton
                            color={"primary"}
                            label={"Añadir"}
                            icon={<AddCircle/>}
                            action={() => {
                                if (typeof onClick == "function") {
                                    if (onAppend) {
                                        onAppend();
                                    } else {
                                        alert("If onClick is a function, then you must provide a onAppend function");
                                    }
                                } else {
                                    navigate(`${onClick}/new`);
                                }
                            }}
                        />
                    </Grid>
                )}
                {actions?.map((action, i) => (
                    <Grid key={"act-btn-" + i} item>
                        <FormButton
                            color={action.color}
                            label={action.label}
                            icon={action.icon}
                            action={action.action}
                        />
                    </Grid>
                ))}
                <Grid item>
                    <Pagination
                        showFirstButton showLastButton
                        defaultPage={currentPage ? currentPage + 1 : undefined}
                        count={totalPages}
                        color="primary"
                        onChange={(event, pageNumber) => setCurrentPage(pageNumber - 1)}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}

export default ListButtons;