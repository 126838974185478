import {Evaluation, EvaluationRestControllerApiFactory} from "arteco-api-client-ts";
import AppList, {AppListConfig} from "../../../../components/layout/list";
import {useApiManager} from "../../../../components/utils/api";
import {Grid} from "@mui/material";
import {COLOR_DISMISSED} from "../../../../components/layout/colors";
import {useParams} from "react-router-dom";
import {dateToString} from "../../../../components/utils/dates";
import {parsePathId} from "../../../../components/form/form_utils";

export const EvaluationListPage = () => {
    const apiManager = useApiManager();
    const params = useParams();
    const personId = parsePathId(params, "personId");

    const getColor = (value: number): string => {
        if (value < 0) value = 0;
        if (value > 40) value = 40;
        // Interpolar el matiz de rojo (0°) a verde (120°)
        const hue = (120 * value) / 40;
        return `hsl(${hue}, 100%, 30%)`;
    }

    const listConfig: AppListConfig<Evaluation> = {
        title: "Listado de evaluaciones",
        onClick: personId
            ? "/app/admin/employee/person/" + personId + "/evaluation"
            : "/app/admin/employee/evaluation",
        decorator: (bean: Evaluation) => (
            <Grid
                container
                style={{color: bean.dismissed ? COLOR_DISMISSED : "inherit"}}>
                <Grid item sm={2}>
                    {dateToString(bean.evaluationDate)}
                </Grid>
                <Grid item sm={4}>
                    {bean.person.surnames + ", " + bean.person.name}
                </Grid>
                <Grid item sm={2}>
                    <div style={{color: getColor(bean.rating || 0)}}>
                        {bean.rating?.toFixed(2)}
                    </div>
                </Grid>
            </Grid>
        ),
        supplier: async (currentPage, size, term, showDismissed) => {
            const companyId = apiManager.companyId;
            const ctrl = apiManager.factory(EvaluationRestControllerApiFactory);
            return ctrl.pageEvaluation(companyId, currentPage, size, undefined, personId,
                undefined, undefined, undefined, term, showDismissed);
        },
    };

    return (
        <>
            <AppList config={listConfig}/>
        </>
    );
};