import {RedmineMappingRestControllerApiFactory, RedmineMapping} from "arteco-api-client-ts";
import AppList, {AppListConfig} from "../../../../components/layout/list";
import {COLOR_DISMISSED} from "../../../../components/layout/colors";
import {Grid} from "@mui/material";
import {useApiManager} from "../../../../components/utils/api";

export const RedmineMappingListPage = () => {
    const apiManager = useApiManager();

    const listConfig: AppListConfig<RedmineMapping> = {
        title: "Listado de Mapeos ",
        onClick: "/app/admin/billing/mapping",
        decorator: (bean: RedmineMapping) => (
            <Grid container style={{ color: bean.dismissed ? COLOR_DISMISSED : "" }}>
                <Grid item sm>
                    {bean.category}
                </Grid>
                <Grid item sm>
                    {bean.value}
                </Grid>
                <Grid item sm>
                    {bean.redmineValue}
                </Grid>
            </Grid>
        ),
        supplier: async (currentPage, size, term, showDismissed) => {
            const ctrl = apiManager.factory(RedmineMappingRestControllerApiFactory);
            return ctrl.pageRedmineMapping(
                apiManager.companyId,
                currentPage,
                size,
                undefined,
                term,
                showDismissed
            );
        },
    };

    return (
        <>
            <AppList config={listConfig} />
        </>
    );
};