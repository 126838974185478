import React, {useContext, useEffect, useState} from "react";
import {FormContext} from "../form_buttons";
import {Button, ButtonGroup, InputLabel} from "@mui/material";
import {TypeConf, useType} from "../../types";
import {Box} from "@mui/system";
import {fieldMarginBottom} from "../../form_constants";

interface FormSelectButtonsProp<T> {
    field: string;
    label: string;
    type: TypeConf<T>;
    multiple?: boolean;
    items?: T[];
    color?: "inherit" | "primary" | "secondary" | "error" | "info" | "success" | "warning";
    size?: 'small' | 'medium' | 'large'
}

function FormSelectButtons<T>(props: FormSelectButtonsProp<T>) {
    const {field, label, type, multiple, items, color, size} = props;

    const typer = useType(type, multiple);
    const typeManager = typer.typeManager;

    const [elements, setElements] = useState<any[]>(items || []);
    const formContext = useContext(FormContext);
    const model = formContext.form;

    const _modelField = model.field(field);

    const [selectedItems, setSelectedItems] = useState<any>(_modelField.valueAsItem(true) || []); // [string,..] (enum) || [{item},..]
    model.register(field, () => {
        return typeManager.finalValue(selectedItems);
    }, t => new Error("TBD"));

    useEffect(() => {
        if (!items) {
            typer.supplier().then((array) => setElements(array));
        }
    }, [type]);

    return (
        <Box className={[ "form-input", "form-input-" + field].join(" ")} mb={fieldMarginBottom}>
            {elements && (
                <Box mb={4}>
                    <InputLabel id={field + "-lbl"} style={{paddingBottom: "7px"}}>
                        {label}
                    </InputLabel>
                    <ButtonGroup variant="contained" color={color} aria-label={label} fullWidth>
                        {elements &&
                            elements.length &&
                            elements.map((item, index) => (
                                <Button

                                    size={size}
                                    key={"btn-group-" + field + "-btn-" + index}
                                    fullWidth
                                    color={
                                        typeManager.selected(selectedItems, item)
                                            ? color
                                            : "inherit"
                                    }
                                    onClick={() => {
                                        const newSelection = typeManager.select(
                                            selectedItems,
                                            item
                                        );
                                        setSelectedItems(newSelection);
                                    }}>
                                    <Box sx={{
                                        p:0,
                                        textOverflow: "ellipsis",
                                        width: "100%",
                                        overflow:"hidden",
                                        whiteSpace: "nowrap"
                                    }} title={type.labeler(item)}>
                                        <Box sx={{ display: { xs: 'inline', sm: 'none' } }}>{index}</Box> {type.labeler(item)}
                                    </Box>

                                </Button>
                            ))}
                    </ButtonGroup>
                </Box>
            )}
        </Box>
    );
}

export default FormSelectButtons;
