import {BillDiscount, BillDiscountRestControllerApiFactory} from "arteco-api-client-ts";
import AppList, {AppListConfig} from "../../../../components/layout/list";
import {useApiManager} from "../../../../components/utils/api";
import {Grid} from "@mui/material";


export const BillDiscountListPage = () => {
	const apiManager = useApiManager();
	const ctrl = apiManager.factory(BillDiscountRestControllerApiFactory);

	const listConfig: AppListConfig<BillDiscount> = {
		title: "Listado de Descuentos",
		onClick: "/app/admin/billing/discount",
		decorator: (bean: BillDiscount) => (
			<Grid container>

				<Grid item sm={4}>
					{bean.name || "---"}
				</Grid>

			</Grid>
		),
		supplier: async (currentPage, size, term, showDismissed) => {
			return ctrl.pageBillDiscount(
				apiManager.companyId,
				currentPage,
				size,
				undefined,
				term,
				showDismissed
			);
		}
	};

	return (
		<>
			<AppList config={listConfig}/>
		</>
	);
};
