import React, {useEffect, useState} from "react";
import {Person, Support, SupportPoint, SupportRestControllerApiFactory, SupportResult} from "arteco-api-client-ts";
import {FormContent} from "../../../../components/form/form_inputs/form_buttons";
import {FormInput} from "../../../../components/form/form_inputs/form_input";
import {types, useType} from "../../../../components/form/types";
import {Grid, Typography} from "@mui/material";
import {useForm} from "../../../../components/form/form_model";
import {useApiManager} from "../../../../components/utils/api";
import {useParams} from "react-router-dom";
import FormAutocomplete from "../../../../components/form/form_inputs/form_autocomplete";
import {useAuthManager} from "../../../../components/utils/auth";
import {AxiosResponse} from "axios";
import FormRating from "../../../../components/form/form_inputs/form_rating";
import FormError from "../../../../components/form/form_inputs/form_error";
import {parsePathId} from "../../../../components/form/form_utils";


export const SupportEditPage = () => {
	const authManager = useAuthManager();
	const params = useParams();
	const apiManager = useApiManager();

	const ctrl = apiManager.factory(SupportRestControllerApiFactory);

	const personId = parsePathId(params, "personId");
	const [selectedPersonId, setSelectedPersonId] = useState<number | undefined>(personId);


	const form = useForm<Support>({
		fetcher: (id) => {
			const prom = ctrl.getSupport(id);
			prom.then((resp: AxiosResponse<Support>) => {
				setSelectedPersonId(resp.data.person?.id);
				return resp;
			});
			return prom;
		},
		updater: (bean) => ctrl.saveSupport(bean),
		remover: (id) => ctrl.deleteOrDismissSupport(id),
		factory: () => {
			return {
				modifier: {id: authManager.getUserId()},
				person: {id: personId} as Person,
				evaluationDate: new Date().toISOString(),
				evaluator: '',
				points: {}
			} as Support;
		},
	});

	return (
		<>
			<FormContent form={form} title={"Ficha de la evaluación"}>

				<SupportFormShared personId={personId}
								   setSelectedPersonId={id => setSelectedPersonId(id)}/>

			</FormContent>
		</>
	);
};

export interface SupportFormSharedProps {
	personId: number | undefined;
	hideSupportDate?: boolean;
	showMandatoryInfo?: boolean;
	setSelectedPersonId?: (id: number | undefined) => void,
}

export const SupportFormShared = ({
									  hideSupportDate = false,
									  showMandatoryInfo = false,
									  personId,
									  setSelectedPersonId,
								  }: SupportFormSharedProps) => {

	const [supportPoints, setSupportPoints] = useState<SupportPoint[]>();
	const [supportResults, setSupportResults] = useState<SupportResult[]>();
	const supportPointType = useType(types.supportPoint);
	const supportResultType = useType(types.supportResult);

	useEffect(() => {
		supportPointType.supplier().then((points) => setSupportPoints(points));
		supportResultType.supplier().then((results) => setSupportResults(results));
	}, []);

	return (
		<>
			<Grid container spacing={3}>
				<Grid item sm>
					{!personId && (
						<FormAutocomplete field={"person"} label={"Persona"} type={types.people}
										  onChange={(type, value) => setSelectedPersonId && setSelectedPersonId(value?.id)}/>
					)}
				</Grid>
				<Grid item sm={3}>
					{!hideSupportDate && (
						<FormInput field={"evaluationDate"} type={"date"} label={"Fecha"}/>
					)}
				</Grid>
			</Grid>

			<FormInput field={"evaluator"} type={"text"} label={"Evaluador"}/>

			<Grid container spacing={3}>
				<Grid item>
					<Typography variant={"h5"} sx={{marginBottom: "1em"}}>
						Preguntas puntuadas {showMandatoryInfo ? "(obligatorio)" : ""}
					</Typography>
				</Grid>
				<Grid item>
					<FormError field={"points"}/>
				</Grid>
			</Grid>

			{supportPoints && supportResults &&
				supportPoints.map((point, pointIdx) => (

					<FormRating key={"point-" + pointIdx}
								type={types.supportResult}
								typeValues={supportResults}
								field={"points." + point.id}
								label={point.label}/>

				))}


			<FormInput type={"text"}
					   field={"comments"}
					   label={"Comentarios"}
					   placeholder={""}
					   rows={2}/>

		</>
	);
}