import React from "react";
import {Accordion, AccordionDetails, Box, List} from "@mui/material";
import {UserAuthItemTypeEnum} from "arteco-api-client-ts";
import {useLocation} from "react-router-dom";
import {ROLE_MAP} from "./components/layout/navbar";
import {useAuthManager} from "./components/utils/auth";
import {EmployeeMenu} from "./route_employee";
import {AdminBillingMenu} from "./route_admin_billings";
import {AdminEmployeeMenu} from "./route_admin_employee";
import {AdminRecruitmentMenu} from "./route_admin_recruitment";
import {AdminMenu} from "./route_admin";
import {SuperMenu} from "./route_super";
import {useLastEvaluation} from "./pages/employee/employee_util";


const SideBar = () => {
	const location = useLocation();
	const authManager = useAuthManager()
	const [forceEvaluation, setForceEvaluation] = useLastEvaluation();

	const checkIfStart = (role: UserAuthItemTypeEnum): boolean => {
		const roleInfo = ROLE_MAP.get(role);
		if (roleInfo && roleInfo.target) {
			return location.pathname.startsWith(roleInfo.target);
		}
		return false;
	}

	const showSuper = checkIfStart(UserAuthItemTypeEnum.SUPERADMIN);
	const showEmployee = checkIfStart(UserAuthItemTypeEnum.EMPLOYEE);
	const showEmployeeManager = authManager.getAuthorities().find(ai => ai.type == UserAuthItemTypeEnum.EMPLOYEE_MANAGER);
	const showAdminBilling = checkIfStart(UserAuthItemTypeEnum.ADMIN_BILLING);
	const showAdminEmployee = checkIfStart(UserAuthItemTypeEnum.ADMIN_EMPLOYEE);
	const showAdminRecruitment = checkIfStart(UserAuthItemTypeEnum.ADMIN_RECRUITMENT);
	const showAdmin = location.pathname.startsWith("/app/admin/company");

	return (

		<Box px={1}>
			{showEmployee && (
				<Accordion expanded={true}>
					<AccordionDetails>
						<List component={"div"} style={{width: "100%"}}>
							<EmployeeMenu showEmployeeManager={showEmployeeManager}
										  forceEvaluation={forceEvaluation}/>
						</List>
					</AccordionDetails>
				</Accordion>
			)}

			{showAdminBilling && (
				<Accordion expanded={true}>
					<AccordionDetails>
						<List component={"div"} style={{width: "100%"}}>
							<AdminBillingMenu/>
						</List>
					</AccordionDetails>
				</Accordion>
			)}

			{showAdminEmployee && (
				<Accordion expanded={true}>
					<AccordionDetails>
						<List component={"div"} style={{width: "100%"}}>
							<AdminEmployeeMenu/>
						</List>
					</AccordionDetails>
				</Accordion>
			)}

			{showAdminRecruitment && (
				<Accordion expanded={true}>
					<AccordionDetails>
						<List component={"div"} style={{width: "100%"}}>
							<AdminRecruitmentMenu/>
						</List>
					</AccordionDetails>
				</Accordion>
			)}

			{showAdmin && (
				<Accordion expanded={true}>
					<AccordionDetails>
						<List component={"div"} style={{width: "100%"}}>
							<AdminMenu/>
						</List>
					</AccordionDetails>
				</Accordion>
			)}

			{showSuper && (
				<Accordion expanded={true}>
					<AccordionDetails>
						<List component={"div"} style={{width: "100%"}}>
							<SuperMenu/>
						</List>
					</AccordionDetails>
				</Accordion>
			)}
		</Box>

	);
}

export default SideBar;