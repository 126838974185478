const ALERT_CLASS = "alert";
const ALERT_DANGER_CLASS = "alert-danger";
const ALERT_VALIDATION_CLASS = "alert-validation";
const MUI_ERROR_CLASS = "Mui-error";

export interface FormError {
    code: string;
    defaultMessage: string;
    field?: string;
    rejectedValue?: string;
    label?: string;
}

export function manageFieldError(error: FormError, formErrors: FormError[]): boolean {
    if (!error.field) {
        return false;
    }
    const input = document.getElementById(error.field);
    if (input) {
        appendErrorDivUnder(input, error);
    } else {
        // se busca el uso de FormError para pintar errores no asociados a inputs
        const formCustomErrors = document.getElementsByClassName("form_error_" + error.field);
        const formCustomError =
            formCustomErrors.length > 0 ? (formCustomErrors.item(0) as HTMLElement) : undefined;
        if (formCustomError) {
            appendErrorDivUnder(formCustomError, error);
        } else {
            console.error("No input/select/textarea/... found for id=" + error.field);
            formErrors.push(error);
        }
    }
    return true;
}

function appendErrorDivUnder(input: HTMLElement, error: FormError) {
    // se busca el input asociado para pintar un error debajo
    const parent = input.closest(".form-input");
    if (parent) {
        parent.classList.add(MUI_ERROR_CLASS);
        const elems = parent.querySelectorAll("*");
        if (elems) {
            elems.forEach((elem) => {
                elem.classList.add(MUI_ERROR_CLASS);
            });
        }
        error.label = parent.querySelector("label")?.innerHTML || error.field;
        const fieldMessage = document.createElement("div");
        fieldMessage.classList.add(ALERT_CLASS, ALERT_DANGER_CLASS, ALERT_VALIDATION_CLASS);
        fieldMessage.innerHTML = error.defaultMessage;
        parent.appendChild(fieldMessage);
    } else {
        const msg = "No parent of type 'FormControl' found for input with id = " + error.field;
        console.error(msg);
    }
}

export function manageFormErrors(formId: string, formErrors: FormError[]) {
    const form = document.getElementById(formId + "_errs");
    if (form) {
        formErrors.map((err) => {
            const alert = document.createElement("div");
            alert.classList.add(ALERT_CLASS, ALERT_DANGER_CLASS, ALERT_VALIDATION_CLASS);
            alert.innerHTML =
                (err.field ? "<b>" + (err.label || err.field) + "</b>: " : "") + err.defaultMessage;
            form.appendChild(alert);
        });
    } else {
        console.error("No form found for id=" + formId);
    }
}

export function manageErrors(errors: FormError[], formId: string) {
    const formErrors = Array<FormError>();
    if (errors.length > 0) {
        errors.map((error, idx) => {
            if (!manageFieldError(error, formErrors)) {
                formErrors.push(error);
            }
        });
    }
    if (formErrors.length == 0) {
        const generalError: FormError = {
            code: "generic",
            defaultMessage: "El formulario contiene errores. Por favor revise los valores.",
        };
        formErrors.push(generalError);
    }
    if (formErrors.length > 0) {
        manageFormErrors(formId, formErrors);
    }
}

export function removePreviousErrors() {
    let validations: HTMLCollectionOf<Element> =
        document.getElementsByClassName(ALERT_VALIDATION_CLASS);
    while (validations.length > 0) {
        validations[0].remove();
        validations = document.getElementsByClassName(ALERT_VALIDATION_CLASS);
    }
    const divs: HTMLCollectionOf<Element> = document.getElementsByClassName(MUI_ERROR_CLASS);
    for (let i = 0; i < divs.length; i++) {
        const val: Element = divs[i];
        val.classList.remove(MUI_ERROR_CLASS);
    }
}
