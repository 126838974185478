import {Grid} from "@mui/material";
import SideBar from "../../sidebar";
import {Outlet} from "react-router-dom";
import * as React from "react";


const SidebarLayout = () => {
    return (
        <>
            <Grid container wrap={"nowrap"}>
                <Grid item >
                    <SideBar/>
                </Grid>
                <Grid item xs sx={{paddingRight:1}}>
                    <Outlet/>
                </Grid>
            </Grid>
        </>
    );
}

export default SidebarLayout;