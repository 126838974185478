import {useEffect, useState} from "react";
import {TypeConf} from "./types";
import {OptionsObject, SnackbarMessage, useSnackbar} from "notistack";
import {AxiosPromise, AxiosResponse} from "axios";
import {FormError, manageErrors, removePreviousErrors} from "./form_errors";
import {v4 as uuidv4} from "uuid";
import {NavigateFunction, useNavigate, useParams} from "react-router-dom";
import {FormButtonProps} from "./form_inputs/form_button";
import {parsePathId} from "./form_utils";

export interface Identifiable {
    id?: number;
}

type ModelIdFetcher = () => number | undefined;

type Promisable<T> = T | Promise<T> | AxiosPromise<T>;


export interface UseFormProps<T> {

    /**
     * Función que indica de dónde coger el id.
     * Si no se indica ninguno va a buscarlo a Params
     */
    modelIdFetcher?: ModelIdFetcher;

    /**
     * En el caso que se coja el id de los Params
     * puede indicarse el nombre diferente a 'id'
     */
    idParamName?: string;

    /**
     * Método por el que crear una instancia nueva
     * que pasar al formulario. Independiente de si
     * hay o no id.
     */
    factory: () => Promisable<T>;

    /**
     * Acción que se ejecutará para guardar un registro
     * en base de datos.
     * @param bean
     */
    updater: (bean: T) => Promisable<T>;

    /**
     * Método a utilizar si viene un id informado
     * @param bean
     */
    fetcher?: (id: number) => Promisable<T>;

    /**
     * Acción a realizar cuando se desee dar de baja o borrar un registro
     * @param bean
     */
    remover?: (id: number) => Promisable<void>;

    /**
     * Método que se ejecutará si está informado, tras la ejecución del fetcher.
     * @param bean
     */
    postLoad?: (bean: T) => void;

    /**
     * Método que se ejecutará si está informado, tras la ejecución del update.
     * @param bean
     */
    postUpdate?: (bean: T) => void;

    /**
     * Método que se ejecutará si está informado, tras la ejecución del update.
     * @param bean
     */
    preUpdate?: (bean: T) => boolean;

    /**
     * Método que se ejecutará si está informado, tras la ejecución del remove.
     * @param bean
     */
    postRemove?: (bean: T) => void;

    /**
     * Aspecto del botón por defecto que llamará a updater
     */
    defaultAction?: FormButtonProps;

    /**
     * Aspecto del botón por defecto que llamará a remover, si hay remover func indicado
     */
    removeAction?: FormButtonProps;

    extraActions?: FormButtonProps[];
}


export function useForm<T extends Identifiable>(props: UseFormProps<T>): FormModel<T> {
    return new FormModel<T>(props);
}

class PropState<T> {
    public readonly value: T;
    public readonly set: (t: T) => void;

    constructor(initialValue: T) {
        const [getter, setter] = useState<T>(initialValue);
        this.value = getter;
        this.set = setter;
    }
}


class FormModel<T extends Identifiable> {

    readonly config: UseFormProps<T>;

    readonly formId = uuidv4();
    readonly enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject) => void;

    private readonly fields: Map<string, () => any> = new Map<string, () => any>();
    private readonly fieldsSets: Map<string, (t: any) => void> = new Map<string, (t: any) => void>();

    public readonly propModel: PropState<T | undefined>;
    private readonly navigate: NavigateFunction;

    constructor(formConfig: UseFormProps<T>) {
        const {enqueueSnackbar} = useSnackbar();
        this.navigate = useNavigate();
        const params = useParams();

        this.enqueueSnackbar = enqueueSnackbar;

        this.propModel = new PropState<T | undefined>(undefined);
        this.config = formConfig;

        if (!this.config.modelIdFetcher) {
            this.config.modelIdFetcher = () => parsePathId(params, formConfig.idParamName);
        }

        useEffect(() => {
            this.load();
        }, []);


        this.config.defaultAction = formConfig.defaultAction === undefined
            ? {
                label: "Guardar",
                action: () => this.save(),
            }
            : formConfig.defaultAction;
        this.config.removeAction =
            formConfig.removeAction === undefined && typeof formConfig.remover === "function"
                ? {
                    label: "Borrar",
                    action: () => this.remove(),
                }
                : formConfig.removeAction;
    }


    private static lastNamePart(fieldName: string): string {
        const lastPoint = fieldName.lastIndexOf(".");
        if (lastPoint >= 0) {
            return fieldName.substr(lastPoint + 1);
        }
        return fieldName;
    }

    private static locateLastParent(entity: any, fieldName: string): any {
        if (!entity) entity = {};
        const path: string[] = fieldName.split(".");
        let root = entity;
        for (let i = 0; i < path.length - 1; i++) {
            const p = path[i];
            root = entity[p];
        }
        if (!root) {
            root = {};
        }
        return root;
    }


    setter(newModel: T) {
        this.propModel.set(newModel);
    }

    register(field: string, getter: () => any, setter: (value: any) => void) {
        if (!getter) {
            throw new Error("Getter is not set");
        }
        if (!setter) {
            throw new Error("Setter is not set");
        }
        if (typeof getter !== "function") {
            throw new Error("Getter is not a function");
        }
        this.fields.set(field, getter);
        this.fieldsSets.set(field, setter);
    }

    /**
     * Obtiene todos los estados de cada uno de los inputs y actualiza
     * el modelo interno del form.
     */
    updateModel(): T {
        const $this = this;
        const newBean = {...this.propModel.value} as T;
        this.fields.forEach((value: () => any, fieldName: string) => {
            const getter = $this.fields.get(fieldName);
            const parent = FormModel.locateLastParent(newBean, fieldName);
            const lastPart = FormModel.lastNamePart(fieldName);
            if (typeof getter !== "function") {
                throw fieldName + "'s getter is not a function";
            } else {
                parent[lastPart] = getter();
            }
        });
        $this.propModel.set(newBean);
        return newBean;
    }

    field(name: string): FormField {
        return new FormField(name, this);
    }

    value(fieldName: string): any {
        let parent = FormModel.locateLastParent(this.propModel.value, fieldName);
        const lastPart = FormModel.lastNamePart(fieldName);
        return parent[lastPart];
    }

    valueSet(fieldName: string, value: any): void {
        let parent = FormModel.locateLastParent(this.propModel.value, fieldName);
        const lastPart = FormModel.lastNamePart(fieldName);
        parent[lastPart] = value;
    }

    hasContent(): boolean {
        return this.propModel.value != null;
    }

    hasId(): boolean {
        return this.hasContent() && typeof this.propModel.value?.id === "number";
    }

    getId(): number | undefined {
        return this.propModel.value?.id;
    }

    getIdOrErr(): number {
        const id = this.propModel.value?.id;
        if (!id) {
            throw new Error("No model id found ");
        }
        return id;
    }

    withId(func: (id: number) => void) {
        const id = this.getId();
        if (id) {
            func(id);
        }
    }

    isLoading(): boolean {
        return !this.hasContent();
    }

    save(): Promise<T> {
        removePreviousErrors();
        const model = this.updateModel();
        const allow = this.config.preUpdate ? this.config.preUpdate(model) : true;
        if (allow) {
            const promisable: Promisable<T> = this.config.updater(model);
            const result = this.resolvePromisable(promisable);
            result
                .then((r) => {
                    if (this.config.postUpdate) {
                        this.config.postUpdate(r)
                    }
                    this.propModel.set(r);
                    this.showMessageOk()
                })
                .catch(err => this.showMessageErr(err));
            return result;
        } else {
            return new Promise<T>((resolve, reject) => {
                reject("preUpdate function disabled operation");
            });
        }
    }

    load(): Promise<T> {
        let promisable: Promisable<T>;
        if (this.config.fetcher && this.config.modelIdFetcher) {
            const regId = this.config.modelIdFetcher();
            if (regId) {
                promisable = this.config.fetcher(regId);
            } else {
                promisable = this.config.factory();
            }
        } else {
            promisable = this.config.factory();
        }

        const promise : Promise<T> =  this.loadForced(promisable);
        promise.then(t => {
            this.setter(t);
            return t;
        });
        return promise;
    }

    loadForced(promisable: Promisable<T>) : Promise<T> {
        this.propModel.set(undefined);
        const result = this.resolvePromisable(promisable);
        result.then(r => {
            this.propModel.set(r);
            this.fieldsSets.forEach((setter: (t: any) => void, fieldName: string) => {
                const parent = FormModel.locateLastParent(r, fieldName);
                const lastPartName = FormModel.lastNamePart(fieldName);
                const newValue = parent[lastPartName];
                setter(newValue);
            });

            if (this.config.postLoad) {
                this.config.postLoad(r as T)
            }
        });
        return result;
    }

    private resolvePromisable<K>(promisable: Promisable<K>): Promise<K> {
        return new Promise<K>((resolve, reject) => {
            // Si es una Promise = Promise | AxiosPromise
            if (promisable && (promisable as any)['then']) {
                // Si es una promesa, puede ser simple o de tipo Axios
                const promise = promisable as Promise<K | AxiosResponse<K>>;
                promise.then(resp => {
                    if ((resp as any).status) {
                        // se trata de axios response
                        const axiosResp = resp as AxiosResponse<K>;
                        resolve(axiosResp.data);
                    } else {
                        // se trata de promesa simple
                        resolve(resp as K);
                    }
                }, (err) => reject(err));
            } else {
                // si no es una promesa, el objeto está devuelto
                resolve(promisable as K);
            }
        });
    }

    remove(): Promise<void> {
        const modelId = this.propModel.value?.id;
        if (this.config.remover
            && modelId
            && confirm("Seguro que desea borrar el registro?")) {
            return this.resolvePromisable(this.config.remover(modelId))
                .then((_) => {
                    if (this.config.postRemove && this.propModel.value) {
                        this.config.postRemove(this.propModel.value);
                    } else {
                        this.navigate(-1);
                    }
                });
        }
        console.error("Model id is null");
        return new Promise<void>((resolve, reject) => reject());
    }

    private showMessageOk() {
        this.enqueueSnackbar("Operación realizada correctamente");
    }

    private showMessageErr(err: any) {
        let causa = "[Revise las validaciones]";
        if (err.response?.status >= 500) {
            causa = "[" + err.response.data.error + "]";
        } else if (err.response?.data) {
            // Si es un error de petición (normalmente validación):
            const errors: FormError[] = err.response.data;
            manageErrors(errors, this.formId);
        }
        this.enqueueSnackbar("Error en la operación. " + causa, {variant: "error"});
    }
}

export class FormField {
    name: string;
    model: FormModel<any>;

    constructor(name: string, model: any) {
        this.name = name;
        this.model = model;
    }

    value(): any {
        return this.model.value(this.name);
    }

    valueAsBoolean(): boolean {
        return !!this.value();
    }

    valueAsDate(): string {
        let result = this.value();
        if (result === undefined) {
            result = null;
        }
        return result;
    }

    valueAsType(type: TypeConf<any>) {
        return this.valueFromType(type, false);
    }

    valueAsItem(asArray: boolean) {
        let result: any = this.value();
        if (asArray && result && !result.map) {
            result = [result];
        }
        return result;
    }

    private valueFromType(type: TypeConf<any>, label: boolean) {
        let result: any = null;
        if (type.isEnum) {
            result = this.value();
        } else {
            const item = this.value();
            if (item) {
                result = label ? type.labeler(item) : type.valuer(item);
            }
        }
        return result;
    }
}

export default FormModel;
