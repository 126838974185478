import React from "react";

import {useApiManager} from "../../../../components/utils/api";
import {SupportPoint, SupportPointRestControllerApiFactory} from "arteco-api-client-ts";

import {FormContent} from "../../../../components/form/form_inputs/form_buttons";
import {FormInput} from "../../../../components/form/form_inputs/form_input";
import {Grid} from "@mui/material";
import {useForm, UseFormProps} from "../../../../components/form/form_model";

export const SupportPointEditPage = () => {
    const apiManager = useApiManager();
    const ctrl = apiManager.factory(SupportPointRestControllerApiFactory);

    const form = useForm({
        fetcher: (id) => ctrl.getSupportPoint(id),
        updater: (bean) => ctrl.saveSupportPoint(bean),
        remover: (id) => ctrl.deleteOrDismissSupportPoint(id),
        factory: () => {
            return {
                company: {
                    id: apiManager.companyId,
                },
            } as SupportPoint;
        },
    } as UseFormProps<SupportPoint>);

    return (
        <>
            <FormContent form={form} title={"Pregunta de evaluación"}>
                <Grid container spacing={3}>
                    <Grid item sm={4}>
                        <FormInput type={"text"} field={"position"} label={"Posición"} />
                    </Grid>
                    <Grid item sm>
                        <FormInput type={"text"} field={"label"} label={"Pregunta"} />
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item sm>
                        <FormInput
                            type={"text"}
                            field={"description"}
                            label={"Descripción"}
                            rows={3}
                        />
                    </Grid>
                </Grid>
            </FormContent>
        </>
    );
};