import React, {useContext, useState} from "react";
import {FormContext} from "../form_buttons";

import {Box, Grid, Rating, Typography} from "@mui/material";
import {TypeConf} from "../../types";
import {FormField, Identifiable} from "../../form_model";

interface FormRatingProp<T> {
    field: string;
    label: string;
    disabled?: boolean;
    onChange?: (value: any) => void;
    max?: number;
    type?: TypeConf<T>;
    typeValues?: T[];
}


function modelToValue<T extends Identifiable>(modelField: FormField,
                                              type: TypeConf<T> | undefined,
                                              typeValues: T[]): number | null {
    const v = modelField.value() as T | number | null | undefined;
    return itemOrNumberToNumber(v, type, typeValues);
}

function valueToModel<T extends Identifiable>(value: number | null,
                                              type: TypeConf<T> | undefined,
                                              typeValues: T[]): T | number | undefined | null {
    if (type && value && typeValues) {
        return typeValues[value - 1];
    } else {
        return value;
    }
}

function locateType<T extends Identifiable>(value: T,
                                            typeValues: T[]): [T, number] | undefined {
    if (typeValues) {
        let idx = -1;
        let elem: T | undefined = undefined;
        let i;
        for (i = 0; i < typeValues.length; i++) {
            elem = typeValues[i];
            if (elem && elem.id == value.id) {
                idx = i;
                break;
            }
        }
        if (idx >= 0 && elem) {
            return [elem, i];
        }
    } else {
        throw "No type values provided";
    }
}

function itemOrNumberToNumber<T extends Identifiable>(value: T | null | undefined | number,
                                                      type: TypeConf<T> | undefined,
                                                      typeValues: T[]): number | null {
    let result: number | null = null;
    if (!value) {
        result = null;
    } else if (type && (value as T).id) {
        const tuple = locateType(value as T, typeValues);
        let idx = null;
        if (tuple) {
            idx = tuple[1] + 1;
        }
        result = idx;
    } else if (typeof value == "number") {
        result = value as number;
    } else {
        throw "Value is not type or number";
    }
    return result;
}


function FormRating<T extends Identifiable>({
                                                field,
                                                label,
                                                disabled,
                                                onChange,
                                                max,
                                                type,
                                                typeValues = []
                                            }: FormRatingProp<T>) {
    const formContext = useContext(FormContext);
    const form = formContext.form;
    const modelField = form.field(field);

    if (type && typeValues?.length == 0) {
        throw "TypeValues are required when type is set";
    }

    const [value, setValue] = useState<number | null>(modelToValue(modelField, type, typeValues));
    form.register(field, () => valueToModel(value, type, typeValues), t => itemOrNumberToNumber(t, type, typeValues));

    const printLabel = () => {
        if (value && type) {
            const elem = typeValues[value - 1];
            if (elem) {
                return type.labeler(elem);
            }
        }
        return "";
    }

    return (
        <Box my={3}
             className={["form-input", "form-input-" + field].join(" ")}>
            <Typography component="legend">{label}</Typography>
            <Grid container spacing={3}>
                <Grid item xs={2}>
                    <Rating max={type ? typeValues?.length : max}
                            disabled={disabled}
                            value={value}
                            id={field}
                            onChange={(event, newValue) => {
                                setValue(newValue);
                                if (onChange) {
                                    onChange(newValue);
                                }
                            }}
                    />
                </Grid>
                {type && value && (
                    <Grid item>
                        <Box sx={{fontSize:"0.8em"}} color={"gray"}>{printLabel()}</Box>
                    </Grid>
                ) || ""}
            </Grid>


        </Box>
    );
}

export default FormRating;
