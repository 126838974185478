import React, {useState} from "react";
import {WikiPage, WikiPageRestControllerApiFactory, WikiPageTypeEnum} from "arteco-api-client-ts";
import {FormContent} from "../../../../components/form/form_inputs/form_buttons";
import {FormInput} from "../../../../components/form/form_inputs/form_input";
import FormFile from "../../../../components/form/form_inputs/form_file";
import {useForm, UseFormProps} from "../../../../components/form/form_model";
import {useApiManager} from "../../../../components/utils/api";
import {types} from "../../../../components/form/types";
import FormSelect from "../../../../components/form/form_inputs/form_select";
import {useAuthManager} from "../../../../components/utils/auth";
import {Button, ButtonGroup, Grid, Link} from "@mui/material";
import FormToggle from "../../../../components/form/form_inputs/form_toggle";
import WikiPageRender from "../../../../components/utils/wiki_page";
import LinkIcon from '@mui/icons-material/Link';

export const SECTION_HR = "rrhh";
export const SECTION_RECRUITMENT = "recruitment";
export const SECTION_BILLING = "billing";
export const SECTION_SYSTEM = "system";

export interface WikiPageEditPageProps {
    section: string;
}

export const WikiPageEditPage = ({section}: WikiPageEditPageProps) => {
    const authManager = useAuthManager();
    const apiManager = useApiManager();
    const ctrl = apiManager.factory(WikiPageRestControllerApiFactory);
    const companyId = apiManager.companyId;

    const [pageType, setPageType] = useState<string | number | undefined>(undefined);
    const [content, setContent] = useState<string>("");


    const form = useForm({
        fetcher: (id) => ctrl.getWikiPage(id),
        updater: (bean) => ctrl.saveWikiPage(bean),
        remover: (id) => ctrl.deleteOrDismissWikiPage(id),
        postLoad: (bean) => {
            setPageType(bean.type);
            setContent(bean.content);
        },
        factory: () => {
            return {
                modifier: {id: authManager.getUserId()},
                company: {id: companyId},
                section: section,
                type: WikiPageTypeEnum.PLAIN_TEXT
            } as WikiPage;
        },
    } as UseFormProps<WikiPage>);

    return (
        <>
            <FormContent form={form} title={"Ficha de Wiki de [" + section + "]"}>

                <FormInput field={"title"} type={"text"} label={"Título"}
                           helperText={"Modificado por: " + (form.propModel.value?.modifier?.username || "---")}/>

                <Grid container spacing={3}>
                    <Grid item sm>
                        <FormInput field={"code"} type={"text"} label={"Código"}
                                   helperText={"Para referenciar a esta página desde el Portal del Empleado. Debe estar visible"}/>
                    </Grid>
                    <Grid item sm={3}>
                        <FormSelect field={"type"} label={"Tipo"} type={types.wikiPageType}
                                    onChange={t => t && setPageType(t.value)}/>
                    </Grid>
                    <Grid item sm={3}>
                        <FormToggle field={"visible"} label={"Visible"}/>
                    </Grid>
                </Grid>


                {pageType == "MARKDOWN" && (
                    <Grid container spacing={3}>
                        <Grid item sm sx={{borderRight: "1px solid gray"}}>
                            <ButtonGroup variant="contained" aria-label="outlined primary button group"
                                         sx={{marginBottom: "1em"}}>
                                <Button onClick={_ => {
                                    const lnk = document.getElementById("linkMarkdown");
                                    if (lnk) lnk.click();
                                }}>Markdown</Button>
                                <Button onClick={_ => {
                                    alert("Puedes hacer un enlace a otra página con:\n[subpágina](/app/employee/wiki/rrhh/emp.learning.subpage)");
                                }}>ENLACES</Button>
                            </ButtonGroup>
                            <a target={"_blank"}
                               id={"linkMarkdown"}
                               href={"https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet"}></a>
                            <FormInput
                                type={"text"}
                                field={"content"}
                                label={"Contenido"}
                                inputProps={{style: {fontFamily: 'fixed'}}}
                                placeholder={""}
                                rows={30}
                                onChange={e => setContent(e as string || "")}
                            />
                        </Grid>
                        <Grid item sm>
                            <WikiPageRender content={content} type={WikiPageTypeEnum.MARKDOWN}/>
                        </Grid>
                    </Grid>
                )}
                {pageType != "MARKDOWN" && (
                    <FormInput
                        type={"text"}
                        field={"content"}
                        inputProps={{style: {fontFamily: 'fixed'}}}
                        label={"Contenido"}
                        placeholder={""}
                        rows={30}
                    />
                )}

                <Grid container spacing={3}>
                    <Grid item sm={5}>
                        <Grid container spacing={1}>
                            <Grid item xs>
                                <FormInput
                                    type={"text"}
                                    field={"linkA"}
                                    label={"Enlace 1"}
                                    placeholder={""}
                                />
                            </Grid>
                            {form.propModel.value?.linkA && (
                                <Grid item xs={1}>
                                    <Link href={form.propModel.value?.linkA} target={"_blank"} sx={{pt: 3}}>
                                        <LinkIcon/>
                                    </Link>
                                </Grid>
                            )}
                        </Grid>

                        <Grid container spacing={1}>
                            <Grid item xs>
                                <FormInput
                                    type={"text"}
                                    field={"linkB"}
                                    label={"Enlace 2"}
                                    placeholder={""}
                                />
                            </Grid>
                            {form.propModel.value?.linkB && (
                                <Grid item xs={1}>
                                    <Link href={form.propModel.value?.linkB} target={"_blank"} sx={{pt: 3}}>
                                        <LinkIcon/>
                                    </Link>
                                </Grid>
                            )}
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs>
                                <FormInput
                                    type={"text"}
                                    field={"linkC"}
                                    label={"Enlace 3"}
                                    placeholder={""}
                                />
                            </Grid>
                            {form.propModel.value?.linkC && (
                                <Grid item xs={1}>
                                    <Link href={form.propModel.value?.linkC} target={"_blank"} sx={{pt: 3}}>
                                        <LinkIcon/>
                                    </Link>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item sm={7}>
                        <FormFile
                            field={"attachmentA"}
                            label={"Archivo 1"}
                            onDownload={() =>
                                apiManager.executeRaw(ctrl.downloadAttachmentWikiPage(form.getIdOrErr(), 1))
                            }
                        />

                        <FormFile
                            field={"attachmentB"}
                            label={"Archivo 2"}
                            onDownload={() =>
                                apiManager.executeRaw(ctrl.downloadAttachmentWikiPage(form.getIdOrErr(), 2))
                            }
                        />

                        <FormFile
                            field={"attachmentC"}
                            label={"Archivo 3"}
                            onDownload={() =>
                                apiManager.executeRaw(ctrl.downloadAttachmentWikiPage(form.getIdOrErr(), 3))
                            }
                        />
                    </Grid>
                </Grid>


            </FormContent>
        </>
    );
};