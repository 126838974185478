import {ApiManager} from "../../../../components/utils/api";
import {
    OccupationMonth,
    OccupationMonthStats,
    OccupationPeopleDay,
    OccupationPersonDayV2,
    OccupationPersonYear,
    OccupationRestControllerApiFactory,
    OccupationStats,
    OccupationV2,
} from "arteco-api-client-ts";

export interface PersonOccupation {
    personId: number;
    monthStats: OccupationMonthStats;
    yearStats: OccupationStats;
    personCalendar: Map<string, PersonOccupationDay>;
    month: number;
}

export interface PersonOccupationDay {
    personDay: OccupationPersonDayV2;
    peopleDay: OccupationPeopleDay;
}

export interface OccupationProcessed {
    months: string[];
    peopleByMonth: Map<string, OccupationPersonYear[]>;
    occupationByPeopleByMonth: Map<string, Map<number, PersonOccupation>>;
}

const resolvePersonMonthOccupation = (
    occupation: OccupationV2,
    selectedMonth: string | undefined,
    personId: number | undefined
): OccupationMonthStats | undefined => {
    if (occupation.occupationByMonth && personId && selectedMonth) {
        const month = occupation.occupationByMonth[selectedMonth];
        if (month && month.occupationByPerson) {
            return month.occupationByPerson[personId.toString()];
        }
    }
    return undefined;
};

const resolvePeopleByMonth = (occupation: OccupationV2, months: string[]): Map<string, OccupationPersonYear[]> => {
    const peopleByMonth = new Map<string, OccupationPersonYear[]>();
    months.forEach((month) => {
        if (occupation.occupationByMonth && occupation.occupationByMonth[month]) {
            const personIds = Object.keys(occupation.occupationByMonth[month].occupationByPerson || {});
            const peopleOfMonth: OccupationPersonYear[] = (occupation.people || [])
                .filter((p) => p.personId && personIds.indexOf(p.personId.toString()) > -1)
                .filter((p) => {
                    const pm = resolvePersonMonthOccupation(occupation, month, p.personId);
                    return pm && pm.netIngress && pm.netIngress > 0;
                });
            peopleByMonth.set(month, peopleOfMonth);
        }
    });
    return peopleByMonth;
}

const processResponse = (occupation: OccupationV2): OccupationProcessed => {
    const months = (Object.keys(occupation.occupationByMonth || {}));
    const peopleByMonth = resolvePeopleByMonth(occupation, months);

    const occupationByPeopleByMonth = new Map<string, Map<number, PersonOccupation>>();
    months.forEach((month) => {
        const occupationPeopleYear = peopleByMonth.get(month);
        const occupationByPeople = new Map<number, PersonOccupation>();
        occupationByPeopleByMonth.set(month, occupationByPeople);
        if (occupationPeopleYear) {
            occupationPeopleYear.forEach((occupationPersonYear) => {
                const personId = occupationPersonYear.personId;
                const yearStats = occupationPersonYear.yearStats;
                if (personId && yearStats && occupation.occupationByMonth && occupation.occupationByMonth[month]) {
                    const personCalendar = new Map<string, PersonOccupationDay>();
                    const monthStats = resolvePersonMonthOccupation(occupation, month, personId);
                    if (monthStats) {
                        const monthGroup: OccupationMonth = occupation.occupationByMonth[month] || {};
                        Object.keys(monthGroup.occupationByDate || {}).map((date) => {
                            const peopleDay = monthGroup.occupationByDate ? monthGroup.occupationByDate[date] : {};
                            const personDay = peopleDay.occupationByPerson ? peopleDay.occupationByPerson[personId] : {};
                            personCalendar.set(date, {personDay, peopleDay});
                        });
                        occupationByPeople.set(personId, {personId, monthStats, yearStats, personCalendar, month: parseInt(month)});
                    }
                }
            });
        }

    });

    return {months, peopleByMonth, occupationByPeopleByMonth} as OccupationProcessed;
}

export const loadAndProcessOccupation = (apiManager: ApiManager, year: number): Promise<OccupationProcessed> => {
    const ctrl = apiManager.factory(OccupationRestControllerApiFactory);
    const companyId = apiManager.companyId;
    return apiManager.execute(ctrl.occupation(companyId, undefined, year))
        .then((occupation) => {
            return processResponse(occupation);
        });
}