import {Params} from "react-router-dom";

export function parseParamId(params: URLSearchParams,
                             idParamName?: string,
                             undefinedIfMissing?: boolean): number | undefined {
    let ids = idParamName ? params.get(idParamName) : params.get("id");
    return parseId(ids, undefinedIfMissing);
}

export function parsePathId(params: Params,
                            idParamName?: string,
                            undefinedIfMissing?: boolean): number | undefined {

    let ids = idParamName ? params[idParamName] : params.id;
    return parseId(ids, undefinedIfMissing);
}

function parseId(ids: string | undefined | null,
                 undefinedIfMissing?: boolean): number | undefined {
    const defValue = undefinedIfMissing ? undefined : null;
    let id: number | undefined | null = defValue;
    if (Array.isArray(ids)) {
        id = parseInt(ids[0]);
    } else if (ids) {
        id = parseInt(ids);
    }
    if (id && isNaN(id)) {
        id = defValue;
    }
    if (id === null) {
        id = undefined;
    }
    return id;
}