import {SupportResult, SupportResultRestControllerApiFactory} from "arteco-api-client-ts";
import AppList, {AppListConfig} from "../../../../components/layout/list";
import {COLOR_GRIS} from "../../../../components/layout/colors";
import {Grid} from "@mui/material";
import {useApiManager} from "../../../../components/utils/api";

export const SupportResultListPage = () => {
	const apiManager = useApiManager();
	const ctrl = apiManager.factory(SupportResultRestControllerApiFactory);

	const listConfig: AppListConfig<SupportResult> = {
		title: "Respuestas de evaluación",
		onClick: "/app/admin/employee/supportresult",
		decorator: (bean: SupportResult) => (
			<Grid container style={{color: bean.dismissed ? COLOR_GRIS : ""}}>
				<Grid item sm>
					{bean.label}
				</Grid>
				<Grid item sm>
					{bean.level}
				</Grid>
			</Grid>
		),
		supplier: async (currentPage, size, term, showDismissed) => {
			const companyId = apiManager.companyId;
			return ctrl.pageSupportResult(companyId, currentPage, size, undefined, term, showDismissed);
		},
	};

	return (
		<>
			<AppList config={listConfig}/>
		</>
	);
};