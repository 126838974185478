import {Grid, Typography} from "@mui/material";
import AdminInner from "../../../components/layout/admin_inner";
import {H1, Typographies} from "../../../components/utils/typography_consts";
import {useEffect, useState} from "react";
import {useApiManager} from "../../../components/utils/api";
import {BillDiscountRestControllerApiFactory, DiscountSummary} from "arteco-api-client-ts";
import {numberToString} from "../../../components/utils/numbers";

export const AdminBillingPage = () => {
	const apiManager = useApiManager();
	const ctrl = apiManager.factory(BillDiscountRestControllerApiFactory);

	const [discountSummary, setDiscountSummary] = useState<DiscountSummary | undefined>(undefined);
	const [year, setYear] = useState<number>(new Date().getFullYear());

	useEffect(() => {
		apiManager.execute(ctrl.getSummaryBillDiscount(year, apiManager.companyId))
		.then(resp => {
			setDiscountSummary(resp)
		})
	}, []);

	return (
		<AdminInner>

			<Typography mb={3} variant={Typographies.h4} component={H1}>
				Bienvenido al Módulo de Facturación
			</Typography>

			<b>DESCUENTOS {year}</b>
			<Grid container spacing={3}>
				{discountSummary?.lines?.map(ds => (
					<Grid item>
						{ds.customerShortName}
						<hr/>
						<table>
							<tr>
								<td align={"right"}>
									<b>Facturas</b><br/>
									{ds.billNum} &nbsp;
								</td>
								<td align={"right"}>
									<b>Importe<sup><del>IVA</del></sup></b><br/>
									{numberToString(ds.totalAmount)} €
								</td>

							</tr>
							<tr>
								<td align={"right"}>
									<b>Tipo</b><br/>
									{ds.discountPct} %
								</td>
								<td align={"right"}>
									<b>Ahorro</b><br/>
									{numberToString(ds.discountSavings|| 0)} €
								</td>

							</tr>
						</table>


					</Grid>
				))}

			</Grid>

		</AdminInner>
	);
};