import {Document, DocumentRestControllerApiFactory} from "arteco-api-client-ts";
import AppList, {AppListConfig} from "../../../../components/layout/list";
import {useApiManager} from "../../../../components/utils/api";
import {Grid} from "@mui/material";
import {dateToString} from "../../../../components/utils/dates";

export const DocumentListPage = () => {
	const apiManager = useApiManager();

	const listConfig: AppListConfig<Document> = {
		title: "Documentos ",
		onClick: "/app/admin/employee/document",
		decorator: (bean: Document) =>
			<Grid container>
				<Grid item sm>{dateToString(bean.modified)}</Grid>
				<Grid item sm>{bean.title}</Grid>
			</Grid>,
		supplier: async (currentPage, size, term, showDismissed) => {
			const companyId = apiManager.companyId;
			const ctrl = apiManager.factory(DocumentRestControllerApiFactory);
			return ctrl.pageDocument(companyId, currentPage, size, undefined, term, showDismissed);
		},
	};

	return (
		<>
			<AppList config={listConfig}/>
		</>
	);
};