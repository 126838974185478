import {Typography} from "@mui/material";
import {H1, Typographies} from "../../components/utils/typography_consts";
import AdminInner from "../../components/layout/admin_inner";

export const AdminPage = () => {
    return (
        <AdminInner>

            <Typography variant={Typographies.h3} component={H1}>
                Bienvenido al Administrador
            </Typography>
            <Typography variant={"body1"}>
                Selecciona una opción del menú lateral para comenzar
            </Typography>

        </AdminInner>
    );
};