import {Grid} from "@mui/material";
import {FormInput} from "../../components/form/form_inputs/form_input";
import React from "react";

export interface  CandidateSalaryProps {
    mode : "c" | "s";
}

export const CandidateSalary = ({mode } : CandidateSalaryProps ) => {
    return (
        <Grid container spacing={3}>
            {mode == "c" && (
                <Grid item xs>
                    <FormInput
                        field={"reqAvgHourlyPrice"}
                        label={"Precio medio por hora deseado €"}
                        helperText={"Importe sin comas, puntos ni decimales"}
                        inputProps={{
                            type: "number",
                            min: "3",
                            max: "150",
                            step: "1",
                            placeholder: "10 €",
                            pattern: "^[-d]d*$",
                            onKeyDown: (event: any) => {
                                if (
                                    event.key.length > 1 ||
                                    (event.key !== "-" &&
                                        event.key !== "," &&
                                        event.key !== "." &&
                                        !event.currentTarget.value.length) ||
                                    /\d/.test(event.key)
                                )
                                    return;
                                event.preventDefault();
                            },
                        }}
                    />
                </Grid>
            )}
            <Grid item xs>
                <FormInput
                    field={"reqAnnualPayroll"}
                    label={"Salario anual bruto deseado €"}
                    helperText={"Importe sin comas, puntos ni decimales"}
                    inputProps={{
                        type: "number",
                        min: "10000",
                        max: "150000",
                        step: "1",
                        placeholder: "14000 €",
                        pattern: "^[-d]d*$",
                        onKeyDown: (event: any) => {
                            if (
                                event.key.length > 1 ||
                                (event.key !== "-" &&
                                    event.key !== "," &&
                                    event.key !== "." &&
                                    !event.currentTarget.value.length) ||
                                /\d/.test(event.key)
                            )
                                return;
                            event.preventDefault();
                        },
                    }}
                />
            </Grid>
            {mode =="c" && (
                <Grid item xs>
                    <FormInput
                        field={"adviceDays"}
                        label={"Días de preaviso"}
                        type={"number"}
                        helperText={"Cuántos días necesitas para incorporarte en Arteco"}
                    />
                </Grid>
            )}

        </Grid>
    )
}