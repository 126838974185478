import React from "react";
import {Holiday, HolidayRestControllerApiFactory} from "arteco-api-client-ts";
import {FormContent} from "../../../../components/form/form_inputs/form_buttons";
import {FormInput} from "../../../../components/form/form_inputs/form_input";
import {Grid} from "@mui/material";
import {useForm, UseFormProps} from "../../../../components/form/form_model";
import {useApiManager} from "../../../../components/utils/api";
import {types} from "../../../../components/form/types";
import FormSelect from "../../../../components/form/form_inputs/form_select";

export const HolidayEditPage = () => {
    const manager = useApiManager();
    const ctrl = manager.factory(HolidayRestControllerApiFactory);
    const companyId = manager.companyId;

    const form = useForm({
        fetcher: (id) => ctrl.getHoliday(id),
        updater: (bean) => ctrl.saveHoliday(bean),
        remover: (id) => ctrl.deleteOrDismissHoliday(id),
        factory: () => {
            return { company: { id: companyId } } as Holiday;
        },
    } as UseFormProps<Holiday>);

    return (
        <>
            <FormContent form={form} title={"Ficha de Día Festivo"}>
                <Grid container spacing={3}>
                    <Grid item sm={3}>
                        <FormSelect field={"calendar"} type={types.calendars} label={"Calendario"}/>
                        <small>Si no se indica, se refiere a un festivo global</small>
                    </Grid>
                    <Grid item sm={3}>
                        <FormInput field={"holidayDate"} type={"date"} label={"Fecha"} />
                    </Grid>
                    <Grid item sm={6}>
                        <FormInput type={"text"} field={"description"} label={"Descripción"} />
                    </Grid>
                </Grid>
            </FormContent>
        </>
    );
};