//Public urls

export const LOGIN_URL = "/login";
export const ABOUT_URL = "/sobre-nosotros";
export const PROJECTS_URL = "/proyectos";
export const POSTS_URL = "/post";
export const CONTACT_URL = "/contacto";
export const CANDIDATE_URL = "/candidato";
export const PRIVACY_URL = "https://www.arteco-consulting.com/privacidad";
export const HOME_URL = "/";
export const HOME_ARTECO = "https://www.arteco-consulting.com";

// Private urls

export const ADMIN_URL = "/admin";
export const SELECT_ROLE_URL = "/app";
export const SUPER_ADMIN_URL = `${ADMIN_URL}/super`;
export const PROFILE_URL = "/app/user/profile";
export const EMPLOYEE_URL = "/employee";

export const ADMIN_HOME = "https://admin.arteco-consulting.com";