import {Route} from "react-router-dom";
import * as React from "react";
import {EmployeeLayout} from "./pages/employee/layout";
import {EmployeePage} from "./pages/employee";
import {EmployeeDirectoryPage} from "./pages/employee/directory";
import {EmployeeInfoPage} from "./pages/employee/info";
import {EmployeePayrollPage} from "./pages/employee/payroll";
import {EmployeeVacationPage} from "./pages/employee/vacation";
import {EmployeeManagerPage} from "./pages/employee/manager";
import {EmployeeDocumentListPage} from "./pages/employee/document";
import EmployeeUploadsPage from "./pages/employee/uploads";
import {EmployeeLearningPage} from "./pages/employee/learning";
import {EmployeeEvaluationPage} from "./pages/employee/evaluation";
import {EmployeeWorkBooking} from "./pages/employee/workbooking";
import EmployeeWikiRender from "./pages/employee/wiki";
import SideBarItem from "./sidebar_item";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import {AccountCircle, Bathtub, Description, Payment} from "@mui/icons-material";
import GroupsIcon from "@mui/icons-material/Groups";
import StyleIcon from "@mui/icons-material/Style";
import ThermostatIcon from "@mui/icons-material/Thermostat";
import SchoolIcon from "@mui/icons-material/School";
import FolderIcon from "@mui/icons-material/Folder";
import {UserAuth} from "arteco-api-client-ts";


const EmployeeRoutes = () => {
	return (
		<Route path="/app/employee" element={<EmployeeLayout/>}>
			<Route index element={<EmployeePage/>}/>
			<Route path="/app/employee/directory" element={<EmployeeDirectoryPage/>}/>
			<Route path="/app/employee/info" element={<EmployeeInfoPage/>}/>
			<Route path="/app/employee/payroll" element={<EmployeePayrollPage/>}/>
			<Route path="/app/employee/vacation" element={<EmployeeVacationPage/>}/>
			<Route path="/app/employee/manager" element={<EmployeeManagerPage/>}/>
			<Route path="/app/employee/document" element={<EmployeeDocumentListPage/>}/>
			<Route path="/app/employee/uploads" element={<EmployeeUploadsPage/>}/>
			<Route path="/app/employee/learning" element={<EmployeeLearningPage/>}/>
			<Route path="/app/employee/evaluation" element={<EmployeeEvaluationPage/>}/>
			<Route path="/app/employee/workbooking" element={<EmployeeWorkBooking/>}/>
			<Route path="/app/employee/wiki/:section/:code"
				   element={<EmployeeWikiRender/>}/>
		</Route>
	);
}

export default EmployeeRoutes;

export interface EmployeeMenuProps {
	showEmployeeManager: UserAuth | undefined;
	forceEvaluation: boolean
}

export const EmployeeMenu = ({showEmployeeManager, forceEvaluation}: EmployeeMenuProps) => {
	return (
		<>
			{!forceEvaluation && (
				<>
					<SideBarItem
						href={"/app/employee/directory"}
						label={"Directorio"}
						icon={<ContactMailIcon/>}
					/>
					<SideBarItem
						href={"/app/employee/info"}
						label={"Mis datos"}
						icon={<AccountCircle/>}
					/>
					{/* no es listado */}
					<SideBarItem
						href={"/app/employee/payroll"}
						label={"Nóminas"}
						icon={<Payment/>}
					/>
					<SideBarItem
						href={"/app/employee/vacation"}
						label={"Tus Ausencias"}
						icon={<Bathtub/>}
					/>
					{showEmployeeManager && (
						<SideBarItem
							href={"/app/employee/manager"}
							label={"Operaciones de Mánager"}
							icon={<GroupsIcon/>}
						/>
					)}
					<SideBarItem
						href={"/app/employee/workbooking"}
						label={"Reserva la Oficina"}
						icon={<StyleIcon/>}
					/>
					<SideBarItem
						href={"/app/employee/uploads"}
						label={"Tus Documentos"}
						icon={<Description/>}
					/>
				</>
			)}
			<SideBarItem
				href={"/app/employee/evaluation"}
				label={"Tus Evaluaciones"}
				icon={<ThermostatIcon/>}
			/>
			{!forceEvaluation && (
				<>

					<SideBarItem
						href={"/app/employee/learning"}
						label={"Formación"}
						icon={<SchoolIcon/>}
					/>
					<SideBarItem
						href={"/app/employee/document"}
						label={"Documentos de Empresa"}
						icon={<FolderIcon/>}
					/>
				</>
			)}
		</>
	);
}