import React from "react";
import styles from "./footer.module.scss";

const AdminFooter = () => {
    return (
        <footer className={styles.admin__footer}>
            <span>Arteco Consulting SL, &copy; {new Date().getFullYear()}</span>
        </footer>
    );
};

export default AdminFooter;
