import {PersonReminder, PersonReminderRestControllerApiFactory} from "arteco-api-client-ts";
import AppList, {AppListConfig} from "../../../../components/layout/list";
import {COLOR_DISMISSED} from "../../../../components/layout/colors";
import {Grid} from "@mui/material";
import {useApiManager} from "../../../../components/utils/api";
import React from "react";

export const PersonReminderListPage = () => {
    const apiManager = useApiManager();

    const listConfig: AppListConfig<PersonReminder> = {
        title: "Recordatorios de empleados",
        onClick: "/app/admin/employee/reminder",
        decorator: (bean: PersonReminder) => (
            <Grid container style={{color: bean.dismissed ? COLOR_DISMISSED : ""}}>
                <Grid item sm>
                    {bean.content.substring(0, 50)}
                </Grid>
                <Grid item sm={3}>
                    {bean.disabled ? "desactivado" : ""}
                </Grid>
            </Grid>
        ),
        supplier: async (currentPage, size, term, showDismissed) => {
            const companyId = apiManager.companyId;
            const ctrl = apiManager.factory(PersonReminderRestControllerApiFactory);
            return ctrl.pagePersonReminder(companyId, currentPage, size, undefined, term, showDismissed);
        },
    };

    return (
        <>
            <AppList config={listConfig}/>
        </>
    );
};