import {Alert, Grid} from "@mui/material";
import {dateToString} from "../../../../components/utils/dates";
import {CustomNumberFormat} from "../../../../components/utils/custom_number_format";
import React, {useEffect, useState} from "react";
import {PersonRestControllerApiFactory, PersonSummary} from "arteco-api-client-ts";
import {useApiManager} from "../../../../components/utils/api";
import AlertTitle from "@mui/material/AlertTitle";

export interface PersonSummaryProps {
	personId: number | undefined;
	showPersonData?: boolean;
}

export const PersonSummaryAlert = ({personId, showPersonData = true}: PersonSummaryProps) => {
	const apiManager = useApiManager();
	const personCtrl = apiManager.factory(PersonRestControllerApiFactory);

	const [personSummary, setPersonSummary] = useState<PersonSummary | undefined>(undefined);

	useEffect(() => {
		if (personId) {
			apiManager
			.execute(personCtrl.summaryPerson(personId))
			.then((p: PersonSummary) => setPersonSummary(p));
		}
	}, [personId]);


	return (
		<Alert severity={"info"}>

			<AlertTitle>Resumen {personSummary ? personSummary.year : ""}</AlertTitle>
			<Grid container spacing={3}>
				{personSummary && (
					<>
						{showPersonData && (
							<>
								<Grid item>
									<strong>Cumpleaños</strong><br/>{dateToString(personSummary.person?.birth)}

								</Grid>
								<Grid item>
									<strong>Alta</strong><br/>{dateToString(personSummary.person?.hireDate)}
								</Grid>
							</>
						)}
						<Grid item >
							<strong>Salario</strong><br/>
							<CustomNumberFormat amount={personSummary.salary?.annualSalary}/>
						</Grid>
						<Grid item >
							<strong>Revisado</strong><br/>
							{dateToString(personSummary.lastSalaryUpdate)}
						</Grid>
						<Grid item >
							<strong>Vacaciones</strong><br/>
							{personSummary.occupation?.takenVacations} de {personSummary.occupation?.allowedVacations}
						</Grid>
					</>
				)}
				{!personSummary && (
					<Grid item>
						Identificador de persona no indicado
					</Grid>
				)}
			</Grid>
		</Alert>
	)
}