import {Alert, Button, Grid} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useApiManager} from "../../../../components/utils/api";
import {DedicationBillingSummary, DedicationRestControllerApiFactory} from "arteco-api-client-ts";
import {OccupationProcessed} from "./occupation_utils";
import {fileLocalSave} from "../../../../components/form/form_inputs/form_file";
import DedicationTable from "./dedication/dedication_table";
import AlertTitle from "@mui/material/AlertTitle";


export interface DedicationProps {
	year: number;
	month: string;
	occupation: OccupationProcessed;
}


export const DedicationSummary = ({month, year, occupation}: DedicationProps) => {

	const apiManager = useApiManager();

	const companyId = apiManager.companyId;
	const dedicationCtrl = apiManager.factory(DedicationRestControllerApiFactory);

	const [operatingBills, setOperatingBills] = useState<boolean>(false);
	const [summary, setSummary] = useState<DedicationBillingSummary | undefined>(undefined);
	const [selectedDedicationIds, setSelectedDedicationIds] = useState<number[]>([]);

	const dedicable = {selectedDedicationIds, setSelectedDedicationIds};

	const loadBilledPending = () => {
		apiManager.execute(dedicationCtrl.checkBillingPendingDedication(companyId, year, parseInt(month)))
		.then(summary => setSummary(summary));
	}

	useEffect(() => {
		loadBilledPending();
	}, [month]);

	const borrarFacturas = () => {
		setOperatingBills(true);
		if (confirm("Seguro que desea borrar las facturas de las dedicaciones?")) {
			apiManager.execute(dedicationCtrl.deleteBillsDedication(companyId, year, parseInt(month)))
			.then(bills => {
				loadBilledPending();
				alert("Se han borrado " + bills.length + " facturas");
				setOperatingBills(false);
			});
		} else {
			setOperatingBills(false);
		}
	}

	const crearFacturas = () => {
		setOperatingBills(true);
		apiManager.execute(dedicationCtrl.makeBillsDedication(companyId, year, parseInt(month), selectedDedicationIds))
		.then(bills => {
			loadBilledPending();
			alert("Se han generado " + bills.length + " facturas");
			setOperatingBills(false);
		});
	}

	const duplicarDedicaciones = (year: number, month: number) => {
		apiManager.execute(dedicationCtrl.duplicateDedication(companyId, year, month, selectedDedicationIds))
		.then(bills => {
			alert("Operación realizada")
		});
	}


	const pdfFacturas = () => {
		setOperatingBills(true);
		apiManager.executeRaw(dedicationCtrl.pdfsDedication(companyId, year, parseInt(month)))
		.then((resp) => {
			fileLocalSave(resp);
			setOperatingBills(false);
		});
	}

	return (
		<>

			<Grid container spacing={3} mt={3} alignContent={"stretch"}>
				<Grid item sm={6}>
					<Alert color={summary?.billsGenerated ? "error" : undefined} sx={{height: "100%"}}>
						<AlertTitle>Facturas</AlertTitle>

						{summary && summary.billingPending && summary.billingPending > 0 &&
							<Button variant="contained" disabled={operatingBills} sx={{marginRight: "1em"}}
									onClick={() => crearFacturas()}>
								Facturar
								({selectedDedicationIds.length > 0 ? selectedDedicationIds.length : summary.billingPending})
								dedicaciones
							</Button>
							|| ""}

						{summary && summary.billsGenerated && summary.billsGenerated > 0 && (
							<>
								<Button variant="contained" disabled={operatingBills} sx={{marginRight: "1em"}}
										onClick={() => borrarFacturas()}>
									Borrar {summary.billsGenerated} Facturas
								</Button>
								<Button variant="contained" disabled={operatingBills} onClick={() => pdfFacturas()}>
									PDFs
								</Button>
							</>
						) || ""}

					</Alert>
				</Grid>
				<Grid item sm={2}>
					<Alert color={"success"} sx={{height: "100%"}}>
						<AlertTitle>Herramientas</AlertTitle>
						<Button variant={"contained"}
								size={"small"}
								disabled={selectedDedicationIds.length < 1}
								onClick={_ => {
									const value = prompt("A qué año y mes (yyyy-mm) desea duplicar ?");
									const regex = /^\d{4}-(0[1-9]|1[0-2])$/;
									if (value && regex.test(value)) {
										const partes = value.split('-');
										const nyear = parseInt(partes[0], 10);
										const nmonth = parseInt(partes[1], 10);
										duplicarDedicaciones(nyear, nmonth);
									} else {
										alert("Fecha incorrecta");
									}
								}}>
							Duplicar
						</Button>
						<Button variant={"contained"}
								size={"small"}
								sx={{marginRight: "1em", marginTop: "1em"}}
								disabled={selectedDedicationIds.length < 1}
								onClick={_ => {
									if (confirm("Seguro que desea borrar todas las seleccionadas?")) {
										dedicationCtrl
										.dedicationBatchDeleteDedication(companyId, selectedDedicationIds)
										.then(resp => alert(resp.data))
									}
								}}>
							Borrar selección
						</Button>
					</Alert>
				</Grid>
				<Grid item sm={4}>
					<Alert color={"warning"} sx={{height: "100%"}}>
						El rol sólo aparece en las empresas con <strong>facturas anónimas</strong> de la siguiente
						manera:<br/>
						<code>P-3232 - &lt;rol_persona&gt;</code><br/>
					</Alert>
				</Grid>
			</Grid>


			<DedicationTable month={month}
							 year={year}
							 showPerson={true}
							 personId={undefined}
							 occupation={occupation}
							 dedicable={dedicable}/>


		</>
	)
}

