import React from "react";
import {CandidateEvent, CandidateEventRestControllerApiFactory,} from "arteco-api-client-ts";
import {useForm} from "../../../../../components/form/form_model";
import {useApiManager} from "../../../../../components/utils/api";
import {FormContent} from "../../../../../components/form/form_inputs/form_buttons";
import FormSelect from "../../../../../components/form/form_inputs/form_select";
import {FormInput} from "../../../../../components/form/form_inputs/form_input";
import {types} from "../../../../../components/form/types";
import {Grid} from "@mui/material";
import {useParams} from "react-router-dom";
import {useCandidate} from "../index";
import {parsePathId} from "../../../../../components/form/form_utils";

export const CandidateEventEditPage = () => {
    const apiManager = useApiManager();
    const eventApi = apiManager.factory(CandidateEventRestControllerApiFactory);
    const params = useParams();

    const candidateId = parsePathId(params, "candidateId", true);
    const [candidate, setCandidate] = useCandidate(candidateId);

    const form = useForm<CandidateEvent>({
        fetcher: (id) => eventApi.getCandidateEvent(id),
        updater: (bean) => eventApi.saveCandidateEvent(bean),
        remover: (id) => eventApi.deleteOrDismissCandidateEvent(id),
        factory: () => {
            return {
                candidate: {
                    id: candidateId,
                },
            } as CandidateEvent;
        }
    });

    return (
        <>
            <FormContent
                form={form}
                title={
                    "Ficha del evento para " +
                    (candidate ? candidate.surnames + ", " + candidate.name : "")
                }>
                <Grid container spacing={3}>
                    <Grid item sm>
                        <FormInput field={"issueDate"} label={"Fecha"} type={"datetime"}/>
                    </Grid>
                    <Grid item sm>
                        <FormSelect
                            field={"type"}
                            label={"Tipo"}
                            type={types.candidateEventTypes}
                        />
                    </Grid>
                </Grid>

                <FormInput field={"description"} label={"Descripción"} rows={4}/>
            </FormContent>
        </>
    );
};