import React, {useEffect, useState} from "react";
import {
    ApplicantList,
    ApplicantRestControllerApiFactory,
    Candidate,
    CandidateLevelTestList,
    CandidateLevelTestRestControllerApiFactory,
    CandidateRestControllerApiFactory,
    Skill,
    SkillLevel
} from "arteco-api-client-ts";
import {FormContent} from "../../../../components/form/form_inputs/form_buttons";
import {FormInput} from "../../../../components/form/form_inputs/form_input";
import {types, useType} from "../../../../components/form/types";
import {Button, Grid, TextField, Typography} from "@mui/material";
import FormFile from "../../../../components/form/form_inputs/form_file";
import FormSelect from "../../../../components/form/form_inputs/form_select";
import FormToggle from "../../../../components/form/form_inputs/form_toggle";
import FormSelectButtons from "../../../../components/form/form_inputs/form_select_buttons";
import {useForm} from "../../../../components/form/form_model";
import {useApiManager} from "../../../../components/utils/api";
import {useNavigate, useParams} from "react-router-dom";
import FormAutocomplete from "../../../../components/form/form_inputs/form_autocomplete";
import FormFilePicture from "../../../../components/form/form_inputs/form_file_picture";
import {ADMIN_HOME} from "../../../../components/utils/urls";
import {parsePathId} from "../../../../components/form/form_utils";
import {randomString} from "../../../../components/utils/auth";
import {CandidateSummaryAlert} from "./summary";
import {dateToString} from "../../../../components/utils/dates";


export const CandidateEditPage = () => {
    const params = useParams();
    const navigate = useNavigate();
    const apiManager = useApiManager();
    const ctrl = apiManager.factory(CandidateRestControllerApiFactory);
    const testCtrl = apiManager.factory(CandidateLevelTestRestControllerApiFactory);
    const applicantCtrl = apiManager.factory(ApplicantRestControllerApiFactory);
    const companyId = apiManager.companyId;

    const [skills, setSkills] = useState<Skill[]>();
    const [skillLevels, setSkillLevels] = useState<SkillLevel[]>();
    const [tests, setTests] = useState<CandidateLevelTestList[]>();
    const [lastApplications, setLastApplications] = useState<ApplicantList[]>([]);

    const skillsType = useType(types.skills);
    const skillLevelType = useType(types.skillLevels);

    const form = useForm<Candidate>({
        fetcher: (id) => {
            apiManager.execute(applicantCtrl.listApplicant(companyId, id, undefined, undefined, undefined))
                .then(lastApplications => {
                    lastApplications = lastApplications.slice(0, 5);
                    setLastApplications(lastApplications);
                });
            return ctrl.getCandidate(id)
        },
        updater: (bean) => ctrl.saveCandidate(bean),
        remover: (id) => ctrl.deleteOrDismissCandidate(id),
        factory: () => {
            return {
                company: {
                    id: apiManager.companyId,
                },
                interest: "Candidato creado manualmente desde el módulo de Selección",
                password: randomString(8),
                country: "ES"
            } as Candidate;
        },
        extraActions: [
            {
                label: "Inscripciones",
                action: () => {
                    navigate("/app/admin/recruitment/candidate/" + form.getIdOrErr() + "/application");
                },
            },
            {
                label: "Eventos",
                action: () => {
                    navigate("/app/admin/recruitment/candidate/" + form.getIdOrErr() + "/event");
                },
            },
            {
                label: "Pruebas",
                action: () => {
                    navigate("/app/admin/recruitment/candidate/" + form.getIdOrErr() + "/solution");
                },
            },
            {
                label: "Documentos",
                action: () => {
                    navigate("/app/admin/recruitment/candidate/" + form.getIdOrErr() + "/document");
                },
            },
        ],
        modelIdFetcher: () => parsePathId(params, "candidateId")

    });

    useEffect(() => {
        skillsType.supplier().then((skills) => setSkills(skills));
        skillLevelType.supplier().then((skillLevels) => setSkillLevels(skillLevels));
        apiManager.execute(testCtrl.listCandidateLevelTest(apiManager.companyId)).then(t => setTests(t));
    }, []);


    return (
        <>
            <FormContent form={form} title={"Ficha del candidato"}>

                <Grid container spacing={2} mb={2}>
                    <Grid item sm={7}>
                        <Grid container spacing={3}>
                            <Grid item sm={3}>
                                <FormFilePicture field={"photo"}
                                                 label={"Foto"}
                                                 photoDownloader={() => ctrl.downloadPhotoCandidate(form.getIdOrErr())}
                                                 helpText={"También puedes pegar una imagen del corta papeles"}/>
                            </Grid>
                            <Grid item sm>
                                <CandidateSummaryAlert candidateId={form.propModel.value?.id}/>

                                <TextField
                                    fullWidth
                                    value={ADMIN_HOME + "/candidate?email=" + form.value("email") + "&pass=" + form.value("password")}
                                    label={"Url de Acceso"} disabled/>

                                {tests && tests.map((test, i) => (
                                    <TextField
                                        key={"test-" + i}
                                        sx={{marginTop: 3}}
                                        fullWidth
                                        value={ADMIN_HOME + "/candidate/" + form.value("id") + "/test/" + test.id}
                                        label={"Url de Prueba [" + test.name + "]"} disabled/>
                                ))}

                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item sm p={3}>
                        <Typography variant={"h5"} component={"h3"} sx={{mb: 1}}>Últimas aplicaciones</Typography>
                        {lastApplications && lastApplications.map((application, appIdx) => (
                            <div key={appIdx} style={{marginBottom: "1em"}}>
                                <Button variant={"text"} onClick={_ => {
                                    navigate(`/app/admin/recruitment/recruitment/${application.recruitment.id}/application/${application.id}`);
                                }}>{dateToString(application.created)} - {application.recruitment.title}</Button>
                            </div>
                        ))}
                        {(!lastApplications || lastApplications.length == 0) && (
                            <p>
                                No hay inscripciones a procesos
                            </p>
                        )}
                    </Grid>
                </Grid>


                <FormInput field={"comment"} label={"Comentarios (privados)"} rows={3}/>

                <Grid container spacing={3}>
                    <Grid item sm={4}>
                        <FormSelect type={types.sources} field={"source"} label={"Origen"}/>
                    </Grid>
                    <Grid item sm={5}>
                        <FormInput field={"points"} label={"Valoración"}/>
                    </Grid>
                    <Grid item sm={3}>
                        <FormAutocomplete type={types.candidateStates} field={"state"} label={"Estado"}/>
                    </Grid>
                </Grid>


                <Grid container spacing={3}>
                    <Grid item sm={4}>
                        <FormInput field={"name"} label={"Nombre (obligatorio)"}/>
                    </Grid>
                    <Grid item sm={5}>
                        <FormInput field={"surnames"} label={"Apellidos (obligatorio)"}/>
                    </Grid>
                    <Grid item sm={3}>
                        <FormAutocomplete field={"type"} label={"Tipo"} type={types.candidateTypes}/>
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item sm={4}>
                        <FormInput field={"email"} label={"Email (obligatorio)"}/>
                    </Grid>
                    <Grid item sm={5}>
                        <FormInput field={"password"} label={"Clave (obligatorio)"}/>
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item sm={4}>
                        <FormInput field={"telephone"} label={"Teléfono"}/>
                    </Grid>
                    <Grid item sm={5}>
                        <FormSelect type={types.countries} field={"country"} label={"País"}/>
                    </Grid>
                    <Grid item sm={3}>
                        <FormInput field={"region"} label={"Región"} helperText={"Mallorca | Resto de ubicaciones"}/>
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item sm>
                        <FormSelect
                            type={types.trainingLevels}
                            field={"maxTrainingLevel"}
                            label={"Máxima formación académica"}
                        />
                        <FormFile
                            field={"cv"}
                            label={"Currículum (máx 5Mb)"}
                            onDownload={() => ctrl.downloadCvCandidate(form.getIdOrErr())}
                        />
                        <FormInput field={"github"} label={"Github"}/>
                        <FormInput field={"linkedin"} label={"Linkedin"}/>
                        <FormInput field={"website"} label={"Website"}/>
                    </Grid>
                </Grid>

                {skills &&
                    skillLevels &&
                    skills.map((skill, skillIdx) => (
                        <FormSelectButtons
                            key={"skill-" + skillIdx}
                            label={skill.label}
                            field={"skills." + skill.id}
                            type={types.skillLevels}
                            items={skillLevels}
                        />
                    ))}

                <Grid container spacing={3}>
                    <Grid item sm>
                        <FormInput
                            field={"reqAvgHourlyPrice"}
                            label={"Precio medio por hora deseado"}
                        />
                    </Grid>
                    <Grid item sm>
                        <FormInput
                            field={"reqAnnualPayroll"}
                            label={"Salario anual bruto deseado"}
                        />
                    </Grid>
                    <Grid item sm>
                        <FormInput
                            field={"adviceDays"} type={"number"}
                            label={"Días de preaviso"}
                        />
                    </Grid>

                </Grid>

                <FormSelect field={"workType"} type={types.workTypes} label={"Tipo de jornada"}/>

                <FormInput field={"interest"} label={"Interesado en (obligatorio)"} rows={10}/>

                <Grid container spacing={3}>
                    <Grid item sm>
                        <FormToggle field={"receiveEmails"} label={"Emails de trabajos"}/>
                    </Grid>

                    <Grid item sm>
                        <FormToggle field={"communityEmails"} label={"Emails de la comunidad"}/>
                    </Grid>

                    <Grid item sm>
                        <FormToggle field={"servicesEmails"} label={"Emails de Arteco"}/>
                    </Grid>
                </Grid>
            </FormContent>
        </>
    );
};