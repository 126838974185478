import React from "react";

import {useApiManager} from "../../../../components/utils/api";
import {Skill, SkillRestControllerApiFactory} from "arteco-api-client-ts";

import {FormContent} from "../../../../components/form/form_inputs/form_buttons";
import {FormInput} from "../../../../components/form/form_inputs/form_input";
import {Grid} from "@mui/material";
import {useForm, UseFormProps} from "../../../../components/form/form_model";
import FormToggle from "../../../../components/form/form_inputs/form_toggle";

export const SkillEditPage = () => {
    const apiManager = useApiManager();
    const ctrl = apiManager.factory(SkillRestControllerApiFactory);

    const form = useForm({
        fetcher: (id) => ctrl.getSkill(id),
        updater: (bean) => ctrl.saveSkill(bean),
        remover: (id) => ctrl.deleteOrDismissSkill(id),
        factory: () => {
            return {
                company: {
                    id: apiManager.companyId,
                },
            } as Skill;
        },
    } as UseFormProps<Skill>);

    return (
        <>
            <FormContent form={form} title={"Formulario de habilidades"}>
                <Grid container spacing={3}>
                    <Grid item sm>
                        <FormInput type={"text"} field={"position"} label={"Posición"} />
                    </Grid>
                    <Grid item sm>
                        <FormInput type={"text"} field={"label"} label={"Etiqueta"} />
                    </Grid>
                    <Grid item sm={3}>
                        <FormToggle field={"showInForm"} label={"Mostrar en formulario"} />
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item sm>
                        <FormInput
                            type={"text"}
                            field={"description"}
                            label={"Descripción"}
                            rows={3}
                        />
                    </Grid>
                </Grid>
            </FormContent>
        </>
    );
};