import AdminInner from "../../../components/layout/admin_inner";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {Alert, Box, ListItemText, Tab} from "@mui/material";
import React, {useState} from "react";
import AppList, {AppListConfig} from "../../../components/layout/list";
import {dateToString} from "../../../components/utils/dates";
import {EmpEvaluationRestControllerApiFactory, Evaluation, SimpleEvaluation,} from "arteco-api-client-ts";
import {useApiManager} from "../../../components/utils/api";
import {FormContent} from "../../../components/form/form_inputs/form_buttons";
import {useForm, UseFormProps} from "../../../components/form/form_model";
import {EvaluationFormShared} from "../../admin/employee/evaluation/form";
import {useAuthManager} from "../../../components/utils/auth";
import {useNavigate} from "react-router-dom";

export const EmployeeEvaluationPage = () => {
	const apiManager = useApiManager();
	const authManager = useAuthManager();
	const navigate = useNavigate();

	const [trigger, setTrigger] = useState<number>(0);
	const [selectedTab, setSelectedTab] = useState<string>("form");
	const evaluationApi = apiManager.factory(EmpEvaluationRestControllerApiFactory);

	const list: AppListConfig<SimpleEvaluation> = {
		onClick: (bean: SimpleEvaluation) => {
			// do nothing
		},
		decorator: (bean: SimpleEvaluation) =>
			<ListItemText primary={"Realizada día " + dateToString(bean.evaluationDate) + " "
				+ (bean.average ? " con puntuación media " + bean.average.toFixed(2) : "")}
						  secondary={bean.interviewDate ? "Revisada día " + dateToString(bean.interviewDate) : "Pendiente de revisar"}/>
		,
		supplier: async (currentPage, size, term, showDismissed) => {
			return evaluationApi.pageEmployeeEvaluation(currentPage, size, undefined);
		},
	};

	const personId = -1; // El Api lo sobrescribe

	const evaluationForm = useForm({
		updater: (bean) => evaluationApi.saveEmployeeEvaluation(bean)
		.then(_ => {
			window.location.href="/app/employee";
		}),
		factory: () => {
			return {
				person: {id: personId},
				modifier: {id: authManager.getUserId()},
				evaluationDate: new Date().toISOString(),
				points: {}
			} as Evaluation;
		},
		postUpdate: (bean) => setTrigger(trigger + 1),
	} as UseFormProps<Evaluation>);

	return (
		<>
			<AdminInner title={"Evaluaciones"}>
				<TabContext value={selectedTab}>
					<Box sx={{borderBottom: 1, borderColor: 'divider'}}>
						<TabList onChange={(event: React.SyntheticEvent, newValue: string) => {
							setSelectedTab(newValue);
						}}>
							<Tab label="Nueva" value="form"/>
							<Tab label="Histórico" value="list"/>
						</TabList>
					</Box>
					<TabPanel value="form">
						<Alert sx={{marginBottom: 3}} color={"warning"}>
							Módulo experimental para la evaluación de la compañía. Disculpa por los cambios que pueda
							haber,
							estamos tratando de diseñar e implantar un buen sistema de medición de temperatura interna.
							Si tienes algún comentario por favor, escríbenos a&nbsp;
							<a href="mailto:rrhh@arteco-consulting.com">RRHH</a>
						</Alert>
						<Alert sx={{marginBottom: 3}}>
							A continuación se muestra el formulario de evaluación. <br/>Por favor tómate tu tiempo para
							cumplimentarlo lo más rigurosamente posible, ya que <b>nos tomamos muy en serio la
							información</b>
							&nbsp; que recibimos por este canal.
						</Alert>
						<FormContent form={evaluationForm} withoutPaper hideTopButtons={true}>
							<EvaluationFormShared asEmployee={true} showMandatoryInfo personId={personId}
												  hideEvaluationDate/>
						</FormContent>
					</TabPanel>
					<TabPanel value="list">
						<AppList inner={false} config={list} appendable={false} searchable={false} trigger={trigger}/>
					</TabPanel>
				</TabContext>

			</AdminInner>
		</>
	);
};