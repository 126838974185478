import {createTheme, responsiveFontSizes} from "@mui/material/styles";
import {COLOR_AZUL_OSCURO, COLOR_VERDE_ARTECO} from "./colors";
import {esES} from "@mui/material/locale";
import {lighten} from "@mui/material";

// import "../../../public/fonts/Manrope/Manrope-VariableFont_wght.ttf";

const components = {
    MuiLink: {
        defaultProps: {
            underline: "none",
        },
    },
    MuiCssBaseline: {
        styleOverrides: `
        @font-face {
          font-family: 'Manrope';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: url("/public/fonts/Manrope/Manrope-Variablefont_wght.ttf") format('truetype');
        }
      `,
    }

} as any;

export const theme = responsiveFontSizes(
    createTheme({
        components: components,
        palette: {
            primary: {
                main: lighten(COLOR_AZUL_OSCURO,.3),
            },
            secondary: {
                main: COLOR_VERDE_ARTECO,
            },
            text: {
                primary: COLOR_AZUL_OSCURO,
            },
        },
        typography: {
            fontFamily: [
                "Manrope",
                "Roboto",
                '"Helvetica Neue"',
                "Arial",
                "sans-serif",
            ].join(","),
        },
    }, esES)
);


