import {useApiManager} from "../../components/utils/api";
import {
    CandidateForm,
    CandidateRequestRestControllerApiFactory,
    Item,
    PublicRecruitmentDto
} from "arteco-api-client-ts";
import {useNavigate, useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useForm} from "../../components/form/form_model";
import {AppLayout} from "../../components/layout/layout";
import {CandidateHeader} from "./header";
import {Alert, Box, Button, List, ListItem, ListItemIcon, ListItemText, Paper, Typography} from "@mui/material";
import {H1, PARAGRAPH, Typographies} from "../../components/utils/typography_consts";
import {HOME_ARTECO} from "../../components/utils/urls";
import {PubCandidateForm} from "./candidate_form";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

export const PublicRecruitmentsPage = () => {
    const navigate = useNavigate();
    const apiManager = useApiManager();
    const ctrl = apiManager.factory(CandidateRequestRestControllerApiFactory);
    const companyId = apiManager.companyId;
    const [recruitments, setRecruitments] = useState<PublicRecruitmentDto[]>([]);


    useEffect(() => {
        apiManager.execute(ctrl.getPublicRecruitments(companyId))
            .then(recruitments => setRecruitments(recruitments));
    }, [])

    return (
        <AppLayout permitAnon={true} maxWidth="md">

            <CandidateHeader title={"Únete a nuestro equipo"}
                             subTitle={"Born to be a Javalover?"}/>

            <Box>

                <Paper>
                    <Box p={3}>
                        <Typography my={4}
                                    variant={Typographies.h4}
                                    component={H1}>
                            Revisa nuestras posiciones disponibles
                        </Typography>

                        {recruitments.length > 0 && (
                            <List>
                                {recruitments.map((recruitment, idx) => (
                                    <ListItem key={idx} sx={{pb: 3}}>
                                        <ListItemIcon>
                                            <ArrowForwardIosIcon/>
                                        </ListItemIcon>
                                        <ListItemText primary={
                                            <>
                                                <Button onClick={_ => {
                                                    navigate(`/candidate?rid=${recruitment.id}&s=in`);
                                                }}>
                                                    <Typography variant={"h5"} component={"h2"}>
                                                        {recruitment.publicTitle}
                                                    </Typography>
                                                </Button>
                                            </>
                                        }/>
                                    </ListItem>
                                ))}
                            </List>
                        )}


                        {(!recruitments || recruitments.length == 0) && (
                            <Alert color={"warning"}>
                                Disculpa, no hay procesos abiertos al público actualmente.
                            </Alert>
                        )}

                        <Typography py={4} component={PARAGRAPH}>
                            Puedes continuar revisando más contenido en la página
                            &nbsp;<a href={HOME_ARTECO}>web de Arteco</a>.
                        </Typography>
                    </Box>
                </Paper>


            </Box>
        </AppLayout>
    );
};