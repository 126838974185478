import {Box, Button, Checkbox, FormControlLabel, Grid, TextField, Typography} from "@mui/material";
import AdminInner from "../../../../components/layout/admin_inner";
import {H1, Typographies} from "../../../../components/utils/typography_consts";
import React, {useState} from "react";
import {useApiManager} from "../../../../components/utils/api";
import {BillRestControllerApiFactory} from "arteco-api-client-ts";
import {fileLocalSave} from "../../../../components/form/form_inputs/form_file";


export const BillBatchPage = () => {
    const apiManager = useApiManager();
    const ctrl = apiManager.factory(BillRestControllerApiFactory);
    const companyId = apiManager.companyId;

    const [value, setValue] = useState<string>("");
    const [separator, setSeparator] = useState<string>("TAB");
    const [disabled, setDisabled] = useState<boolean>(false);
    const [sent, setSent] = useState<boolean>(true);

    return (
        <>
            <AdminInner>

                <Typography variant={Typographies.h3} component={H1}>
                    Creación de facturas por lote
                </Typography>

                <Grid container spacing={2}>
                    <Grid item xs={1}>
                        <TextField
                            sx={{marginTop: 2}}
                            variant="filled"
                            label={"Separador"}
                            type={"text"}
                            rows={1}
                            multiline={false}
                            fullWidth
                            autoComplete={"off"}
                            value={separator}
                            onChange={(event) => {
                                const value = event.target.value;
                                setSeparator(value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <FormControlLabel sx={{marginTop: 2}}
                                          control={<Checkbox checked={sent} onChange={_ => setSent(!sent)}/>}
                                          label="Enviadas"/>
                    </Grid>
                </Grid>

                <TextField
                    sx={{marginTop: 2}}
                    variant="filled"
                    label={"Una factura por línea"}
                    type={"text"}
                    rows={20}
                    multiline={true}
                    fullWidth
                    autoComplete={"off"}
                    value={value}
                    onChange={(event) => {
                        const value = event.target.value;
                        setValue(value);
                    }}
                />

                <Box sx={{my: 1}}>
                    <b>Formato:</b><br/>
                    <i>{`id_cliente OR name ${separator} dd/mm/yyyy ${separator} descripción_factura ${separator} descripción_línea ${separator} cantidad ${separator} importe_unitario (€)`}</i>

                    <br/>
                    <br/>

                    <b>Notas:</b><br/>
                    <ul>
                        <li>Si las líneas coinciden en [cliente, concepto y descripción] se unificarán en la misma factura</li>
                        <li>Las cantidades han de ir con decimales separados por punto '.'</li>
                        <li>Las comas ',' serán reemplazadas por '.'</li>
                        <li>Si hay más de dos puntos, se lanzará error</li>
                    </ul>


                </Box>

                <Button
                    sx={{marginBottom: 1, marginTop: 1}}
                    variant={"contained"}
                    disabled={disabled}
                    onClick={() => {
                        setDisabled(true);
                        apiManager
                            .executeRaw(ctrl.batchCreateBill(companyId, value, sent, separator))
                            .then(summary => {
                                fileLocalSave(summary)
                            })
                            .catch(reason => {
                                alert(reason);
                                setDisabled(false);
                            });
                    }}>
                    Guardar
                </Button>
            </AdminInner>

        </>
    );
}