import {useApiManager} from "../../../components/utils/api";
import React, {useEffect, useState} from "react";
import {useForm, UseFormProps} from "../../../components/form/form_model";
import AdminInner from "../../../components/layout/admin_inner";
import {Grid, ListItemText} from "@mui/material";
import {FormContent} from "../../../components/form/form_inputs/form_buttons";
import {FormInput} from "../../../components/form/form_inputs/form_input";
import {dateToString} from "../../../components/utils/dates";
import {EmpUploadRestControllerApiFactory, PersonDocument, PersonEstimation} from "arteco-api-client-ts";
import AppList, {AppListConfig} from "../../../components/layout/list";
import FormFile, {fileLocalSave} from "../../../components/form/form_inputs/form_file";
import FormSelect from "../../../components/form/form_inputs/form_select";
import {types} from "../../../components/form/types";


const EmployeeUploadsPage = () => {
	const apiManager = useApiManager();
	const api = apiManager.factory(EmpUploadRestControllerApiFactory);
	const [trigger, setTrigger] = useState<number>(0);
	const [requiredYearMonth, setRequiredYearMonth] = useState<boolean>(false);
	const [requiredExpiration, setRequiredExpiration] = useState<boolean>(false);
	const [docType, setDocType] = useState<any>(undefined);
	const [activityLog, setActivityLog] = useState<boolean>(false);

	const [month, setMonth] = useState<number>(new Date().getMonth() + 1);
	const [year, setYear] = useState<number>(new Date().getFullYear());
	const [estimation, setEstimation] = useState<PersonEstimation | undefined>(undefined);

	const form = useForm({
		updater: (bean) => api.saveEmployeeUpload(bean),
		factory: () => {
			return {
				year: new Date().getFullYear(),
				month: new Date().getMonth() + 1,
				person: {id: 0},
			} as PersonDocument;
		},
		postUpdate: (bean) => {
			setTrigger(trigger + 1);
		},
	} as UseFormProps<PersonDocument>);

	const list: AppListConfig<PersonDocument> = {
		title: "Documentos",
		onClick: (bean: PersonDocument) => {
			if (bean.id)
				api
				.viewEmployeeUpload(bean.id)
				.then((resp) => fileLocalSave(resp))
		},
		decorator: (bean: PersonDocument) =>
			<ListItemText primary={dateToString(bean.created) + " - " + bean.type?.name}
						  secondary={bean.title}/>,
		supplier: async (currentPage, size, term, showDismissed) => {
			return api.pageEmployeeUpload(currentPage, size, undefined);
		},
	};

	useEffect(() => {
		if (activityLog) {
			apiManager.execute(api.viewEmployeeEstimationHours(year, month))
			.then(estimation => setEstimation(estimation));
		}
	}, [activityLog, year, month]);


	return (
		<>
			<Grid container spacing={3}>
				<Grid item sm={8}>
					<AppList config={list} appendable={false} searchable={false} trigger={trigger}/>
				</Grid>
				<Grid item sm={4}>
					<AdminInner title={"Subir Archivo"}>
						<FormContent form={form} withoutPaper hideMetadata={true}
									 hideTopButtons={true}>
							<FormSelect field={"type"} label={"Tipo"} type={types.personDocumentTypesAsEmployee}
										onChange={item => {
											setDocType(item);
											if (item) {
												if (item.requiredYearMonth) setRequiredYearMonth(true);
												else setRequiredYearMonth(false);
												if (item.requiredExpiration) setRequiredExpiration(true);
												else setRequiredExpiration(false);
												if ((item.name?.toLowerCase().indexOf("actividad") || -1) >= 0) {
													setActivityLog(true);
												} else {
													setActivityLog(false);
												}
											}
										}}/>
							<FormInput field={"title"} label={"Título"} type={"text"}/>
							{docType && docType.description && docType.description.length && (
								<FormInput field={"_type_desc"}
										   label={"Notas"} rows={4}
										   disabled={true}
										   value={docType.description}/>
							)}

							{requiredYearMonth && (
								<>
									<Grid container spacing={2}>
										<Grid item sm={4}>
											<FormInput field={"year"}
													   label={"Año"}
													   type={"number"}
													   onChange={v => {
														   setYear(v)
													   }}/>
										</Grid>
										<Grid item sm={4}>
											<FormInput field={"month"}
													   label={"Mes"}
													   type={"number"}
													   onChange={v => {
														   setMonth(v);
													   }}/>
										</Grid>
										{estimation && (
											<Grid item sm>
												<b><small>Estimación</small></b><br/>{estimation.hours}h
											</Grid>
										)}
									</Grid>
								</>
							)}
							{requiredExpiration && (
								<>
									<FormInput field={"expiration"} label={"Expiración"} type={"date"}/>
								</>
							)}
							<FormFile field={"attachment"} label={"Archivo"}/>
						</FormContent>
					</AdminInner>
				</Grid>
			</Grid>


		</>
	);
}

export default EmployeeUploadsPage;