import {EvaluationResult, EvaluationResultRestControllerApiFactory} from "arteco-api-client-ts";
import AppList, {AppListConfig} from "../../../../components/layout/list";
import {COLOR_GRIS} from "../../../../components/layout/colors";
import {Grid} from "@mui/material";
import {useApiManager} from "../../../../components/utils/api";

export const EvaluationResultListPage = () => {
    const apiManager = useApiManager();
    const ctrl = apiManager.factory(EvaluationResultRestControllerApiFactory);

    const listConfig: AppListConfig<EvaluationResult> = {
        title: "Respuestas de evaluación",
        onClick: "/app/admin/employee/evaluationresult",
        decorator: (bean: EvaluationResult) => (
            <Grid container style={{ color: bean.dismissed ? COLOR_GRIS : "" }}>
                <Grid item sm>
                    {bean.label}
                </Grid>
                <Grid item sm>
                    {bean.level}
                </Grid>
            </Grid>
        ),
        supplier: async (currentPage, size, term, showDismissed) => {
            const companyId = apiManager.companyId;
            return ctrl.pageEvaluationResult(companyId, currentPage, size, undefined, term, showDismissed);
        },
    };

    return (
        <>
            <AppList config={listConfig} />
        </>
    );
};