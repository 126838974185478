import {AdminCompanyRestControllerApiFactory, Company} from "arteco-api-client-ts";
import AppList, {AppListConfig} from "../../../components/layout/list";
import {COLOR_GRIS} from "../../../components/layout/colors";
import {Grid} from "@mui/material";
import {useApiManager} from "../../../components/utils/api";
import {useAuthManager} from "../../../components/utils/auth";

export const CompanyListPage = () => {
    const apiManager = useApiManager();
    const authManager = useAuthManager();

    const listConfig: AppListConfig<Company> = {
        title: "Empresas ",
        onClick: "/app/admin/super/company",
        decorator: (bean: Company) => (
            <Grid container style={{ color: bean.dismissed ? COLOR_GRIS : "" }}>
                <Grid item sm>
                    {bean.name}
                </Grid>
            </Grid>
        ),
        supplier: async (currentPage, size, term, showDismissed) => {
            const ctrl = apiManager.factory(AdminCompanyRestControllerApiFactory);
            return ctrl.pageCompany(currentPage, size, undefined, term, showDismissed);
        },
    };

    return (
        <>
            <AppList config={listConfig} />
        </>
    );
};