import WikiPageRender from "../../../components/utils/wiki_page";
import AdminInner from "../../../components/layout/admin_inner";
import {SECTION_HR} from "../../admin/employee/wiki/form";

export const EmployeeLearningPage = () => {


    return (
        <>
            <AdminInner title={"Formación"}>
                <WikiPageRender code={"emp.learning.home"} section={SECTION_HR}/>
            </AdminInner>
        </>
    );
};