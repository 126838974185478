import {Box, Button, Icon} from "@mui/material";
import React, {useState} from "react";

export interface FormButtonAspectProps {
    color?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning";
    icon?: React.ReactElement | string;
    label: React.ReactElement | string;
}

export interface FormButtonProps extends FormButtonAspectProps {
    action: () => Promise<any> | void;
}

const FormButton = (props: FormButtonProps) => {
    const [disabled, setDisabled] = useState<boolean>(false);
    return (

        <Button
            sx={{marginBottom:1, marginRight:1}}
            color={props.color}
            variant={"contained"}
            disabled={disabled}
            onClick={() => {
                setDisabled(true)
                const res = props.action();
                if (res && res['finally']) {
                    res.finally(() => setDisabled(false));
                } else {
                    setDisabled(false);
                }
            }}>
            {props.icon && typeof props.icon === "string" && (
                <>
                    <Icon>{props.icon}</Icon> &nbsp;
                </>
            )}
            {props.icon && typeof props.icon !== "string" && <>{props.icon} &nbsp; </>}
            {props.label}
        </Button>

    );
};

export default FormButton;
