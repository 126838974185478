import {Person, PersonRestControllerApiFactory} from "arteco-api-client-ts";
import AppList, {AppListConfig} from "../../../../components/layout/list";
import {COLOR_DISMISSED} from "../../../../components/layout/colors";
import {Grid} from "@mui/material";
import {useApiManager} from "../../../../components/utils/api";
import React, {useEffect, useState} from "react";
import {fileLocalSave} from "../../../../components/form/form_inputs/form_file";


export const usePerson = (id: number | undefined): [(Person | undefined), React.Dispatch<React.SetStateAction<Person | undefined>>] => {
    const apiManager = useApiManager();
    const [getter, setter] = useState<Person | undefined>();
    const ctrl = apiManager.factory(PersonRestControllerApiFactory);

    useEffect(() => {
        if (id) {
            apiManager
                .execute(ctrl.getPerson(id))
                .then(c => setter(c));

        }
    }, [id]);
    return [getter, setter];
};

export const PersonListPage = () => {
    const apiManager = useApiManager();
    const ctrl = apiManager.factory(PersonRestControllerApiFactory);
    const companyId = apiManager.companyId;

    const listConfig: AppListConfig<Person> = {
        title: "Listado de personas",
        onClick: "/app/admin/employee/person",
        decorator: (bean: Person) => (
            <Grid container style={{color: bean.dismissed ? COLOR_DISMISSED : ""}}>
                <Grid item sm>
                    {bean.surnamesAndName}
                </Grid>
                <Grid item sm={2}>
                    {bean.manager ? bean.manager.name : ""}
                </Grid>
                <Grid item sm>
                    {bean.email}
                </Grid>
                <Grid item sm>
                    {bean.maxTrainingLevel ? bean.maxTrainingLevel.label : ""}
                </Grid>
                <Grid item sm>
                    {bean.help}
                </Grid>
            </Grid>
        ),
        supplier: async (currentPage, size, term, showDismissed) => {

            return ctrl.pagePerson(companyId, currentPage, size, undefined, term, showDismissed);
        },
        actions: [
            {
                action: () => {
                    apiManager.executeRaw(ctrl.exportPeoplePerson(companyId)).then((resp) => {
                        fileLocalSave(resp);
                    });
                },
                label: "Exportar"
            },
            {

                action: () => {

                    let year = new Date().getFullYear();
                    year = parseInt(prompt("Indique el año", year.toString())|| year.toString());
                    if (year) {
                        if (confirm("El proceso puede tardar hasta unos minutos. ¿Desea continuar?")) {
                            apiManager.executeRaw(ctrl.exportAbsencesPerson(companyId, year)).then((resp) => {
                                fileLocalSave(resp);
                            });
                        }
                    }
                },
                label: "Ausencias"
            }
        ]
    };

    return (
        <>
            <AppList config={listConfig}/>
        </>
    );
};