import FormModel, {Identifiable} from "./form_model";
import {Box} from "@mui/material";
import * as React from "react";
import FormButton from "./form_inputs/form_button";


export interface FormButtonsProps<T extends Identifiable>{
    form: FormModel<T>;
}

export function FormButtons<T extends Identifiable>({form} : FormButtonsProps<T>){
    return (
        <Box my={2}>
            {form.config.defaultAction && (
                <FormButton
                    action={form.config.defaultAction.action}
                    color={form.config.defaultAction.color}
                    label={form.config.defaultAction.label}
                    icon={form.config.defaultAction.icon}
                />
            )}
            {form.config.removeAction && (
                <FormButton
                    action={form.config.removeAction.action}
                    color={form.config.removeAction.color}
                    label={form.config.removeAction.label}
                    icon={form.config.removeAction.icon}
                />
            )}
            {form.config.extraActions &&
            form.config.extraActions.map((action,i) => (
                <FormButton key={"formButtons-"+i}
                    action={action.action}
                    color={action.color}
                    label={action.label}
                    icon={action.icon}
                />
            ))}
        </Box>
    )
}