import React from "react";

import {Grid} from "@mui/material";
import {FormInput} from "../../../../../components/form/form_inputs/form_input";
import {FormContent} from "../../../../../components/form/form_inputs/form_buttons";
import { CandidateDocument, CandidateDocumentRestControllerApiFactory, Candidate} from "arteco-api-client-ts";
import {useForm, UseFormProps,} from "../../../../../components/form/form_model";
import {useApiManager} from "../../../../../components/utils/api";
import {useParams} from "react-router-dom";
import FormFile from "../../../../../components/form/form_inputs/form_file";
import {useCandidate} from "../index";
import {parsePathId} from "../../../../../components/form/form_utils";

export const CandidateDocumentEditPage = () => {
    const params = useParams();
    const candidateId = parsePathId(params, "candidateId");
    const manager = useApiManager();
    const ctrl = manager.factory(CandidateDocumentRestControllerApiFactory);

    const [candidate, setCandidate] = useCandidate(candidateId);

    const form = useForm({
        fetcher: (id) => ctrl.getCandidateDocument(id),
        updater: (bean) => ctrl.saveCandidateDocument(bean),
        remover: (id) => ctrl.deleteOrDismissCandidateDocument(id),
        factory: () => {
            return {
                candidate: {id: candidateId} as Candidate,
            } as CandidateDocument;
        },
    } as UseFormProps<CandidateDocument>);

    return (
        <>
            <FormContent
                form={form}
                title={
                    "Documento para [" +
                    (candidate ? candidate.surnamesAndName : "") +
                    "]"
                }>

                <Grid item sm>
                    <FormInput field={"title"} type={"text"} label={"Título"}/>
                </Grid>

                <FormInput field={"comments"} type={"text"} label={"Comentarios"} rows={5}/>

                <FormFile
                    field={"attachment"}
                    label={"Archivo"}
                    onDownload={() =>
                        manager.executeRaw(ctrl.downloadCandidateDocument(form.getIdOrErr(),2))
                    }
                />

            </FormContent>
        </>
    );
};