/**
 * @param {String} email
 */
export const isValidEmail = (email: string) => {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
};

export const isEmpty = (element: any) => {
    if (!element) {
        return true;
    }

    if (Array.isArray(element)) {
        return !element.length;
    }

    if (typeof element === "string") {
        return !element.trim().length;
    }

    if ("object" === typeof element) {
        return !Object.keys(element).length;
    }

    return !element;
};
export const isValidField = (element: string, minLength: number) =>
    !isEmpty(element) && element.trim().length >= minLength;

export const abbreviate = (text: string | undefined, maxLength: number): string | null => {
    if (text) {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + "...";
        } else {
            return text;
        }
    }
    return null;
}