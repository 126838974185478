import React from "react";
import {Customer, CustomerRestControllerApiFactory} from "arteco-api-client-ts";
import {FormContent} from "../../../../components/form/form_inputs/form_buttons";
import {FormInput} from "../../../../components/form/form_inputs/form_input";
import {Grid} from "@mui/material";
import {FormAddress} from "../../../../components/form/form_inputs/form_address";
import {useForm, UseFormProps} from "../../../../components/form/form_model";
import {useApiManager} from "../../../../components/utils/api";
import FormToggle from "../../../../components/form/form_inputs/form_toggle";
import FormSelect from "../../../../components/form/form_inputs/form_select";
import {types} from "../../../../components/form/types";
import FormAutocomplete from "../../../../components/form/form_inputs/form_autocomplete";

export const CustomerEditPage = () => {
    const manager = useApiManager();
    const ctrl = manager.factory(CustomerRestControllerApiFactory);
    const companyId = manager.companyId;

    const form = useForm({
        fetcher: (id) => ctrl.getCustomer(id),
        updater: (bean) => ctrl.saveCustomer(bean),
        remover: (id) => ctrl.deleteOrDismissCustomer(id),
        factory: () => {
            return {
                company: {
                    id: companyId,
                },
                address: {}
            } as Customer;
        },
    } as UseFormProps<Customer>);

    return (
        <>
            <FormContent form={form} title={"Formulario de cliente"}>

                <FormInput field={"name"} label={"Nombre"}/>

                <Grid container spacing={3}>
                    <Grid item sm={4}>
                        <FormInput field={"shortName"} label={"Nombre Corto"}/>
                    </Grid>
                    <Grid item sm={4}>
                        <FormInput field={"nif"} label={"Nif"}/>
                    </Grid>
                    <Grid item sm>
                        <FormToggle field={"anonBills"} label={"Facturas anónimas"}/>
                    </Grid>
                    <Grid item sm>
                        <FormToggle field={"skipDedications"} label={"Ignorar dedicaciones"}/>
                    </Grid>
                </Grid>


                <Grid container spacing={3}>
                    <Grid item sm={4}>
                        <FormInput field={"accAccount"} label={"Cuenta Contable"}/>
                    </Grid>
                    <Grid item sm={4}>
                        <FormInput field={"bankAccount"} label={"Cuenta del Banco"}/>
                    </Grid>
                    <Grid item sm>
                        <FormAutocomplete field={"discount"} label={"Descuento"} type={types.discounts}/>
                    </Grid>
                </Grid>

                <FormAddress field={"address"}/>

                <Grid container spacing={3}>
                    <Grid item sm>
                        <FormInput field={"observation"} label={"Observaciones"} rows={10}/>
                    </Grid>
                </Grid>
            </FormContent>
        </>
    );
};
