import {Skill, SkillRestControllerApiFactory} from "arteco-api-client-ts";
import AppList, {AppListConfig} from "../../../../components/layout/list";
import {useApiManager} from "../../../../components/utils/api";
import {Grid} from "@mui/material";

export const SkillListPage = () => {
    const apiManager = useApiManager();

    const listConfig: AppListConfig<Skill> = {
        title: "Listado de habilidades ",
        onClick: "/app/admin/recruitment/skill",
        decorator: (bean: Skill) => (
            <Grid container >
                <Grid item sm={6}>
                    {bean.label}
                </Grid>
                <Grid item sm>
                    {bean.position}
                </Grid>
                <Grid item sm>
                    {bean.showInForm? "Sí": ""}
                </Grid>
            </Grid>
        ),
        supplier: async (currentPage, size, term, showDismissed) => {
            const companyId = apiManager.companyId;
            const ctrl = apiManager.factory(SkillRestControllerApiFactory);
            return ctrl.pageSkill(companyId, currentPage, size, undefined, term, showDismissed);
        },
    };

    return (
        <>
            <AppList config={listConfig} />
        </>
    );
};