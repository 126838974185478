import {SuperUserRestControllerApiFactory, User} from "arteco-api-client-ts";
import AppList, {AppListConfig} from "../../../components/layout/list";
import {Grid} from "@mui/material";
import {useApiManager} from "../../../components/utils/api";
import {COLOR_DISMISSED} from "../../../components/layout/colors";

export const UserListPage = () => {
    const apiManager = useApiManager();

    const listConfig: AppListConfig<User> = {
        title: "Usuarios ",
        onClick: "/app/admin/super/user",
        decorator: (bean: User) => (
            <Grid container style={{ color: bean.dismissed ? COLOR_DISMISSED : "" }}>
                <Grid item sm>
                    {bean.username}
                </Grid>
                <Grid item sm>
                    {bean.email}
                </Grid>
            </Grid>
        ),
        supplier: async (currentPage, size, term, showDismissed) => {
            const ctrl = apiManager.factory(SuperUserRestControllerApiFactory);
            return ctrl.pageUser(currentPage, size, undefined, term, showDismissed);
        },
    };

    return (
        <>
            <AppList config={listConfig} />
        </>
    );
};