import React, {useContext} from "react";
import {FormContext} from "../form_buttons";
import {FormControl} from "@mui/material";
import {Box} from "@mui/system";

export interface FormErrorProps {
    field: string;
}

const FormError = ({ field }: FormErrorProps) => {
    const formContext = useContext(FormContext);
    const form = formContext.form;
    const modelField = form.field(field);

    return (
        <>
            <FormControl component={"span"} fullWidth>
                <Box className="form-input" mb={3}>
                    <span className={"form_error_" + modelField.name}></span>
                </Box>
            </FormControl>
        </>
    );
};

export default FormError;
