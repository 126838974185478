import {Holiday, HolidayRestControllerApiFactory} from "arteco-api-client-ts";
import AppList, {AppListConfig} from "../../../../components/layout/list";
import {useApiManager} from "../../../../components/utils/api";
import {Grid} from "@mui/material";
import {dateFromToString} from "../../../../components/utils/dates";

export const HolidayListPage = () => {
    const apiManager = useApiManager();

    const listConfig: AppListConfig<Holiday> = {
        title: "Días Festivos",
        onClick: "/app/admin/employee/holiday",
        decorator: (bean: Holiday) => (
            <Grid container>
                <Grid item sm={2}>
                    {bean.calendar}
                </Grid>
                <Grid item sm={2}>
                    {dateFromToString(bean.holidayDate)}
                </Grid>
                <Grid item sm>
                    {bean.description}
                </Grid>
            </Grid>
        ),
        supplier: async (currentPage, size, term, showDismissed) => {
            const ctrl = apiManager.factory(HolidayRestControllerApiFactory);
            return ctrl.pageHoliday(
                apiManager.companyId,
                currentPage,
                size,
                undefined,
                undefined,
                term,
                showDismissed
            );
        },
    };

    return (
        <>
            <AppList config={listConfig} />
        </>
    );
};