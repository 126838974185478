import React, {createRef, useContext, useEffect, useState} from "react";
import {FormContext} from "../form_buttons";
import {Autocomplete, TextField} from "@mui/material";
import {TypeConf, useType} from "../../types";
import {Box} from "@mui/system";
import {fieldMarginBottom} from "../../form_constants";

interface FormAutoCompleteProp<T> {
    field: string;
    label: string;
    type: TypeConf<T>;
    helpText?: string;
    fieldIdName?: string;
    value?: T;
    readOnly?: boolean;
    disabled?: boolean;
    onChange?: (type: TypeConf<T>, value: T | null) => void;
    onKeyUp?: (event: React.KeyboardEvent<HTMLDivElement>, inputValue: string) => void
}


function FormAutocomplete<T>(props: FormAutoCompleteProp<T>) {
    const {field, label, type, helpText, onChange, onKeyUp, fieldIdName = 'id', value, readOnly, disabled} = props;

    const inputRef = createRef<HTMLInputElement>();

    const typer = useType(type);
    const [elements, setElements] = useState<T[]>([]);
    const [selectedItem, setSelectedItem] = useState<T>(value ? value : {} as T);
    const [userInput, setUserInput] = useState<string>('');

    const formContext = useContext(FormContext);
    const model = formContext.form;
    const modelField = model.field(field);

    model.register(field, () => {
        return selectedItem && (selectedItem as any)[fieldIdName] ? selectedItem : undefined;
    }, t => setSelectedItem(t));

    const labeler = (item: T | undefined | null): string => {
        return nilToEmpty(item ? type.labeler(item) : "");
    }

    const valuer = (item: T | undefined | null): string => {
        return nilToEmpty(item ? type.valuer(item)?.toString() : "");
    }

    const nilToEmpty = (value: any): string => {
        return value ? value.toString() : "";
    }

    const changeSelectedItem = (item: T | undefined | null) => {
        setSelectedItem(item && (item as any).id ? item : {} as T);
    }

    useEffect(() => {
        const item: T | undefined = modelField.value();
        typer.supplier().then((array) => setElements(array));
        changeSelectedItem(item ? item : value);
    }, [value]);

    useEffect(() => {
        typer.supplier(userInput).then((array) => setElements(array));
    }, [userInput])

    return (
        <Box className={["form-input", "form-input-" + field].join(" ")} mb={fieldMarginBottom}>
            {elements && (
                <Autocomplete
                    fullWidth
                    id={field}
                    value={selectedItem}
                    options={elements}
                    disablePortal
                    readOnly={readOnly}
                    disabled={disabled}
                    isOptionEqualToValue={(option, value) => {
                        return valuer(option) == valuer(value);
                    }}
                    getOptionLabel={(option: T) => {
                        return labeler(option);
                    }}
                    onChange={(event, newValue) => {
                        changeSelectedItem(newValue);
                        if (onChange) {
                            onChange(type, newValue);
                        }
                    }}
                    onInputChange={(event: React.SyntheticEvent, value: string) => {
                        setUserInput(value);
                    }}

                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={label}
                            fullWidth
                            helperText={helpText}
                            value={labeler(selectedItem)}
                            onKeyUp={onKeyUp ? (event) => onKeyUp(event, inputRef.current?.value || '') : undefined}
                            inputRef={inputRef}
                            inputProps={{
                                ...params.inputProps,
                            }}
                        />
                    )}
                />
            )}
        </Box>
    );
}

export default FormAutocomplete;
