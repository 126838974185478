import AdminInner from "../../../components/layout/admin_inner";
import {useApiManager} from "../../../components/utils/api";
import {
    EmployeeVacationList,
    EmpVacationRestControllerApiFactory,
    VacationListStateEnum,
    VacationRequest,
    VacationRequestTypeEnum,
} from "arteco-api-client-ts";
import React, {useEffect, useState} from "react";
import {Box, Grid, List, ListItem, ListItemText, Typography} from "@mui/material";
import {dateFromToString} from "../../../components/utils/dates";
import {FormContent} from "../../../components/form/form_inputs/form_buttons";
import {useForm, UseFormProps} from "../../../components/form/form_model";
import {FormInput} from "../../../components/form/form_inputs/form_input";
import FormSelect from "../../../components/form/form_inputs/form_select";
import {types} from "../../../components/form/types";
import {H2} from "../../../components/utils/typography_consts";


export const EmployeeVacationPage = () => {
    const apiManager = useApiManager();
    const vacationApi = apiManager.factory(EmpVacationRestControllerApiFactory);
    const [vacations, setVacations] = useState<EmployeeVacationList>();

    const loadVacations = () => {
        apiManager.executeRaw<EmployeeVacationList>(vacationApi.getEmployeeVacations())
            .then((resp) => setVacations(resp.data));
    };

    useEffect(() => {
        loadVacations();
    }, []);

    const vacationForm = useForm({
        updater: (bean) => vacationApi.newEmployeeVacationRequest(bean),
        factory: () => {
            return {
                type: VacationRequestTypeEnum.HOLIDAY
            } as VacationRequest;
        },
        postUpdate: (bean) => loadVacations(),
        preUpdate: bean => {
			if (bean.type != "HOLIDAY" && bean.type != "GIFT" && bean.type != "UNPAID_LEAVE") {
				return confirm("Recuerda anexar el documento justificativo en la sección de \"Tus Documentos\"");
			}
            return true;
        }
    } as UseFormProps<VacationRequest>);


    return (
        <>
            <AdminInner title={"Ausencias " + new Date().getFullYear()}>
                {vacations && (
                    <>
                        <Box mb={4}>Cómputo: {vacations.taken} de {vacations.allowed}</Box>


                        <FormContent form={vacationForm} withoutPaper hideTopButtons={true}>

                            <Typography mb={2} component={H2} variant={"subtitle2"}>
                                Nueva solicitud
                            </Typography>

                            <Typography mb={2} variant={"body2"}>
                                Desde y hasta inclusive. Si las vacaciones es de un sólo día poner el mismo día en ambos.
                                El sistema tiene en cuenta fines de semana y festivos para el cálculo.
                            </Typography>

                            <Grid container spacing={3}>
                                <Grid item sm>
                                    <FormInput
                                        field={"periodFrom"}
                                        label={"Desde"}
                                        type={"date"}
                                    />
                                </Grid>
                                <Grid item sm>
                                    <FormInput
                                        field={"periodTo"}
                                        label={"Hasta"}
                                        type={"date"}
                                    />
                                </Grid>
                                <Grid item sm>
                                    <FormSelect
                                        field={"type"}
                                        label={"Tipo"}
                                        type={types.vacationType}
                                    />
                                </Grid>
                            </Grid>
                            <FormInput field={"title"} label={"Comentario"}/>
                        </FormContent>


                        <List sx={{fontSize: "0.7em"}}>
                            {vacations.vacations &&
                                vacations.vacations.map((vacation, idx) => (
                                    <ListItem divider key={idx}
                                              sx={{color: VacationListStateEnum.PENDING == vacation.state ? "lightgray" : "inherit"}}>
                                        <ListItemText
                                            primary={dateFromToString(vacation.periodFrom)+" - "+dateFromToString(vacation.periodTo)+" - ["+vacation.state+"]"}
                                            secondary={vacation.type+": "+vacation.title}>

                                        </ListItemText>
                                    </ListItem>
                                ))}
                        </List>
                    </>
                )}
            </AdminInner>
        </>
    );
};

