import React from "react";

import {BillDiscount, BillDiscountLine, BillDiscountRestControllerApiFactory,} from "arteco-api-client-ts";
import {FormContent} from "../../../../components/form/form_inputs/form_buttons";
import {FormInput} from "../../../../components/form/form_inputs/form_input";
import {Grid} from "@mui/material";
import {useForm, UseFormProps} from "../../../../components/form/form_model";
import {useApiManager} from "../../../../components/utils/api";
import FormDataGrid, {FormDataGridColumn,} from "../../../../components/form/form_inputs/form_data_grid";

export const BillDiscountEditPage = () => {
	const apiManager = useApiManager();
	const companyId = apiManager.companyId;
	const ctrl = apiManager.factory(BillDiscountRestControllerApiFactory);


	const form = useForm({
		fetcher: (id) => ctrl.getBillDiscount(id),
		updater: (bean) => ctrl.saveBillDiscount(bean),
		remover: (id) => ctrl.deleteOrDismissBillDiscount(id),
		factory: () => {
			return {
				company: {id: companyId},
			} as BillDiscount;
		}
	} as UseFormProps<BillDiscount>);


	const columns: FormDataGridColumn<BillDiscountLine>[] = [
		{editable: true, key: "amount", name: "Importe", width: "30%"},
		{editable: true, key: "percentage", name: "Descuento % (0-100)", width: "30%"},
	];

	return (
		<>
			<FormContent form={form} title={"Formulario de descuento"}>

				<FormInput field={"name"} label={"Nombre"}/>
				<FormInput field={"description"} label={"Description"} rows={5}/>

				<Grid container spacing={3}>
					<Grid item sm={9}>

						{form.getId() && (
							<FormDataGrid
								title={"Tramos del descuento"}
								columns={columns}
								field={"lines"}
								onRemove={(ids) => {
									return apiManager
									.execute(ctrl.removeLinesBillDiscount(form.getIdOrErr(), ids))
									.then(() => {
										apiManager.enqueueSnackbar("Eliminación realizada");
									});
								}}
								onAppend={() => apiManager.execute(ctrl.appendLineBillDiscount(form.getIdOrErr()))}
							/>
						)}
					</Grid>

				</Grid>


			</FormContent>
		</>
	);
};