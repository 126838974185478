import {FormContent} from "../../../../../components/form/form_inputs/form_buttons";
import FormAutocomplete from "../../../../../components/form/form_inputs/form_autocomplete";
import {types} from "../../../../../components/form/types";
import {Box, Grid, TextField} from "@mui/material";
import {FormInput} from "../../../../../components/form/form_inputs/form_input";
import React, {useEffect, useState} from "react";
import {useForm, UseFormProps} from "../../../../../components/form/form_model";
import {useApiManager} from "../../../../../components/utils/api";
import {
    CustomerRestControllerApiFactory, DedicationBillingSummary,
    DedicationList,
    DedicationRestControllerApiFactory, PersonDedicationHelp,
    PersonList, PersonRestControllerApiFactory
} from "arteco-api-client-ts";
import FormToggle from "../../../../../components/form/form_inputs/form_toggle";
import {dateToString} from "../../../../../components/utils/dates";
import {numberToString} from "../../../../../components/utils/numbers";
import {DedicationSummary} from "./dedication_table";

export interface DedicationFormProps {
    year: number;
    month: number;
    person?: PersonList;
    summary? : DedicationSummary;
    dedication: DedicationList | undefined;
    onChange: (dedication: DedicationList | undefined) => void;
}

const DedicationForm = ({dedication, onChange, year, month, person, summary}: DedicationFormProps) => {
    const apiManager = useApiManager();
    const dedicationCtrl = apiManager.factory(DedicationRestControllerApiFactory);
    const customerCtrl = apiManager.factory(CustomerRestControllerApiFactory);
    const personCtrl = apiManager.factory(PersonRestControllerApiFactory);

    const [customerNotes, setCustomerNotes] = useState<string>("");
    const [personHelp, setPersonHelp] = useState<PersonDedicationHelp>({
        privateNotes: "",
        salaryAmt: 0.0
    });

    const factory = (useProp: boolean): DedicationList => {
        if (useProp && dedication && dedication.id) {
            return dedication;
        } else {
            return {
                year,
                month,
                person,
            } as any as DedicationList
        }
    }


    const dedicationForm = useForm({
        factory: () => factory(true),
        updater: (bean) => dedicationCtrl.saveDedication(bean).then(t => {
            onChange(undefined);
        }),
        remover: (id) => dedicationCtrl.deleteOrDismissDedication(id).then(t => {
            onChange(undefined);
        }),
        postRemove: _ => undefined, // evita la navegación
        extraActions: [
            {
                label: "Nuevo",
                action: () => {
                    onChange(undefined);
                },
            },
        ]
    } as UseFormProps<DedicationList>);

    useEffect(() => {
        dedicationForm.loadForced(factory(true));
        if (dedication?.customer?.id) {
            customerCtrl.getCustomer(dedication.customer.id).then(c => {
                setCustomerNotes(c.data.observation || "");
            })
        }
        if (dedication?.person?.id || person?.id) {
            let personId = (dedication?.person?.id || person?.id) as number;
            personCtrl.dedicationHelpPerson(personId).then(c => {
                setPersonHelp(c.data);
            })
        }
    }, [dedication, person]);

    return (
        <Box my={4}>

            <FormContent form={dedicationForm} title={"Nueva dedicación"}
                         elevation={0} padding={0} withoutPaper
                         hideTopButtons={true} hideMetadata>

                <Grid container spacing={3} mt={1}>
                    <Grid item sm={2}>
                        <FormInput field={"id"} type={"number"} label={"Id"}/>
                    </Grid>

                    <Grid item sm>
                        <FormAutocomplete
                            field={"person"}
                            label={"Persona"}
                            type={types.people}
                            disabled={!!person}
                            onChange={(type, item) => {
                                if (item && item.id) {
                                    personCtrl.dedicationHelpPerson(item.id).then(c => {
                                        setPersonHelp(c.data);
                                    })
                                }
                            }}
                        />
                    </Grid>

                    <Grid item sm>
                        <FormAutocomplete
                            field={"customer"}
                            label={"Cliente"}
                            type={types.customers}
                            onChange={(type, item) => {
                                let notes = "";
                                if (item) {
                                    notes = item.observation ? item.observation : "";
                                }
                                setCustomerNotes(notes);
                            }}
                        />
                    </Grid>
                    {summary && (
                        <Grid item xs={2} textAlign={"right"}>
                            Horas según ausencias<br/> <b>{summary?.plannedHours}</b>
                        </Grid>
                    )}
                </Grid>


                <Grid container spacing={3}>
                    <Grid item sm={2}><FormInput type={"text"} field={"hours"} label={"Horas"}/></Grid>
                    <Grid item sm={2}><FormInput type={"text"} field={"rate"} label={"Tarifa"}/></Grid>
                    <Grid item sm><FormInput type={"text"} field={"peopleGroup"} label={"Grupo Personas"}/></Grid>
                    <Grid item sm><FormInput type={"text"} field={"billingGroup"} label={"Grupo Fra"}/></Grid>
                    <Grid item sm={2}><FormInput type={"text"} field={"adjustedHours"} label={"Ajuste"}/></Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item sm={4}><FormInput field={"role"} label={"Rol"}/></Grid>
                    <Grid item sm={6}><FormInput field={"manager"} label={"Mánager"}/></Grid>
                    <Grid item sm={2}><FormToggle field={"validated"} label={"Validado"}/></Grid>
                </Grid>

                <FormInput type={"text"} field={"subject"} label={"Concepto"}/>
            </FormContent>

            <Grid container spacing={3}>
                <Grid item sm={6}>
                    <Grid container spacing={2} mb={3}>
                        <Grid item sm={4}>
                            <TextField fullWidth label={"Precio hora"} rows={1} value={numberToString(personHelp.avgHourRate)} disabled/>
                        </Grid>
                        <Grid item sm={4}>
                            <TextField fullWidth label={"Salario"} rows={1} value={numberToString(personHelp.salaryAmt)} disabled/>
                        </Grid>
                        <Grid item sm={4}>
                            <TextField fullWidth label={"Desde"} rows={1} value={dateToString(personHelp.salaryStart)} disabled/>
                        </Grid>
                    </Grid>
                    <TextField fullWidth label={"Notas de la persona"} multiline rows={6} value={personHelp.privateNotes} disabled/>
                </Grid>
                <Grid item sm={6}>
                    <TextField fullWidth label={"Notas del cliente"} multiline rows={10} value={customerNotes} disabled/>
                </Grid>
            </Grid>

        </Box>
    );
}

export default DedicationForm;