import {Grid} from "@mui/material";
import FormFile, {fileBlobFromAttachment, fileBlobFromAxios} from "../form_file";
import React, {useContext, useEffect, useState} from "react";
import {useApiManager} from "../../../utils/api";
import {AxiosPromise} from "axios";
import {Attachment} from "arteco-api-client-ts";
import {FormContext} from "../form_buttons";

export interface FormFilePictureProps {
    field: string;
    label: string;
    helpText?: string;
    photoDownloader: () => AxiosPromise<string[]>;
    disablePaste?: boolean;
}

const FormFilePicture = ({field, label, helpText, photoDownloader, disablePaste}: FormFilePictureProps) => {
    const apiManager = useApiManager();
    const form = useContext(FormContext).form;

    const [photoUrl, setPhotoUrl] = useState<string>("");


    useEffect(() => {
        form.withId(id => {
            const promise = photoDownloader();
            promise.then(resp => {
                const {filename, url} = fileBlobFromAxios(resp);
                setPhotoUrl(url);
            })
        })

    }, []);

    const updateImage = (attach: Attachment | undefined) => {
        if(attach){
            const {filename, url} = fileBlobFromAttachment(attach);
            setPhotoUrl(url);
        }else{
            setPhotoUrl("");
        }
    }


    return (
        <Grid container spacing={3}>
            <Grid item xs>
                {photoUrl != "" && (
                    <img src={photoUrl} style={{width:"100%", maxWidth: "320"}}/>
                )}

                <FormFile
                    field={field}
                    label={label}
                    helperText={helpText}
                    onChange={(attach)=> updateImage(attach)}
                    onDownload={() => apiManager.executeRaw(photoDownloader())}
                    hideName={true} hideShow={true} enablePaste={!disablePaste}
                />
            </Grid>
        </Grid>
    );
};

export default FormFilePicture;