import {Box, Typography} from "@mui/material";
import styles from "../candidate/candidate.module.scss";
import {H1, SPAN, Typographies} from "../../components/utils/typography_consts";
import {COLOR_AZUL_ELECTRICO, COLOR_AZUL_OSCURO} from "../../components/layout/colors";
import React from "react";

export interface CandidateHeaderProps {
	title: string;
	subTitle: string;
}

export const SupportHeader = ({title, subTitle}: CandidateHeaderProps) => {
	return (
		<Box className={styles.candidate}>
			<Box className={styles.banner}>
				<Box className={styles.banner__title}>
					<Typography
						variant={Typographies.h3}
						component={H1}
						color={COLOR_AZUL_ELECTRICO}
						pt={"1.0rem"}
						pb={"4rem"}>
						{title}
					</Typography>
					<Typography
						color={COLOR_AZUL_OSCURO}
						variant={Typographies.h2}
						component={SPAN}>
						{subTitle}
					</Typography>
				</Box>
			</Box>
		</Box>
	)
}