import {useApiManager} from "../../../components/utils/api";
import {FormContent} from "../../../components/form/form_inputs/form_buttons";
import {useForm, UseFormProps} from "../../../components/form/form_model";
import {EmployeeData, EmployeeRestControllerApiFactory} from "arteco-api-client-ts";
import {EmployeeDataFields} from "../../../components/layout/shared/person/employee_data";
import {useAuthManager} from "../../../components/utils/auth";


export const EmployeeInfoPage = () => {
    const apiManager = useApiManager();
    const employeeCtrl = apiManager.factory(EmployeeRestControllerApiFactory);
    const authManager = useAuthManager();


    const employeeForm = useForm({
        fetcher: (_) => employeeCtrl.viewEmployeeInfo(),
        updater: (bean) => employeeCtrl.updateEmployeeInfo(bean),
        factory: () => employeeCtrl.viewEmployeeInfo(),
        modelIdFetcher: () => authManager.getUserId()
    } as UseFormProps<EmployeeData>);

    return (
        <>
            <FormContent hideMetadata={true} form={employeeForm} title={"Datos personales"}>
                <EmployeeDataFields lockFields
                                    photoDownloader={() => employeeCtrl.viewEmployeePhoto(employeeForm.getIdOrErr())}/>
            </FormContent>
        </>
    );
};