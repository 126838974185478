import {RecruitmentLayout} from "./pages/admin/recruitment/layout";
import {Route} from "react-router-dom";
import {AdminRecruitmentPage} from "./pages/admin/recruitment";
import {CandidateListPage} from "./pages/admin/recruitment/candidate";
import {CandidateEditPage} from "./pages/admin/recruitment/candidate/form";
import {CandidateApplicationListPage} from "./pages/admin/recruitment/candidate/application";
import {CandidateApplicationEditPage} from "./pages/admin/recruitment/candidate/application/form";
import {CandidateEventListPage} from "./pages/admin/recruitment/candidate/event";
import {CandidateEventEditPage} from "./pages/admin/recruitment/candidate/event/form";
import {CandidateLevelSolutionListPage} from "./pages/admin/recruitment/candidatelevelsolution";
import {CandidateLevelSolutionEditPage} from "./pages/admin/recruitment/candidatelevelsolution/form";
import {CandidateDocumentListPage} from "./pages/admin/recruitment/candidate/document";
import {CandidateDocumentEditPage} from "./pages/admin/recruitment/candidate/document/form";
import {RecruitmentListPage} from "./pages/admin/recruitment/recruitment";
import {RecruitmentEditPage} from "./pages/admin/recruitment/recruitment/form";
import {RecruitmentApplicationListPage} from "./pages/admin/recruitment/recruitment/application";
import {RecruitmentApplicationEditPage} from "./pages/admin/recruitment/recruitment/application/form";
import {RecruitmentDocumentListPage} from "./pages/admin/recruitment/recruitment/document";
import {RecruitmentDocumentEditPage} from "./pages/admin/recruitment/recruitment/document/form";
import {SkillListPage} from "./pages/admin/recruitment/skill";
import {SkillEditPage} from "./pages/admin/recruitment/skill/form";
import {ApplicationListPage} from "./pages/admin/recruitment/application";
import {ApplicationEditPage} from "./pages/admin/recruitment/application/form";
import {SkillLevelListPage} from "./pages/admin/recruitment/skilllevel";
import {SkillLevelEditPage} from "./pages/admin/recruitment/skilllevel/form";
import {CandidateSourceListPage} from "./pages/admin/recruitment/source";
import {CandidateSourceEditPage} from "./pages/admin/recruitment/source/form";
import {ApplicantStateListPage} from "./pages/admin/recruitment/state";
import {ApplicantStateEditPage} from "./pages/admin/recruitment/state/form";
import {TrainingLevelListPage} from "./pages/admin/recruitment/traininglevel";
import {TrainingLevelEditPage} from "./pages/admin/recruitment/traininglevel/form";
import {CandidateStateListPage} from "./pages/admin/recruitment/candidatestate";
import {CandidateStateEditPage} from "./pages/admin/recruitment/candidatestate/form";
import {CandidateTypeListPage} from "./pages/admin/recruitment/candidatetype";
import {CandidateTypeEditPage} from "./pages/admin/recruitment/candidatetype/form";
import {WikiPageListPage} from "./pages/admin/employee/wiki";
import {SECTION_RECRUITMENT, WikiPageEditPage} from "./pages/admin/employee/wiki/form";
import {CandidateLevelTestListPage} from "./pages/admin/recruitment/candidateleveltest";
import {CandidateLevelTestEditPage} from "./pages/admin/recruitment/candidateleveltest/form";
import * as React from "react";
import SideBarItem from "./sidebar_item";
import {
	CallSplit,
	CastForEducation,
	EditLocation,
	LocalMall,
	LocalOffer, NoteAlt,
	PeopleOutline,
	PlaylistAddCheck
} from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import QuizIcon from "@mui/icons-material/Quiz";
import RunCircleIcon from "@mui/icons-material/RunCircle";
import BadgeIcon from "@mui/icons-material/Badge";


const AdminRecruitmentRoutes = () => {
	return (
		<Route path="/app/admin/recruitment" element={<RecruitmentLayout/>}>
			<Route index element={<AdminRecruitmentPage/>}/>
			<Route path="/app/admin/recruitment/candidate"
				   element={<CandidateListPage/>}/>
			<Route path="/app/admin/recruitment/candidate/new"
				   element={<CandidateEditPage/>}/>
			<Route path="/app/admin/recruitment/candidate/:candidateId"
				   element={<CandidateEditPage/>}/>
			<Route path="/app/admin/recruitment/candidate/:candidateId/application"
				   element={<CandidateApplicationListPage/>}/>
			<Route path="/app/admin/recruitment/candidate/:candidateId/application/:id"
				   element={<CandidateApplicationEditPage/>}/>
			<Route path="/app/admin/recruitment/candidate/:candidateId/application/new"
				   element={<CandidateApplicationEditPage/>}/>
			<Route path="/app/admin/recruitment/candidate/:candidateId/event"
				   element={<CandidateEventListPage/>}/>
			<Route path="/app/admin/recruitment/candidate/:candidateId/event/:id"
				   element={<CandidateEventEditPage/>}/>
			<Route path="/app/admin/recruitment/candidate/:candidateId/event/new"
				   element={<CandidateEventEditPage/>}/>
			<Route path="/app/admin/recruitment/candidate/:candidateId/solution"
				   element={<CandidateLevelSolutionListPage/>}/>
			<Route path="/app/admin/recruitment/candidate/:candidateId/solution/:id"
				   element={<CandidateLevelSolutionEditPage/>}/>
			<Route path="/app/admin/recruitment/candidate/:candidateId/solution/new"
				   element={<CandidateLevelSolutionEditPage/>}/>
			<Route path="/app/admin/recruitment/candidate/:candidateId/document"
				   element={<CandidateDocumentListPage/>}/>
			<Route path="/app/admin/recruitment/candidate/:candidateId/document/:id"
				   element={<CandidateDocumentEditPage/>}/>
			<Route path="/app/admin/recruitment/candidate/:candidateId/document/new"
				   element={<CandidateDocumentEditPage/>}/>
			<Route path="/app/admin/recruitment/recruitment"
				   element={<RecruitmentListPage/>}/>
			<Route path="/app/admin/recruitment/recruitment/new"
				   element={<RecruitmentEditPage/>}/>
			<Route path="/app/admin/recruitment/recruitment/:recruitmentId"
				   element={<RecruitmentEditPage/>}/>
			<Route path="/app/admin/recruitment/recruitment/:recruitmentId/application"
				   element={<RecruitmentApplicationListPage/>}/>
			<Route
				path="/app/admin/recruitment/recruitment/:recruitmentId/application/:id"
				element={<RecruitmentApplicationEditPage/>}/>
			<Route
				path="/app/admin/recruitment/recruitment/:recruitmentId/application/new"
				element={<RecruitmentApplicationEditPage/>}/>

			<Route path="/app/admin/recruitment/recruitment/:recruitmentId/document"
				   element={<RecruitmentDocumentListPage/>}/>
			<Route
				path="/app/admin/recruitment/recruitment/:recruitmentId/document/:id"
				element={<RecruitmentDocumentEditPage/>}/>
			<Route
				path="/app/admin/recruitment/recruitment/:recruitmentId/document/new"
				element={<RecruitmentDocumentEditPage/>}/>

			<Route path="/app/admin/recruitment/skill" element={<SkillListPage/>}/>
			<Route path="/app/admin/recruitment/skill/:id" element={<SkillEditPage/>}/>
			<Route path="/app/admin/recruitment/skill/new" element={<SkillEditPage/>}/>
			<Route path="/app/admin/recruitment/application"
				   element={<ApplicationListPage/>}/>
			<Route path="/app/admin/recruitment/application/:id"
				   element={<ApplicationEditPage/>}/>
			<Route path="/app/admin/recruitment/application/new"
				   element={<ApplicationEditPage/>}/>
			<Route path="/app/admin/recruitment/skilllevel"
				   element={<SkillLevelListPage/>}/>
			<Route path="/app/admin/recruitment/skilllevel/:id"
				   element={<SkillLevelEditPage/>}/>
			<Route path="/app/admin/recruitment/skilllevel/new"
				   element={<SkillLevelEditPage/>}/>
			<Route path="/app/admin/recruitment/source"
				   element={<CandidateSourceListPage/>}/>
			<Route path="/app/admin/recruitment/source/:id"
				   element={<CandidateSourceEditPage/>}/>
			<Route path="/app/admin/recruitment/source/new"
				   element={<CandidateSourceEditPage/>}/>
			<Route path="/app/admin/recruitment/state"
				   element={<ApplicantStateListPage/>}/>
			<Route path="/app/admin/recruitment/state/:id"
				   element={<ApplicantStateEditPage/>}/>
			<Route path="/app/admin/recruitment/state/new"
				   element={<ApplicantStateEditPage/>}/>
			<Route path="/app/admin/recruitment/traininglevel"
				   element={<TrainingLevelListPage/>}/>
			<Route path="/app/admin/recruitment/traininglevel/:id"
				   element={<TrainingLevelEditPage/>}/>
			<Route path="/app/admin/recruitment/traininglevel/new"
				   element={<TrainingLevelEditPage/>}/>
			<Route path="/app/admin/recruitment/candidatestate"
				   element={<CandidateStateListPage/>}/>
			<Route path="/app/admin/recruitment/candidatestate/:id"
				   element={<CandidateStateEditPage/>}/>
			<Route path="/app/admin/recruitment/candidatestate/new"
				   element={<CandidateStateEditPage/>}/>
			<Route path="/app/admin/recruitment/candidatetype"
				   element={<CandidateTypeListPage/>}/>
			<Route path="/app/admin/recruitment/candidatetype/:id"
				   element={<CandidateTypeEditPage/>}/>
			<Route path="/app/admin/recruitment/candidatetype/new"
				   element={<CandidateTypeEditPage/>}/>
			<Route path="/app/admin/recruitment/wiki"
				   element={<WikiPageListPage section={SECTION_RECRUITMENT}/>}/>
			<Route path="/app/admin/recruitment/wiki/:id"
				   element={<WikiPageEditPage section={SECTION_RECRUITMENT}/>}/>
			<Route path="/app/admin/recruitment/wiki/new"
				   element={<WikiPageEditPage section={SECTION_RECRUITMENT}/>}/>
			<Route path="/app/admin/recruitment/level/test"
				   element={<CandidateLevelTestListPage/>}/>
			<Route path="/app/admin/recruitment/level/test/:id"
				   element={<CandidateLevelTestEditPage/>}/>
			<Route path="/app/admin/recruitment/level/test/new"
				   element={<CandidateLevelTestEditPage/>}/>
			<Route path="/app/admin/recruitment/level/solution"
				   element={<CandidateLevelSolutionListPage/>}/>
			<Route path="/app/admin/recruitment/level/solution/:id"
				   element={<CandidateLevelSolutionEditPage/>}/>
			<Route path="/app/admin/recruitment/level/solution/new"
				   element={<CandidateLevelSolutionEditPage/>}/>
		</Route>
	)
}

export default AdminRecruitmentRoutes;

export const AdminRecruitmentMenu = () => {
	return (
		<>
			<SideBarItem
				href={"/app/admin/recruitment/candidate"}
				label={"Candidatos"}
				icon={<PeopleOutline/>}
			/>
			<SideBarItem
				href={"/app/admin/recruitment/level/solution"}
				label={"Soluciones de nivel"}
				icon={<CheckIcon/>}
			/>
			<SideBarItem
				href={"/app/admin/recruitment/recruitment"}
				label={"Proceso de selección"}
				icon={<LocalMall/>}
			/>
			<SideBarItem
				href={"/app/admin/recruitment/application"}
				label={"Candidaturas"}
				icon={<PermIdentityIcon/>}
			/>
			<SideBarItem
				href={"/app/admin/recruitment/traininglevel"}
				label={"Niveles formativos"}
				icon={<CastForEducation/>}
			/>
			<SideBarItem
				href={"/app/admin/recruitment/skill"}
				label={"Habilidades"}
				icon={<PlaylistAddCheck/>}
			/>
			<SideBarItem
				href={"/app/admin/recruitment/skilllevel"}
				label={"Niveles de habilidad"}
				icon={<LocalOffer/>}
			/>
			<SideBarItem
				href={"/app/admin/recruitment/level/test"}
				label={"Pruebas de nivel"}
				icon={<QuizIcon/>}
			/>
			<SideBarItem
				href={"/app/admin/recruitment/source"}
				label={"Origen de candidatos"}
				icon={<CallSplit/>}
			/>
			<SideBarItem
				href={"/app/admin/recruitment/state"}
				label={"Estados del proceso"}
				icon={<EditLocation/>}
			/>
			<SideBarItem
				href={"/app/admin/recruitment/candidatestate"}
				label={"Estados del candidato"}
				icon={<RunCircleIcon/>}
			/>
			<SideBarItem
				href={"/app/admin/recruitment/candidatetype"}
				label={"Tipos del candidato"}
				icon={<BadgeIcon/>}
			/>
			<SideBarItem
				href={"/app/admin/recruitment/wiki"}
				label={"Wiki"}
				icon={<NoteAlt/>}
			/>
		</>
	)
}