import {List, ListItem, Typography} from "@mui/material";
import AdminInner from "../../../components/layout/admin_inner";
import {H1, Typographies} from "../../../components/utils/typography_consts";
import React, {useEffect, useState} from "react";
import {AdminCard} from "../../../components/layout/admin_card";
import {AdminCards} from "../../../components/layout/admin_card/admin_cards";
import {useApiManager} from "../../../components/utils/api";
import {
    EmployeeStats,
    EmployeeStatsRestControllerApiFactory,
    PersonBirthday,
    PersonEval,
    PersonSal,
    PersonTestPeriod
} from 'arteco-api-client-ts';
import {dateFromString, dateToString} from "../../../components/utils/dates";
import moment from "moment";
import {useNavigate} from "react-router-dom";

export const AdminEmployeePage = () => {
    const navigate = useNavigate();
    const apiManager = useApiManager();
    const companyId = apiManager.companyId;
    const ctrl = apiManager.factory(EmployeeStatsRestControllerApiFactory);
    const [stats, setStats] = useState<EmployeeStats>({});

    useEffect(() => {
        apiManager.execute(ctrl.getEmployeeStats(companyId)).then(resp => {
            setStats(resp);
        })
    }, []);

    const setColorAfterDays = (d: string | null | undefined, warnDays: number): string | undefined => {
        let result: string | undefined = undefined;
        if (d) {
            const days = daysBetweenDates(d);
            if (Math.abs(days) > warnDays) {
                result = "#cbbf73";
            }
        } else {
            result = "#932424";
        }
        return result;
    }

    function daysBetweenDates(d: string) {
        const date = dateFromString(d);
        return moment().diff(moment(date), 'days');
    }

    const setColorBeforeDays = (d: string | null | undefined, warnDays: number): string | undefined => {
        let result: string | undefined = undefined;
        if (d) {
            const days = daysBetweenDates(d);
            if (Math.abs(days) < warnDays) {
                result = "#932424";
            } else {
                result = "#cbbf73";
            }
        } else {
            result = "#932424";
        }
        return result;
    }

    return (
        <AdminInner>

            <Typography variant={Typographies.h3} component={H1}>
                Módulo de personal
            </Typography>
            <AdminCards>
                <AdminCard title={"Próximos cumpleaños"}>
                    <small>Celebraciones de los próximos 30 días</small>
                    <List>
                        {stats.nextBirthdays && stats.nextBirthdays.map((b: PersonBirthday, i: number) => (
                            <ListItem key={"birth-" + i}
                                      button
                                      onClick={_ => navigate("/app/admin/employee/person/" + b.personId)}
                            >{b.dayOfMonth}/{b.month} - {b.personName}</ListItem>
                        ))}
                        {!stats.nextBirthdays && (
                            <li>No hay próximos eventos</li>
                        )}
                    </List>
                </AdminCard>
                <AdminCard title={"A quién entrevistar"}>
                    <small>Se comprueba la fecha de entrevista de la evaluación</small>
                    <List>
                        {stats.pendingEvaluations && stats.pendingEvaluations.map((b: PersonEval, i: number) => (
                            <ListItem key={"eval-" + i}
                                      button
                                      onClick={_ => navigate("/app/admin/employee/person/" + b.personId)}
                                      sx={{color: setColorAfterDays(b.lastEvaluation, 64)}}>{dateToString(b.lastEvaluation)} - {b.personName}</ListItem>
                        ))}
                        {!stats.pendingEvaluations && (
                            <li>No hay empleados pendientes</li>
                        )}
                    </List>
                </AdminCard>
                <AdminCard title={"Salarios a revisar"}>
                    <small>Se comprueba la fecha de inicio del último salario</small>
                    <List>
                        {stats.pendingSalaries && stats.pendingSalaries.map((b: PersonSal, i: number) => (
                            <ListItem key={"eval-" + i}
                                      button
                                      onClick={_ => navigate("/app/admin/employee/person/" + b.personId)}
                                      sx={{color: setColorAfterDays(b.lastSalary, 365)}}>{dateToString(b.lastSalary)} - {b.personName}</ListItem>
                        ))}
                        {!stats.pendingSalaries && (
                            <li>No hay empleados pendientes</li>
                        )}
                    </List>
                </AdminCard>
                <AdminCard title={"Períodos de prueba"}>
                    <small>Se comprueba la fecha de fin del período de prueba</small>
                    <List>
                        {stats.periodTest && stats.periodTest.map((b: PersonTestPeriod, i: number) => (
                            <ListItem key={"eval-" + i}
                                      button
                                      onClick={_ => navigate("/app/admin/employee/person/" + b.personId)}
                                      sx={{color: setColorBeforeDays(b.testPeriod, 30)}}>{dateToString(b.testPeriod)} - {b.personName}</ListItem>
                        ))}
                        {!stats.periodTest && (
                            <li>No hay empleados pendientes</li>
                        )}
                    </List>
                </AdminCard>

            </AdminCards>

        </AdminInner>
    );
};