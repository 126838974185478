import {CandidateLevelTest, CandidateLevelTestRestControllerApiFactory} from "arteco-api-client-ts";
import AppList, {AppListConfig} from "../../../../components/layout/list";
import {COLOR_DISMISSED} from "../../../../components/layout/colors";
import {Grid} from "@mui/material";
import {useApiManager} from "../../../../components/utils/api";
import React from "react";

export const CandidateLevelTestListPage = () => {
    const apiManager = useApiManager();

    const listConfig: AppListConfig<CandidateLevelTest> = {
        title: "Pruebas de Nivel",
        onClick: "/app/admin/recruitment/level/test",
        decorator: (bean: CandidateLevelTest) => (
            <Grid container style={{color: bean.dismissed ? COLOR_DISMISSED : ""}}>
                <Grid item sm>
                    {bean.name}
                </Grid>
            </Grid>
        ),
        supplier: async (currentPage, size, term, showDismissed) => {
            const companyId = apiManager.companyId;
            const ctrl = apiManager.factory(CandidateLevelTestRestControllerApiFactory);
            return ctrl.pageCandidateLevelTest(companyId, currentPage, size, undefined, term, showDismissed);
        },
    };

    return (
        <>
            <AppList config={listConfig}/>
        </>
    );
};