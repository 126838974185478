import React from "react";
import {Company, AdminCompanyRestControllerApiFactory} from "arteco-api-client-ts";

import {FormContent} from "../../../components/form/form_inputs/form_buttons";
import {FormInput} from "../../../components/form/form_inputs/form_input";
import {Grid} from "@mui/material";
import {FormAddress} from "../../../components/form/form_inputs/form_address";
import {useForm, UseFormProps} from "../../../components/form/form_model";
import {useApiManager} from "../../../components/utils/api";

export const SuperCompanyEditPage = () => {
    const manager = useApiManager();
    const ctrl = manager.factory(AdminCompanyRestControllerApiFactory);

    const form = useForm({
        fetcher: (id) => ctrl.getCompany(id),
        updater: (bean) => ctrl.saveCompany(bean),
        remover: (id) => ctrl.deleteOrDismissCompany(id),
        factory: () => {
            return {} as Company;
        },
    } as UseFormProps<Company>);

    return (
        <>
            <FormContent form={form} title={"Información de la empresa"}>
                <Grid container spacing={3}>
                    <Grid item sm>
                        <FormInput type={"text"} field={"name"} label={"Nombre"} />
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item sm={4}>
                        <FormInput type={"text"} field={"nif"} label={"Nif"} />
                    </Grid>
                    <Grid item sm={4}>
                        <FormInput type={"text"} field={"email"} label={"Email"} />
                    </Grid>
                    <Grid item sm={4}>
                        <FormInput type={"text"} field={"telephone"} label={"Telefono"} />
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item sm>
                        <FormInput type={"text"} field={"bankAccount"} label={"Cuenta bancaria"} />
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item sm>
                        <FormInput type={"text"} field={"website"} label={"Página web"} />
                    </Grid>
                </Grid>

                <FormAddress field={"address"} />
            </FormContent>
        </>
    );
};