import {Box, Button, ButtonGroup, FormControlLabel, Grid, Modal, Switch, TextField, Typography} from "@mui/material";
import {CustomNumberFormat} from "../../../../../components/utils/custom_number_format";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import React, {useEffect, useState} from "react";
import {
    CustomerList,
    DedicationList,
    DedicationRestControllerApiFactory,
    OccupationPersonYear,
    PersonList
} from "arteco-api-client-ts";
import {OccupationProcessed, PersonOccupation} from "../occupation_utils";
import DedicationForm from "./dedication_form";
import {useApiManager} from "../../../../../components/utils/api";
import {dedicationModalStyle} from "../../../../employee/modal";
import {fileLocalSave} from "../../../../../components/form/form_inputs/form_file";


const GROUP_BY_PERSON = 'person';
const GROUP_BY_PERSON_AND_CUSTOMER = 'person_and_customer';

interface GroupHandler {
    name: string;
    keyer: (dedication: DedicationList) => number | string | undefined;
    customer: (dedication: DedicationList) => CustomerList | undefined;
    person: (dedication: DedicationList) => PersonList | undefined;
    manager: (dedication: DedicationList) => string | undefined;
    billingGroup: (dedication: DedicationList) => string | undefined;
}

const GROUPS: GroupHandler[] = [
    {
        name: GROUP_BY_PERSON,
        keyer: dedication => dedication.person?.id,
        person: dedication => dedication.person,
        customer: dedication => undefined,
        manager: dedication => undefined,
        billingGroup: dedication => undefined,
    },
    {
        name: GROUP_BY_PERSON_AND_CUSTOMER,
        keyer: dedication => dedication.customer.name + "_" + dedication.person?.id,
        person: dedication => dedication.person,
        customer: dedication => dedication.customer,
        manager: dedication => undefined,
        billingGroup: dedication => undefined,
    }
];

export interface Dedicable {
    selectedDedicationIds: number[];
    setSelectedDedicationIds: (elems: number[]) => void;
}

export interface DedicationListProps {
    occupation: OccupationProcessed;
    year: number;
    month: string;
    showPerson: boolean;
    personId: number | undefined;

    dedicable?: Dedicable;
}

export interface DedicationSummary {
    hours: number;
    adjustedAmount: number;
    plannedHours: number;
    workableHours: number;
    adjustedHours: number;
    averageRate: number;
}

const useCorrectHours = (dedication: DedicationList, adjusted: boolean): number => {
    if (adjusted) {
        return dedication.adjustedHours || dedication.hours;
    } else {
        return dedication.hours;
    }
}

const locatePerson = (occupation: OccupationProcessed,
                      month: string,
                      filter: string | undefined,
                      personId: number | undefined): PersonList | undefined => {

    let result: PersonList | undefined = undefined;
    const mapPerson = (person: OccupationPersonYear): PersonList => {
        return {id: person.personId, surnamesAndName: person.personName} as PersonList;
    }
    const allPeople = (occupation.peopleByMonth.get(month) || []);
    if (personId) {
        // si viene la persona, se va a piñón fijo
        const person: OccupationPersonYear | undefined = allPeople.find(p => p.personId == personId);
        if (person) {
            result = mapPerson(person);
        }
    } else if (filter && filter.length > 0) {
        const lowerFilter = filter.toLowerCase();
        const partial = allPeople
            .map((opy: OccupationPersonYear) => mapPerson(opy))
            .filter(person => (person.surnamesAndName && person.surnamesAndName.toLowerCase().indexOf(lowerFilter) > -1));
        if (partial.length == 1) {
            result = partial[0];
        }
    }

    return result;
}


const DedicationTable = ({showPerson, occupation, month, year, personId, dedicable}: DedicationListProps) => {
    const apiManager = useApiManager();
    const companyId = apiManager.companyId;
    const dedicationCtrl = apiManager.factory(DedicationRestControllerApiFactory);

    const [summary, setSummary] = useState<DedicationSummary>({
        hours: 0,
        adjustedAmount: 0,
        plannedHours: 0,
        workableHours: 0,
        averageRate: 0,
    } as DedicationSummary)

    const [filter, setFilter] = useState<string | undefined>(undefined);
    const [groupBy, setGroupBy] = useState<string | undefined>(undefined);

    const [selectedPerson, setSelectedPerson] = useState<PersonList | undefined>(locatePerson(occupation, month, filter, personId));

    const [dedications, setDedications] = useState<DedicationList[]>([]);
    const [filteredDedication, setFilteredDedication] = useState<DedicationList[]>([]);
    const [selectedDedication, setSelectedDedication] = useState<DedicationList | undefined>(undefined);
    const [showSensibleInfo, setShowSensibleInfo] = useState<boolean>(true);

    const calcSummary = (dedication: DedicationList[]): DedicationSummary => {
        const hours: number = dedication
            .map(d => d.hours)
            .filter(d => !isNaN(d))
            .reduce((d1, d2) => d1 + d2, 0);

        let adjustedHours: number = 0;
        if (dedication.length > 0) {
            const tmpAdjusted = dedication
                .map(d => d.adjustedHours)
                .filter(d => d && !isNaN(d))
                .reduce((d1, d2) => (d1 || 0) + (d2 || 0), 0);
            adjustedHours = tmpAdjusted || 0;
        }

        const amount: number = dedication
            .map(d => useCorrectHours(d, true) * d.rate)
            .filter(d => !isNaN(d))
            .reduce((d1, d2) => d1 + d2, 0);

        const monthOccupation = occupation.occupationByPeopleByMonth.get(month);
        const plannedHours: number = personId
            // Ocupación de una persona
            ? monthOccupation?.get(personId)?.monthStats.workedHours || 0
            // Ocupación de todas las personas
            : Array.from(monthOccupation?.values() || [])
                .map((o: PersonOccupation) => o.monthStats.workedHours || 0)
                .reduce((n1, n2) => n1 + n2, 0);

        const workableHours: number = personId
            // Ocupación de una persona
            ? monthOccupation?.get(personId)?.monthStats.workableHours || 0
            // Ocupación de todas las personas
            : Array.from(monthOccupation?.values() || [])
                .map((o: PersonOccupation) => o.monthStats.workableHours || 0)
                .reduce((n1, n2) => n1 + n2, 0);

        const accInitial = {
            sum: 0,
            n: 0
        };

        const accFinal = dedication.reduce((d1, d2) => {
            const hours = useCorrectHours(d2, true);
            return {sum: d1.sum + hours * d2.rate, n: d1.n + hours};
        }, accInitial);

        return {
            hours,
            adjustedAmount: amount,
            plannedHours,
            workableHours,
            adjustedHours,
            averageRate: accFinal.sum / accFinal.n
        } as DedicationSummary;
    }

    const loadDedication = () => {
        apiManager.execute(dedicationCtrl.listDedication(companyId, personId, year, parseInt(month)))
            .then(dedications => {
                setDedications(dedications);
                setFilteredDedication(dedications);
            });
    }

    useEffect(() => {
        setSelectedPerson(locatePerson(occupation, month, filter, personId));
        if (month && year) {
            loadDedication();
        }
    }, [month, year, personId]);

    useEffect(() => {
        setSelectedPerson(locatePerson(occupation, month, filter, personId));
    }, []);


    useEffect(() => {
        const selectedPerson = locatePerson(occupation, month, filter, personId);

        const notNullContains = (str: string | undefined, filter: string | undefined): boolean => {
            return str && filter ? str.toLowerCase().indexOf(filter.toLowerCase()) >= 0 : false;
        }

        let filtered: DedicationList[] = filter && filter.length >= 0
            ? dedications.filter(d => {
                return (
                    selectedPerson == undefined || selectedPerson?.id == d.person.id) && (
                    notNullContains(d.customer.name, filter) ||
                    notNullContains(d.customer.shortName, filter) ||
                    notNullContains(d.person.surnamesAndName, filter) ||
                    notNullContains(d.billingGroup, filter) ||
                    notNullContains(d.peopleGroup, filter) ||
                    notNullContains(d.subject, filter) ||
                    notNullContains(d.manager, filter) ||
                    d.rate == parseInt(filter))
            })
            : dedications;


        const groupHandler = GROUPS.find(g => g.name == groupBy);
        if (groupHandler) {
            const map = new Map<number | string, DedicationList>();
            filtered.forEach((elem) => {
                const VARIOS = 'Varios';
                const key = groupHandler.keyer(elem) || VARIOS;
                let row = map.get(key);
                if (!row) {
                    row = {...elem};
                    row.id = undefined;
                    row.subject = '';
                    row.billingGroup = groupHandler.billingGroup(elem) || VARIOS;
                    row.person = groupHandler.person(elem) || {id: undefined, surnamesAndName: VARIOS} as PersonList;
                    row.customer = groupHandler.customer(elem) || {
                        id: undefined,
                        name: VARIOS,
                        shortName: VARIOS
                    } as CustomerList;
                    row.manager = groupHandler.manager(elem) || VARIOS;
                    map.set(key, row);
                } else {
                    row.rate = ((row.rate * row.hours + elem.rate * elem.hours) / (row.hours + elem.hours));
                    row.rate = Math.round(row.rate * 100) / 100;
                    row.hours += elem.hours;
                }
            })
            filtered = Array.from(map.values()) as DedicationList[];
        }

        filtered = filtered.sort((a, b) => {
            let r = a.customer.name.localeCompare(b.customer.name);
            if (r == 0) {
                r = (a.manager || "").localeCompare(b.manager || "");
            }
            if (r == 0) {
                r = (a.peopleGroup || "").localeCompare(b.peopleGroup || "");
            }
            if (r == 0) {
                r = (a.person?.surnamesAndName || "").localeCompare(b.person?.surnamesAndName || "");
            }
            if (r == 0) {
                r = (a.id || 0) - (b.id || 0);
            }
            return r;
        })


        setFilteredDedication(filtered);
        setSelectedPerson(selectedPerson);
        const summary = calcSummary(filtered);
        setSummary(summary);

    }, [dedications, filter, groupBy]);

    const [showDedicationModal, setShowDedicationModal] = useState<boolean>(false);

    const getManagerColor = (manager: string | undefined): string => {

        let palette = [
            'rgba(255, 0, 0, 0.5)',
            'rgba(255, 255, 0, 0.5)',
            'rgba(0, 255, 0, 0.5)',
            'rgba(0, 0, 255, 0.5)',
            'rgba(255, 0, 255, 0.5)',
        ];

        if (manager) {
            const managers = new Array<string>();
            filteredDedication.forEach(d => {
                if (d.manager && managers.indexOf(d.manager) < 0) {
                    managers.push(d.manager);
                }
            })

            const idx = managers.indexOf(manager);
            if (idx > -1) {
                const colorIdx = idx % palette.length;
                return palette[colorIdx];
            }
        }
        return "red";
    }

    const leftBorderWidth = 5;

    function getFilteredIds() {
        return filteredDedication
        .map(d => d.id as number)
        .filter(d => d != undefined);
    }

    return (
        <>
            <Box my={3}>
                <Alert sx={{marginY: 3}} severity="info">
                    <AlertTitle>Resumen mensual</AlertTitle>
                    <Grid container spacing={3}>
                        <Grid item>
                            <b>REAL</b><br/>
                            Horas: <CustomNumberFormat amount={summary.hours}/> <br/>
                            Importe: <CustomNumberFormat amount={summary.adjustedAmount}/>
                        </Grid>
                        <Grid item>
                            <b>TEÓRICO</b><br/>
                            Horas posibles: <CustomNumberFormat amount={summary.workableHours}/> &nbsp;
                            {selectedPerson ? "personal" : "todas"}
                            <br/>
                            Horas asistentes: <b><CustomNumberFormat amount={summary.plannedHours}/></b> &nbsp;
                            {selectedPerson ? "personal" : "todas"}
                            <br/>
                            Diff: <CustomNumberFormat
                            style={{color: (summary.hours - summary.plannedHours) >= 0.1 ? "green" : "red"}}
                            amount={summary.hours - summary.plannedHours}/>
                        </Grid>
                    </Grid>
                </Alert>


                <Grid container spacing={3}>
                    <Grid item>
                        <ButtonGroup variant="contained" aria-label="outlined primary button group">
                            <Button disabled={groupBy == undefined && ((filter?.length || 0) <= 0)} onClick={_ => {
                                setGroupBy(undefined);
                                setFilter("");
                            }}>Todas</Button>
                            <Button disabled={groupBy == GROUP_BY_PERSON}
                                    onClick={_ => setGroupBy(GROUP_BY_PERSON)}>Persona</Button>
                            <Button disabled={groupBy == GROUP_BY_PERSON_AND_CUSTOMER}
                                    onClick={_ => setGroupBy(GROUP_BY_PERSON_AND_CUSTOMER)}>Y cliente</Button>
                        </ButtonGroup>
                    </Grid>
                    <Grid item>
                        <Button variant={"contained"}
                                title={"Validar dedicaciones visibles"}
								disabled={filteredDedication.length < 1}
                                onClick={_ => {
                                    if (filteredDedication &&
                                        filteredDedication.length > 0 &&
                                        confirm("Desea validar las dedicaciones visibles?")) {
                                        const ids = getFilteredIds();
                                        apiManager
                                            .execute(dedicationCtrl.validateInBatchDedication(ids))
                                            .then(_ => loadDedication())
                                    }
                                }}>
                            Validar
                        </Button>
                        <Button sx={{marginLeft: "1em"}} variant={"contained"}
                                title={"Al cortapapeles"}
								disabled={filteredDedication.length < 1}
                                onClick={_ => {
                                    const selection = window.getSelection();
                                    const table = document.getElementById("dedicationTable");
                                    if (table && selection) {
                                        selection.removeAllRanges();
                                        const range = document.createRange();
                                        range.selectNode(table);
                                        selection.addRange(range);
                                        //navigator.clipboard.writeText(table.outerHTML);
                                        document.execCommand('copy');
                                        selection.removeAllRanges();
                                        alert("Tabla HTML tal y como se muestra copiada en el portapapeles");
                                    }
                                }}>
                            Copiar
                        </Button>
                        <Button sx={{marginLeft: "1em"}} variant={"contained"}
                                disabled={filteredDedication.length < 1}
                                onClick={_ => {
                                    apiManager
                                    .executeRaw(dedicationCtrl.exportXlsDedication(getFilteredIds()))
                                    .then((resp) => fileLocalSave(resp));
                                }}>
                            Xls
                        </Button>
                        <Button sx={{marginLeft: "1em"}} variant={"contained"}
                                onClick={_ => {
                                    setSelectedDedication({
                                        year: new Date().getFullYear(),
                                        month: new Date().getMonth()
                                    } as DedicationList);
                                    setShowDedicationModal(true);
                                }}>
                            Nueva
                        </Button>
                    </Grid>

                    <Grid item sx={{flexGrow: 1}}>
                        <FormControlLabel control={<Switch checked={showSensibleInfo}
                                                           onChange={e => setShowSensibleInfo(!showSensibleInfo)}/>}
                                          label="Importes"/>
                    </Grid>

                    <Grid item>
                        <TextField value={filter} label="Filtro" variant="outlined" size={"small"}
                                   sx={{width: "10em;"}}
                                   onChange={(e) => {
                                       const value = e.target.value;
                                       if (value != "") {
                                           setFilter(e.target.value);
                                       } else {
                                           setFilter(undefined);
                                       }
                                   }}/>
                    </Grid>
                </Grid>

            </Box>
            {dedicable && (
                <Box sx={{py: 2}}>
                    <ButtonGroup size={"small"}>
                        <Button onClick={_ => {
                            const newArr = [];
                            const checks = document.getElementsByClassName("dedi-check");
                            for (let i = 0; i < checks.length; i++) {
                                const check = checks.item(i) as HTMLInputElement;
                                check.checked = true;
                                newArr.push(parseInt(check.value));
                            }
                            dedicable?.setSelectedDedicationIds(newArr);
                        }}>todas</Button>
                        <Button onClick={_ => {
                            let newArr: number[] = [];
                            const checks = document.getElementsByClassName("dedi-check");
                            for (let i = 0; i < checks.length; i++) {
                                const check = checks.item(i) as HTMLInputElement;
                                if (check.checked) {
                                    check.checked = false;
                                    newArr = newArr.filter(d => d != parseInt(check.value));
                                } else {
                                    check.checked = true;
                                    newArr.push(parseInt(check.value));
                                }
                            }
                            dedicable?.setSelectedDedicationIds(newArr);
                        }}>invertir</Button>
                        <Button onClick={_ => {
                            dedicable?.setSelectedDedicationIds([]);
                            const checks = document.getElementsByClassName("dedi-check");
                            for (let i = 0; i < checks.length; i++) {
                                const check = checks.item(i) as HTMLInputElement;
                                check.checked = false;
                            }
                        }}>ninguna</Button>
                    </ButtonGroup>
                </Box>
            )}
            <table id={"dedicationTable"}>
                <thead>
                <tr style={{borderLeftColor: "gray", borderLeftWidth: leftBorderWidth, borderLeftStyle: "solid"}}>
                    <th>{dedicable && dedicable.selectedDedicationIds.length ? dedicable.selectedDedicationIds.length : ""}</th>
                    <th align={"left"}>CLIENTE</th>
                    <th align={"left"}>MANAGER</th>
                    <th align={"left"} title={"Grupo de Personas"}>G. PERS</th>
                    {showPerson && (
                        <th align={"left"}>PERSONA</th>
                    )}
                    {showSensibleInfo && (
                        <th align={"left"}>ROL</th>
                    )}
                    {showSensibleInfo && (
                        <th align={"right"}>TARIFA</th>
                    )}
                    <th align={"right"}>HORAS</th>
                    {showSensibleInfo && (
                        <th align={"right"}>AJUST.</th>
                    )}
                    {showSensibleInfo && (
                        <th align={"left"}>VALIDADO</th>
                    )}
                    {showSensibleInfo && (
                        <th align={"left"}>FACT.</th>
                    )}
                    {showSensibleInfo && (
                        <th align={"left"} title={"Grupo de Factura"}>G. FACTURA</th>
                    )}
                    <th align={"left"}>CONCEPTO</th>
                    {showSensibleInfo && (
                        <th align={"right"}>IMP. AJUST. <sup>(sin IVA)</sup></th>
                    )}
                </tr>
                </thead>
                <tbody>
                {filteredDedication && filteredDedication.map((elem, i) => (
                    <tr key={i}
                        title={elem.billLineId ? "Facturado" : undefined}
                        style={{
                            borderLeftColor: getManagerColor(elem.manager),
                            borderLeftWidth: leftBorderWidth,
                            borderLeftStyle: "solid",
                            borderRight: elem.billLineId ? "green 2px solid" : undefined
                        }}>
                        <td>{dedicable && (
                            <input type={"checkbox"} value={elem.id} className={"dedi-check"} onChange={(e) => {
                                const id = elem.id as number;
                                if (e.target.checked) {
                                    if (!dedicable.selectedDedicationIds.find(d => d == id)) {
                                        const newElems = Object.assign([], dedicable.selectedDedicationIds);
                                        newElems.push(id);
                                        dedicable.setSelectedDedicationIds(newElems);
                                    }
                                } else {
                                    const elems = dedicable.selectedDedicationIds.filter(d => d != id);
                                    dedicable.setSelectedDedicationIds(elems);
                                }
                            }
                            }/>
                        )}
                        </td>
                        <td>
                            <span style={{cursor: "pointer"}} onClick={() => {
                                setSelectedDedication(elem);
                                setShowDedicationModal(true);
                            }}>
                                {(elem.customer?.shortName || "").substring(0, 12)}
                            </span>
                        </td>
                        <td>{elem.manager}</td>
                        <td>{elem.peopleGroup}</td>
                        {showPerson && (
                            <td title={elem.role}>{elem.person.surnamesAndName}</td>
                        )}
                        {showSensibleInfo && (
                            <td align={"left"}>{elem.role || ""}</td>
                        )}
                        {showSensibleInfo && (
                            <td align={"right"}><CustomNumberFormat amount={elem.rate}/></td>
                        )}
                        <td align={"right"}><CustomNumberFormat amount={elem.hours} scale={1}/></td>
                        {showSensibleInfo && (
                            <td align={"right"}><CustomNumberFormat amount={elem.adjustedHours} scale={1}/></td>
                        )}
                        {showSensibleInfo && (
                            <td>{elem.validated ? "sí" : ""}</td>
                        )}
                        {showSensibleInfo && (
                            <td>{elem.billLineId ? "sí" : ""}</td>
                        )}
                        {showSensibleInfo && (
                            <td>{elem.billingGroup}</td>
                        )}
                        <td align={"left"}>{elem.subject}</td>
                        {showSensibleInfo && (
                            <td align={"right"}>
                                <CustomNumberFormat amount={useCorrectHours(elem, true) * elem.rate}/>
                            </td>
                        )}
                    </tr>
                ))}
                </tbody>
                <tfoot>
                <tr style={{borderLeftColor: "gray", borderLeftWidth: leftBorderWidth, borderLeftStyle: "solid"}}>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    {showPerson && (
                        <th></th>
                    )}
                    {showSensibleInfo && (
                        <th>{/* rol */}</th>
                    )}
                    {showSensibleInfo && (
                        <th align={"right"}>{summary.averageRate?.toFixed(1)}</th>
                    )}
                    <th align={"right"}>{summary.hours.toFixed(1)}</th>
                    {showSensibleInfo && (
                        <th align={"right"}>{summary.adjustedHours?.toFixed(1)}</th>
                    )}
                    {showSensibleInfo && (
                        <th>{/* validated */}</th>
                    )}
                    {showSensibleInfo && (
                        <th>{/* validated */}</th>
                    )}
                    {showSensibleInfo && (
                        <th>{/* billing group */}</th>
                    )}
                    <th>{/* subject */}</th>
                    {showSensibleInfo && (
                        <th align={"right"}><CustomNumberFormat amount={summary.adjustedAmount}/></th>
                    )}
                </tr>

                </tfoot>
            </table>

            <Modal
                open={showDedicationModal}
                onClose={_ => setShowDedicationModal(false)}
                aria-labelledby="evaluation-modal-title"
                aria-describedby="evaluation-modal-description">
                <Box sx={dedicationModalStyle}>
                    <Typography id="evaluation-modal-description" sx={{mt: 2}}>
                        <DedicationForm dedication={selectedDedication}
                                        year={year} month={parseInt(month)}
                                        summary={selectedPerson? summary: undefined}
                                        person={selectedPerson}
                                        onChange={d => {
                                            setSelectedDedication(d);
                                            loadDedication();
                                            setShowDedicationModal(false);
                                        }}/>
                    </Typography>
                </Box>
            </Modal>


        </>


    );
}

export default DedicationTable;