import React from "react";

import {useApiManager} from "../../../../components/utils/api";
import {EvaluationResult, EvaluationResultRestControllerApiFactory} from "arteco-api-client-ts";

import {FormContent} from "../../../../components/form/form_inputs/form_buttons";
import {FormInput} from "../../../../components/form/form_inputs/form_input";
import {Grid} from "@mui/material";
import {useForm, UseFormProps} from "../../../../components/form/form_model";


export const EvaluationResultEditPage = () => {
    const apiManager = useApiManager();
    const ctrl = apiManager.factory(EvaluationResultRestControllerApiFactory);

    const form = useForm({
        fetcher: (id) => ctrl.getEvaluationResult(id),
        updater: (bean) => ctrl.saveEvaluationResult(bean),
        remover: (id) => ctrl.deleteOrDismissEvaluationResult(id),
        factory: () => {
            return {
                company: {
                    id: apiManager.companyId,
                },
            } as EvaluationResult;
        },
    } as UseFormProps<EvaluationResult>);

    return (
        <>
            <FormContent form={form} title={"Respuesta de evaluación"}>
                <Grid container spacing={3}>
                    <Grid item sm>
                        <FormInput type={"number"} field={"level"} label={"Nivel"} />
                    </Grid>
                    <Grid item sm>
                        <FormInput type={"text"} field={"label"} label={"Etiqueta"} />
                    </Grid>
                </Grid>
                <FormInput type={"text"} field={"description"} label={"Descripción"} rows={3} />
            </FormContent>
        </>
    );
};