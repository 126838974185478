import React, {useEffect, useState} from "react";
import {
	Company,
	Person,
	PersonRestControllerApiFactory,
	SupportGroup,
	SupportLink,
	SupportLinkList,
	SupportLinkRestControllerApiFactory
} from "arteco-api-client-ts";
import {FormContent} from "../../../../components/form/form_inputs/form_buttons";
import {useForm} from "../../../../components/form/form_model";
import {useApiManager} from "../../../../components/utils/api";
import {FormInput} from "../../../../components/form/form_inputs/form_input";
import {Grid, List, ListItemButton, TextField, Typography} from "@mui/material";
import {Box} from "@mui/system";


export const SupportLinkEditPage = () => {

	const apiManager = useApiManager();
	const companyId = apiManager.companyId;
	const ctrl = apiManager.factory(SupportLinkRestControllerApiFactory);
	const personCtrl = apiManager.factory(PersonRestControllerApiFactory);

	const [people, setPeople] = useState<Person[]>([]);
	const [peopleFiltered, setPeopleFiltered] = useState<Person[]>([]);
	const [peopleSelected, setPeopleSelected] = useState<Person[]>([]);
	const [personFilter, setPersonFilter] = useState<string>('');
	const [showGroups, setShowGroups] = useState<boolean>(false);

	const form = useForm<SupportLink>({
		fetcher: (id) => ctrl.getSupportLink(id),
		updater: (bean) => ctrl.saveSupportLink(bean),
		remover: (id) => ctrl.deleteOrDismissSupportLink(id),
		factory: () => {
			return {
				name: '',
				company: {id: companyId} as Company
			} as SupportLink;
		},
		postLoad: t => {
			const selected = [] as Person[];
			(t.group || []).forEach(pg => {
				selected.push(pg.person);
			});
			setPeopleSelected(selected);
			setShowGroups(t.id != undefined);
		},
		preUpdate: bean => {
			const groups = [] as SupportGroup[];
			peopleSelected.forEach(p => {
				groups.push({
					person: p,
					supportLink: {id: bean.id} as SupportLinkList
				} as SupportGroup);
			});
			bean.group = groups;
			return true;
		},
		postUpdate: bean => {
			setShowGroups(bean.id != undefined);
		}
	});

	useEffect(() => {
		personCtrl.listPerson(companyId).then(resp => setPeople(resp.data))
	}, []);

	useEffect(() => {
		const p = people.filter(p => (p.surnamesAndName || '').toUpperCase().indexOf(personFilter.toUpperCase()) >= 0);
		setPeopleFiltered(p);
	}, [personFilter, people]);


	return (
		<>
			<FormContent form={form} title={"Ficha de enlace a evaluación"}>

				<Box sx={{my: 2}}>
					<a href={"/evaluation?rid=" + form.getId()}
					   target={"_blank"}
					   rel="noreferrer">
						Link al formulario
					</a>
				</Box>

				<Grid container spacing={3}>
					<Grid item>
						<FormInput
							field={"name"}
							type={"text"}
							label={"Nombre del enlace"}/>
					</Grid>
					<Grid item>
						<FormInput
							field={"evaluationDate"}
							type={"datetime"}
							label={"Fecha de la evaluación"}
						/>
						<small>Se cumplimentará automáticamente desde el enlace</small>
					</Grid>
				</Grid>


				{showGroups && (
					<Box sx={{mt: 5}}>
						<Typography sx={{fontWeight: "bold", fontSize: "1.2em"}}>Personas solicitadas</Typography>
						<TextField variant="filled"
								   fullWidth
								   label={"Filtro"}
								   sx={{pb: 2}}
								   value={personFilter}
								   onChange={e => setPersonFilter(e.target.value)}
						/>

						<Grid container spacing={2}>

							<Grid item xs={6}>
								<Typography sx={{fontWeight: "bold"}}>Disponibles</Typography>
								<List sx={{maxHeight: "20em", overflowY: "auto", border: "1px solid gray;"}}>
									{peopleFiltered && peopleFiltered.map((person, i) => (
										<ListItemButton
											key={"person-" + i}
											onClick={() => {
												const f = peopleSelected.find(p => p.id == person.id);
												if (!f) {
													const newArr = [...peopleSelected];
													newArr.push(person);
													const ordered = newArr.sort((a, b) => (a.surnamesAndName as string).localeCompare(b.surnamesAndName as string))
													setPeopleSelected(ordered);
												}
											}}>
											{person.surnamesAndName}
										</ListItemButton>
									))}
								</List>

							</Grid>
							<Grid item xs={6}>
								<Typography sx={{fontWeight: "bold"}}>Seleccionados</Typography>
								<List>
									{peopleSelected && peopleSelected.map((person, i) => (
										<ListItemButton
											key={"selected-" + i}
											onClick={() => {
												const newArr = [...peopleSelected].filter(p => p.id != person.id);
												setPeopleSelected(newArr);
											}}>
											{person.surnamesAndName}
										</ListItemButton>
									))}
								</List>
							</Grid>
						</Grid>

					</Box>
				)}


			</FormContent>
		</>
	);
};
