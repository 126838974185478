import {Grid} from "@mui/material";
import React, {useEffect, useState} from "react";
import {
    PersonEvent, PersonEventRestControllerApiFactory,
} from "arteco-api-client-ts";
import AppList, {AppListConfig} from "../../../../../components/layout/list";
import {useApiManager} from "../../../../../components/utils/api";
import {dateFromString, dateToString} from "../../../../../components/utils/dates";
import {useParams} from "react-router-dom";
import {usePerson} from "../index";
import {parsePathId} from "../../../../../components/form/form_utils";

export const PersonEventListPage = () => {
    const apiManager = useApiManager();
    const params = useParams();
    const personId = parsePathId(params, "personId", true);
    const companyId = apiManager.companyId;

    const eventApi = apiManager.factory(PersonEventRestControllerApiFactory);

    const [person, setPerson] = usePerson(personId);

    const listConfig: AppListConfig<PersonEvent> = {
        title:
            "Eventos de la persona " +
            (person ? person.surnamesAndName : ""),
        onClick: "/app/admin/employee/person/" + personId + "/event",
        decorator: (bean: PersonEvent) => (
            <Grid container>
                <Grid item sm={2}>
                    {dateToString(dateFromString(bean.issueDate))}
                </Grid>
                <Grid item sm>
                    {bean.description ? bean.description.substr(0, 50) : ""}
                </Grid>
            </Grid>
        ),
        supplier: async (currentPage, size, term, showDismissed) => {
            if (!personId) throw new Error("No personId");
            return eventApi.pagePersonEvent(personId, companyId, currentPage, size, undefined, undefined, term, showDismissed);
        },
    };

    return (
        <>
            <AppList config={listConfig}/>
        </>
    );
};