import React, {useEffect, useState} from "react";
import {
    Evaluation,
    EvaluationPoint,
    EvaluationRestControllerApiFactory,
    EvaluationResult
} from "arteco-api-client-ts";
import {FormContent} from "../../../../components/form/form_inputs/form_buttons";
import {FormInput} from "../../../../components/form/form_inputs/form_input";
import {types, useType} from "../../../../components/form/types";
import {Box, Grid, Typography} from "@mui/material";
import {useForm} from "../../../../components/form/form_model";
import {useApiManager} from "../../../../components/utils/api";
import {useParams} from "react-router-dom";
import FormAutocomplete from "../../../../components/form/form_inputs/form_autocomplete";
import {useAuthManager} from "../../../../components/utils/auth";
import {AxiosResponse} from "axios";
import FormRating from "../../../../components/form/form_inputs/form_rating";
import FormError from "../../../../components/form/form_inputs/form_error";
import {parsePathId} from "../../../../components/form/form_utils";
import {PersonSummaryAlert} from "../person/summary";


export const EvaluationEditPage = () => {
    const authManager = useAuthManager();
    const params = useParams();
    const apiManager = useApiManager();

    const ctrl = apiManager.factory(EvaluationRestControllerApiFactory);

    const personId = parsePathId(params, "personId");
    const [selectedPersonId, setSelectedPersonId] = useState<number | undefined>(personId);


    const form = useForm<Evaluation>({
        fetcher: (id) => {
            const prom = ctrl.getEvaluation(id);
            prom.then((resp: AxiosResponse<Evaluation>) => {
                setSelectedPersonId(resp.data.person?.id);
                return resp;
            });
            return prom;
        },
        updater: (bean) => ctrl.saveEvaluation(bean),
        remover: (id) => ctrl.deleteOrDismissEvaluation(id),
        factory: () => {
            return {
                modifier: {id: authManager.getUserId()},
                person: {id: personId},
                evaluationDate: new Date().toISOString(),
                points: {}
            } as Evaluation;
        },
    });

    return (
        <>
            <FormContent form={form} title={"Ficha de la evaluación"}>

                <EvaluationFormShared personId={personId} asEmployee={false}
                                      setSelectedPersonId={id => setSelectedPersonId(id)}/>

            </FormContent>
        </>
    );
};

export interface EvaluationFormSharedProps {
    personId: number | undefined;
    hideEvaluationDate?: boolean;
    showMandatoryInfo?: boolean;
    setSelectedPersonId?: (id: number | undefined) => void,
    asEmployee: boolean;
}

export const EvaluationFormShared = ({
                                         hideEvaluationDate = false,
                                         showMandatoryInfo = false,
                                         personId,
                                         setSelectedPersonId,
                                         asEmployee
                                     }: EvaluationFormSharedProps) => {

    const [evaluationPoints, setEvaluationPoints] = useState<EvaluationPoint[]>();
    const [evaluationResults, setEvaluationResults] = useState<EvaluationResult[]>();
    const evaluationPointType = useType(asEmployee ? types.evaluationPointAsEmployee : types.evaluationPoint);
    const evaluationResultType = useType(asEmployee ? types.evaluationResultAsEmployee : types.evaluationResult);

    useEffect(() => {
        evaluationPointType.supplier().then((points) => setEvaluationPoints(points));
        evaluationResultType.supplier().then((results) => setEvaluationResults(results));
    }, []);

    return (
        <>
            <Grid container spacing={3}>
                <Grid item sm>
                    {!personId && (
                        <FormAutocomplete field={"person"} label={"Persona"} type={types.people}
                                          onChange={(type, value) => setSelectedPersonId && setSelectedPersonId(value?.id)}/>
                    )}
                </Grid>
                <Grid item sm={3}>
                    {!hideEvaluationDate && (
                        <FormInput field={"evaluationDate"} type={"date"} label={"Fecha"}/>
                    )}
                </Grid>
            </Grid>


            <Grid container spacing={3}>
                <Grid item>
                    <Typography variant={"h5"} sx={{marginBottom: "1em"}}>
                        Preguntas puntuadas {showMandatoryInfo ? "(obligatorio)" : ""}
                    </Typography>
                </Grid>
                <Grid item>
                    <FormError field={"points"}/>
                </Grid>
            </Grid>

            {evaluationPoints && evaluationResults &&
                evaluationPoints.map((point, pointIdx) => (

                    <FormRating key={"point-" + pointIdx}
                                type={types.evaluationResult}
                                typeValues={evaluationResults}
                                field={"points." + point.id}
                                label={point.label}/>

                ))}

            <Typography variant={"h5"} sx={{marginBottom: "1em"}}>
                Comentarios {showMandatoryInfo ? "(opcional)" : ""}
                {!asEmployee ?
                    <>
                        <br/>
                        <small style={{fontSize: "0.5em"}}>El empleado no las vee una vez rellenadas o
                            modificadas</small>
                    </>
                    : ""}
            </Typography>
            <FormInput type={"text"}
                       field={"personalComments"}
                       label={"Comentarios personales"}
                       placeholder={""}
                       rows={2}/>
            <FormInput type={"text"}
                       field={"teamComments"}
                       label={"Comentarios sobre el equipo"}
                       placeholder={""}
                       rows={2}/>
            <FormInput type={"text"}
                       field={"projectComments"}
                       label={"Comentarios sobre el proyecto"}
                       placeholder={""}
                       rows={2}/>
            <FormInput type={"text"}
                       field={"companyComments"}
                       label={"Comentarios sobre la empresa"}
                       placeholder={""}
                       rows={2}/>

        </>
    );
}