import * as React from "react";
import {useEffect, useState} from "react";
import {Box, Container} from "@mui/material";
import Navbar from "./navbar";
import AdminFooter from "./footer";
import {useAuthManager} from "../utils/auth";

import {LOGIN_URL} from "../utils/urls";
import {useNavigate} from "react-router-dom";


export interface AppLayoutProps {
    children?: React.ReactNode;
    permitAnon?: boolean;
    maxWidth?: 'xs'
        | 'sm'
        | 'md'
        | 'lg'
        | 'xl'
}

export const AppLayout = (props: AppLayoutProps) => {
    const authManager = useAuthManager();
    const router = useNavigate();
    const [logging, setLogging] = useState<boolean>(!authManager.isLogged());

    useEffect(() => {
        let loginPromise = new Promise((resolve, reject) => {
            const permitted = "Permitted";
            const notAllowed = "Not allowed";
            if (props.permitAnon == true) {
                resolve(permitted);
            } else if (authManager.isLogged()) {
                resolve(permitted);
            } else {
                const auth = authManager.tryRecoverState();
                if (auth) resolve(permitted);
                else reject(notAllowed);
            }
        });
        loginPromise.then(
            () => {
                setLogging(false)
            },
            () => {
                setLogging(false);
                router(LOGIN_URL);
            })

    }, []);


    return (
        <Box sx={{backgroundColor: "rgb(231, 235, 240)", height: "100%"}}>
            <Navbar/>
            {logging && (
                <>
                    <Container maxWidth={"md"}>
                        <Box my={5}>
                            <span>
                                Loading...
                            </span>
                        </Box>
                    </Container>

                </>
            )}
            {!logging && (
                <Box my={3}>
                    {!props.maxWidth && (
                        <>
                            {props.children}
                        </>
                    )}
                    {props.maxWidth && (
                        <Container maxWidth={props.maxWidth}>
                            {props.children}
                        </Container>
                    )}
                </Box>
            )}
            <AdminFooter/>
        </Box>
    );
};
