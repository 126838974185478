import {AdminEmployeeLayout} from "./pages/admin/employee/layout";
import {Route} from "react-router-dom";
import {AdminEmployeePage} from "./pages/admin/employee";
import {DocumentListPage} from "./pages/admin/employee/document";
import {DocumentEditPage} from "./pages/admin/employee/document/form";
import {PersonDocumentListPage} from "./pages/admin/employee/person/document";
import {PayrollListPage} from "./pages/admin/employee/payroll";
import {PayrollEditPage} from "./pages/admin/employee/payroll/form";
import {PayrollPackListPage} from "./pages/admin/employee/payrollpack";
import {PayrollPackEditPage} from "./pages/admin/employee/payrollpack/form";
import {HolidayListPage} from "./pages/admin/employee/holiday";
import {HolidayEditPage} from "./pages/admin/employee/holiday/form";
import {VacationsListPage} from "./pages/admin/employee/vacations";
import {VacationEditPage} from "./pages/admin/employee/vacations/form";
import {PersonListPage} from "./pages/admin/employee/person";
import {PersonEditPage} from "./pages/admin/employee/person/form";
import {SalaryListPage} from "./pages/admin/employee/person/salary";
import {SalaryEditPage} from "./pages/admin/employee/person/salary/form";
import {PersonDocumentEditPage} from "./pages/admin/employee/person/document/form";
import {PersonEventListPage} from "./pages/admin/employee/person/event";
import {PersonEventEditPage} from "./pages/admin/employee/person/event/form";
import {EvaluationListPage} from "./pages/admin/employee/evaluation";
import {EvaluationEditPage} from "./pages/admin/employee/evaluation/form";
import {InterviewListPage} from "./pages/admin/employee/interview";
import {InterviewEditPage} from "./pages/admin/employee/interview/form";
import {WikiPageListPage} from "./pages/admin/employee/wiki";
import {SECTION_HR, WikiPageEditPage} from "./pages/admin/employee/wiki/form";
import {EvaluationPointListPage} from "./pages/admin/employee/evaluationpoint";
import {EvaluationPointEditPage} from "./pages/admin/employee/evaluationpoint/form";
import {EvaluationResultListPage} from "./pages/admin/employee/evaluationresult";
import {EvaluationResultEditPage} from "./pages/admin/employee/evaluationresult/form";
import {PersonDocumentTypeListPage} from "./pages/admin/employee/persondoctype";
import {PersonDocumentTypeEditPage} from "./pages/admin/employee/persondoctype/form";
import {PersonReminderListPage} from "./pages/admin/employee/reminder";
import {PersonReminderEditPage} from "./pages/admin/employee/reminder/form";
import {WorkCenterListPage} from "./pages/admin/employee/workcenter";
import {WorkCenterEditPage} from "./pages/admin/employee/workcenter/form";
import {WorkBookingListPage} from "./pages/admin/employee/workbooking";
import {WorkBookingEditPage} from "./pages/admin/employee/workbooking/form";
import * as React from "react";
import SideBarItem from "./sidebar_item";
import {AllInbox, AssignmentInd, BeachAccess, Description, Inbox, NoteAlt, PersonSearch, WorkOff} from "@mui/icons-material";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import BookmarksIcon from "@mui/icons-material/Bookmarks";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import StyleIcon from "@mui/icons-material/Style";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import {SupportPointListPage} from "./pages/admin/employee/supportpoint";
import {SupportPointEditPage} from "./pages/admin/employee/supportpoint/form";
import {SupportResultListPage} from "./pages/admin/employee/supportresult";
import {SupportResultEditPage} from "./pages/admin/employee/supportresult/form";
import {SupportEditPage} from "./pages/admin/employee/support/form";
import {SupportListPage} from "./pages/admin/employee/support";
import {SupportLinkEditPage} from "./pages/admin/employee/supportlink/form";
import {SupportLinkListPage} from "./pages/admin/employee/supportlink";
import LinkIcon from "@mui/icons-material/Link";


const AdminEmployeeRoutes = () => {
	return (
		<Route path="/app/admin/employee" element={<AdminEmployeeLayout/>}>
			<Route index element={<AdminEmployeePage/>}/>
			<Route path="/app/admin/employee/document" element={<DocumentListPage/>}/>
			<Route path="/app/admin/employee/document/:id"
				   element={<DocumentEditPage/>}/>
			<Route path="/app/admin/employee/document/new"
				   element={<DocumentEditPage/>}/>
			<Route path="/app/admin/employee/persondoc"
				   element={<PersonDocumentListPage/>}/>
			<Route path="/app/admin/employee/interview"
				   element={<InterviewListPage/>}/>
			<Route path="/app/admin/employee/interview/:id"
				   element={<InterviewEditPage/>}/>
			<Route path="/app/admin/employee/interview/new"
				   element={<InterviewEditPage/>}/>
			<Route path="/app/admin/employee/payroll" element={<PayrollListPage/>}/>
			<Route path="/app/admin/employee/payroll/:id" element={<PayrollEditPage/>}/>
			<Route path="/app/admin/employee/payroll/new" element={<PayrollEditPage/>}/>
			<Route path="/app/admin/employee/payrollpack"
				   element={<PayrollPackListPage/>}/>
			<Route path="/app/admin/employee/payrollpack/:id"
				   element={<PayrollPackEditPage/>}/>
			<Route path="/app/admin/employee/payrollpack/new"
				   element={<PayrollPackEditPage/>}/>
			<Route path="/app/admin/employee/holiday" element={<HolidayListPage/>}/>
			<Route path="/app/admin/employee/holiday/:id" element={<HolidayEditPage/>}/>
			<Route path="/app/admin/employee/holiday/new" element={<HolidayEditPage/>}/>
			<Route path="/app/admin/employee/vacations" element={<VacationsListPage/>}/>
			<Route path="/app/admin/employee/vacations/:id"
				   element={<VacationEditPage/>}/>
			<Route path="/app/admin/employee/vacations/new"
				   element={<VacationEditPage/>}/>
			<Route path="/app/admin/employee/person" element={<PersonListPage/>}/>
			<Route path="/app/admin/employee/person/new" element={<PersonEditPage/>}/>
			<Route path="/app/admin/employee/person/:personId"
				   element={<PersonEditPage/>}/>
			<Route path="/app/admin/employee/person/:personId/salary"
				   element={<SalaryListPage/>}/>
			<Route path="/app/admin/employee/person/:personId/salary/:id"
				   element={<SalaryEditPage/>}/>
			<Route path="/app/admin/employee/person/:personId/salary/new"
				   element={<SalaryEditPage/>}/>
			<Route path="/app/admin/employee/person/:personId/document"
				   element={<PersonDocumentListPage/>}/>
			<Route path="/app/admin/employee/person/:personId/document/:id"
				   element={<PersonDocumentEditPage/>}/>
			<Route path="/app/admin/employee/person/:personId/document/new"
				   element={<PersonDocumentEditPage/>}/>
			<Route path="/app/admin/employee/person/:personId/event"
				   element={<PersonEventListPage/>}/>
			<Route path="/app/admin/employee/person/:personId/event/:id"
				   element={<PersonEventEditPage/>}/>
			<Route path="/app/admin/employee/person/:personId/event/new"
				   element={<PersonEventEditPage/>}/>
			<Route path="/app/admin/employee/wiki"
				   element={<WikiPageListPage section={SECTION_HR}/>}/>
			<Route path="/app/admin/employee/wiki/:id"
				   element={<WikiPageEditPage section={SECTION_HR}/>}/>
			<Route path="/app/admin/employee/wiki/new"
				   element={<WikiPageEditPage section={SECTION_HR}/>}/>
			<Route path="/app/admin/employee/person/:personId/evaluation"
				   element={<EvaluationListPage/>}/>
			<Route path="/app/admin/employee/person/:personId/evaluation/:id"
				   element={<EvaluationEditPage/>}/>
			<Route path="/app/admin/employee/person/:personId/evaluation/new"
				   element={<EvaluationEditPage/>}/>
			<Route path="/app/admin/employee/evaluation"
				   element={<EvaluationListPage/>}/>
			<Route path="/app/admin/employee/evaluation/:id"
				   element={<EvaluationEditPage/>}/>
			<Route path="/app/admin/employee/evaluation/new"
				   element={<EvaluationEditPage/>}/>
			<Route path="/app/admin/employee/evaluationpoint"
				   element={<EvaluationPointListPage/>}/>
			<Route path="/app/admin/employee/evaluationpoint/:id"
				   element={<EvaluationPointEditPage/>}/>
			<Route path="/app/admin/employee/evaluationpoint/new"
				   element={<EvaluationPointEditPage/>}/>
			<Route path="/app/admin/employee/evaluationresult"
				   element={<EvaluationResultListPage/>}/>
			<Route path="/app/admin/employee/evaluationresult/:id"
				   element={<EvaluationResultEditPage/>}/>
			<Route path="/app/admin/employee/evaluationresult/new"
				   element={<EvaluationResultEditPage/>}/>
			<Route path="/app/admin/employee/person/:personId/support"
				   element={<SupportListPage/>}/>
			<Route path="/app/admin/employee/person/:personId/support/:id"
				   element={<SupportEditPage/>}/>
			<Route path="/app/admin/employee/person/:personId/support/new"
				   element={<SupportEditPage/>}/>
			<Route path="/app/admin/employee/supportlink"
				   element={<SupportLinkListPage/>}/>
			<Route path="/app/admin/employee/supportlink/:id"
				   element={<SupportLinkEditPage/>}/>
			<Route path="/app/admin/employee/supportlink/new"
				   element={<SupportLinkEditPage/>}/>
			<Route path="/app/admin/employee/support"
				   element={<SupportListPage/>}/>
			<Route path="/app/admin/employee/support/:id"
				   element={<SupportEditPage/>}/>
			<Route path="/app/admin/employee/support/new"
				   element={<SupportEditPage/>}/>
			<Route path="/app/admin/employee/supportpoint"
				   element={<SupportPointListPage/>}/>
			<Route path="/app/admin/employee/supportpoint/:id"
				   element={<SupportPointEditPage/>}/>
			<Route path="/app/admin/employee/supportpoint/new"
				   element={<SupportPointEditPage/>}/>
			<Route path="/app/admin/employee/supportresult"
				   element={<SupportResultListPage/>}/>
			<Route path="/app/admin/employee/supportresult/:id"
				   element={<SupportResultEditPage/>}/>
			<Route path="/app/admin/employee/supportresult/new"
				   element={<SupportResultEditPage/>}/>
			<Route path="/app/admin/employee/persondoctype"
				   element={<PersonDocumentTypeListPage/>}/>
			<Route path="/app/admin/employee/persondoctype/:id"
				   element={<PersonDocumentTypeEditPage/>}/>
			<Route path="/app/admin/employee/persondoctype/new"
				   element={<PersonDocumentTypeEditPage/>}/>
			<Route path="/app/admin/employee/reminder"
				   element={<PersonReminderListPage/>}/>
			<Route path="/app/admin/employee/reminder/:id"
				   element={<PersonReminderEditPage/>}/>
			<Route path="/app/admin/employee/reminder/new"
				   element={<PersonReminderEditPage/>}/>
			<Route path="/app/admin/employee/workcenter"
				   element={<WorkCenterListPage/>}/>
			<Route path="/app/admin/employee/workcenter/:id"
				   element={<WorkCenterEditPage/>}/>
			<Route path="/app/admin/employee/workcenter/new"
				   element={<WorkCenterEditPage/>}/>
			<Route path="/app/admin/employee/workbooking"
				   element={<WorkBookingListPage/>}/>
			<Route path="/app/admin/employee/workbooking/:id"
				   element={<WorkBookingEditPage/>}/>
			<Route path="/app/admin/employee/workbooking/new"
				   element={<WorkBookingEditPage/>}/>
		</Route>
	);
}

export default AdminEmployeeRoutes;

export const AdminEmployeeMenu = () => {
	return (
		<>
			<SideBarItem
				href={"/app/admin/employee/person"}
				label={"Personal"}
				icon={<AssignmentInd/>}
			/>
			<SideBarItem
				href={"/app/admin/employee/vacations"}
				label={"Ausencias"}
				icon={<BeachAccess/>}
			/>
			<SideBarItem
				href={"/app/admin/employee/persondoc"}
				label={"Documentos del personal"}
				icon={<Description/>}
			/>
			<SideBarItem
				href={"/app/admin/employee/payrollpack"}
				label={"Pack de Nóminas"}
				icon={<AllInbox/>}
			/>
			<SideBarItem
				href={"/app/admin/employee/payroll"}
				label={"Nóminas"}
				icon={<Inbox/>}
			/>
			<SideBarItem
				href={"/app/admin/employee/interview"}
				label={"Entrevistas"}
				icon={<PersonSearch/>}
			/>
			<SideBarItem
				href={"/app/admin/employee/evaluation"}
				label={"Evaluaciones"}
				icon={<ContentPasteIcon/>}
			/>
			<SideBarItem
				href={"/app/admin/employee/evaluationpoint"}
				label={"Preguntas de Evaluación"}
				icon={<HelpCenterIcon/>}
			/>
			<SideBarItem
				href={"/app/admin/employee/evaluationresult"}
				label={"Respuestas de Evaluación"}
				icon={<FactCheckIcon/>}
			/>

			<SideBarItem
				href={"/app/admin/employee/supportlink"}
				label={"Link de valoración de clientes"}
				icon={<LinkIcon/>}
			/>
			<SideBarItem
				href={"/app/admin/employee/support"}
				label={"Valoración de clientes"}
				icon={<ContentPasteIcon/>}
			/>
			<SideBarItem
				href={"/app/admin/employee/supportpoint"}
				label={"Preguntas de Valoración"}
				icon={<HelpCenterIcon/>}
			/>
			<SideBarItem
				href={"/app/admin/employee/supportresult"}
				label={"Respuestas de Valoración"}
				icon={<FactCheckIcon/>}
			/>


			<SideBarItem
				href={"/app/admin/employee/document"}
				label={"Documentos de Empresa"}
				icon={<Description/>}
			/>
			<SideBarItem
				href={"/app/admin/employee/persondoctype"}
				label={"Tipos de Documento"}
				icon={<BookmarksIcon/>}
			/>
			<SideBarItem
				href={"/app/admin/employee/reminder"}
				label={"Recordatorios"}
				icon={<NotificationsActiveIcon/>}
			/>
			<SideBarItem
				href={"/app/admin/employee/workbooking"}
				label={"Reservas de Plazas"}
				icon={<StyleIcon/>}
			/>
			<SideBarItem
				href={"/app/admin/employee/workcenter"}
				label={"Centros de Trabajo"}
				icon={<CorporateFareIcon/>}
			/>
			<SideBarItem
				href={"/app/admin/employee/holiday"}
				label={"Festivos"}
				icon={<WorkOff/>}
			/>
			<SideBarItem
				href={"/app/admin/employee/wiki"}
				label={"Wiki"}
				icon={<NoteAlt/>}
			/>
		</>
	)
}