import React, {FormEvent, useEffect, useState} from "react";
import {useApiManager} from "../components/utils/api";
import {SignupResult, UserRestControllerApiFactory} from "arteco-api-client-ts";
import {Alert, Button, TextField} from "@mui/material";
import {isEmpty} from "../components/utils/functions";
import {LoginLayout} from "../components/layout/login_layout";

export default function Signup() {
    const apiManager = useApiManager();
    const ctrl = apiManager.factory(UserRestControllerApiFactory);

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");

    const [fieldsNotEmpty, setFieldsNotEmpty] = useState({
        username: false,
        password: false,
        email: false,
    });

    const [requestError, setRequestError] = useState("");
    const [requestOk, setRequestOk] = useState(false);

    const [invalidData, setInvalidData] = useState(true);

    const [sending, setSending] = useState(false);
    const [validUsername, setValidUsername] = useState(true);
    const [validEmail, setValidEmail] = useState(true);

    const sendForm = (event: FormEvent) => {
        event.preventDefault();
        setSending(true);
        setRequestError("");
        ctrl.signup(email, username, password)
            .then((resp) => {
                let result: SignupResult = resp.data;
                if (!result.signupToken) {
                    setValidEmail(result.validEmail || false);
                    setValidUsername(result.validUsername || false);
                } else {
                    setRequestOk(true);
                }
            })
            .catch((err: any) => {
                setRequestError("Ha habido un error al procesar la petición");
            }).finally(() => {
            setSending(false);
        });
    };

    useEffect(() => {
        setInvalidData(!(fieldsNotEmpty.username && fieldsNotEmpty.password));
    }, [fieldsNotEmpty]);

    return (
        <LoginLayout title={" Date de alta"}>
            {!requestOk && (
                <>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Dirección de email"
                        name="email"
                        type="email"
                        autoComplete={"off"}
                        autoFocus
                        onChange={(event) => {
                            if (isEmpty(event.target.value)) {
                                setFieldsNotEmpty({
                                    ...fieldsNotEmpty,
                                    email: false,
                                });
                            } else {
                                setEmail(event.target.value);
                                setFieldsNotEmpty({
                                    ...fieldsNotEmpty,
                                    email: true,
                                });
                            }
                        }}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label="Nombre de usuario"
                        name="username"
                        autoComplete={"off"}
                        autoFocus
                        onChange={(event) => {
                            if (isEmpty(event.target.value)) {
                                setFieldsNotEmpty({
                                    ...fieldsNotEmpty,
                                    username: false,
                                });
                            } else {
                                setUsername(event.target.value);
                                setFieldsNotEmpty({
                                    ...fieldsNotEmpty,
                                    username: true,
                                });
                            }
                        }}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Contraseña"
                        type="password"
                        id="password"
                        autoComplete={"off"}
                        onChange={(event) => {
                            if (isEmpty(event.target.value)) {
                                setFieldsNotEmpty({
                                    ...fieldsNotEmpty,
                                    password: false,
                                });
                            } else {
                                setPassword(event.target.value);
                                setFieldsNotEmpty({
                                    ...fieldsNotEmpty,
                                    password: true,
                                });
                            }
                        }}
                    />

                    <Button
                        fullWidth
                        variant="contained"
                        sx={{mt: 3, mb: 2}}
                        onClick={sendForm} disabled={invalidData || sending}>
                        Entrar
                    </Button>

                    {requestError !== "" && (
                        <Alert severity="error" sx={{mb: 2}}>{requestError}</Alert>
                    )}
                    {!validEmail && (
                        <Alert severity="error" sx={{mb: 2}}>El email no está disponible para su registro</Alert>
                    )}
                    {!validUsername && (
                        <Alert severity="error" sx={{mb: 2}}>El nombre de usuario no está disponible para su
                            registro</Alert>
                    )}
                </>
            )}

            {requestOk && (
                <Alert severity="success" sx={{my: 4}}>Se le ha enviado un email para validar la cuenta</Alert>
            )}

            <Alert color={"info"}  sx={{my:3, width:"100%", lineBreak:"strict"}}>
                Si eres personal de Arteco, basta que realices la recuperación de tu contraseña.
                El nombre de usuario viene determinado por tu email.
            </Alert>

        </LoginLayout>
    );
}
