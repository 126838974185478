import {Alert, Button, Grid, List, ListItem, ListItemText} from "@mui/material";
import AdminInner from "../../../components/layout/admin_inner";
import {useApiManager} from "../../../components/utils/api";
import {EmpManagerRestControllerApiFactory, Vacation} from "arteco-api-client-ts";
import React, {useEffect, useState} from "react";
import {dateFromToString} from "../../../components/utils/dates";


export const EmployeeManagerPage = () => {
    const apiManager = useApiManager();
    const managerCtrl = apiManager.factory(EmpManagerRestControllerApiFactory);
    const [vacations, setVacations] = useState<Vacation[]>([]);

    const loadPendingVacations = () => {
        apiManager.execute(managerCtrl.getManagedPendingVacations())
            .then(vacations => setVacations(vacations));
    }

    useEffect(() => {
        loadPendingVacations();
    }, []);

    return (
        <>
            <Grid container spacing={3}>
                <Grid item sm>
                    <AdminInner title={"Vacaciones por validar"}>
                        <Alert color={"info"}>
                            Aquí podrás validar las solicitudes regulares de vacaciones. Aquellas que tengan un tipo
                            especial deben ser aprobadas por RRHH.
                            La aprobación notifica al solicitante mediante email de confirmación.
                        </Alert>
                        <List sx={{fontSize: "0.7em"}}>
                            {vacations && vacations.map((vacation, idx) => (
                                <ListItem divider key={idx}>
                                    <ListItemText>
                                        <Grid container spacing={3}>
                                            <Grid item sm={3}>
                                                {dateFromToString(vacation.periodFrom)} -{" "}
                                                {dateFromToString(vacation.periodTo)}
                                            </Grid>
                                            <Grid item sm={3}>
                                                {vacation.person.surnamesAndName}
                                            </Grid>
                                            <Grid item sm={5}>
                                                <span style={{whiteSpace: 'nowrap'}}>{vacation.title}</span>
                                            </Grid>
                                            <Grid item sm={1}>
                                                <Button size={"small"} variant={"contained"} onClick={() => {
                                                    if (confirm("¿Seguro que desea validar esta solicitud?")) {
                                                        apiManager.execute(managerCtrl.approveManagedVacations([vacation.id as number]))
                                                            .then(() => loadPendingVacations());
                                                    }
                                                }}>Validar</Button>
                                            </Grid>
                                        </Grid>
                                    </ListItemText>
                                </ListItem>
                            ))}
                            {(!vacations || vacations.length == 0) && (
                                <Alert color={"success"}>
                                    No hay vacaciones ordinarias pendientes de validar
                                </Alert>
                            )}
                        </List>
                    </AdminInner>
                </Grid>
            </Grid>
        </>
    );
}