import {Bill, BillRestControllerApiFactory, BillSummary} from "arteco-api-client-ts";
import AppList, {AppListConfig} from "../../../../components/layout/list";
import {useApiManager} from "../../../../components/utils/api";
import {useEffect, useState} from "react";
import {Box, Grid} from "@mui/material";
import {CustomNumberFormat} from "../../../../components/utils/custom_number_format";
import {useNavigate} from "react-router-dom";


interface BillListFilter {
    advanced: boolean;
    pending: boolean;
}

export const BillListPage = () => {
    const navigate = useNavigate();
    const apiManager = useApiManager();
    const ctrl = apiManager.factory(BillRestControllerApiFactory);
    const companyId = apiManager.companyId;

    const [listFilter, setListFilter] = useState<BillListFilter>({
        advanced: false,
        pending: false,
    });
    const [trigger, setTrigger] = useState<number>(0);
    const [summary, setSummary] = useState<BillSummary>({});


    const billDateColor = (bean: Bill): string => {
        if (bean.doubtful) {
            return "purple";
        } else if (bean.paymentDate) {
            return "success.main";
        } else if (bean.advanceDate) {
            return "secondary.main";
        } else if (bean.sentDate) {
            return "warning.main";
        } else {
            return "error.main";
        }
    };

    useEffect(()=>{
        apiManager
            .execute(ctrl.summaryBill(companyId))
            .then(summary => setSummary(summary))
    },[]);

    const listConfig: AppListConfig<Bill> = {
        title:
            "Listado de Facturas " +
            (listFilter.pending ? "Pendientes" : "") +
            (listFilter.pending && listFilter.advanced ? " y " : "") +
            (listFilter.advanced ? "Adelantadas" : ""),
        onClick: "/app/admin/billing/bill",
        decorator: (bean: Bill) => (
            <Grid container spacing={2}>
                <Grid item xs={2}>
                    <Box display={"flex"}>
                        <Box>[{bean.number}]</Box>
                        <Box color={billDateColor(bean)}>&nbsp;{bean.issueDate}</Box>
                    </Box>
                </Grid>
                <Grid item xs={2}>
                    {bean.customer?.shortName || ""}
                </Grid>
                <Grid item xs>
                    <small>{bean.description}</small>
                </Grid>
                <Grid item xs={3}>
                    <Grid container justifyContent="flex-end">
                        <Box display={"flex"}>
                            <Box>
                                <CustomNumberFormat amount={bean.totals?.total}/>
                            </Box>
                            <Box color={"text.disabled"}>
                                &nbsp;<small>con IVA</small>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        ),
        supplier: async (currentPage, size, term, showDismissed) => {
            return ctrl.pageBill(
                apiManager.companyId,
                currentPage,
                size,
                undefined,
                listFilter.advanced,
                listFilter.pending,
                term,
                showDismissed
            );
        },
        actions: [
            {
                action: () => {
                    navigate("/app/admin/billing/bill-batch");
                },
                color: "primary",
                label: <Box>
                    Añadir en lote
                </Box>

            },
            {
                action: () => {
                    setListFilter({
                        ...listFilter,
                        pending: true,
                    });
                    setTrigger(trigger + 1);
                },
                color: listFilter.pending ? "primary" : "secondary",
                label: <Box>
                    Pendientes  (<CustomNumberFormat amount={summary.pending}/> <small>con IVA</small>)
                       </Box>

            },
            {
                action: () => {
                    setListFilter({
                        ...listFilter,
                        advanced: true,
                    });
                    setTrigger(trigger + 1);
                },
                color: listFilter.advanced ? "primary" : "secondary",
                label:  <Box>
                        Adelantadas  (<CustomNumberFormat amount={summary.advanced}/>)
                        </Box>,
            },
            {
                action: () => {
                    setListFilter({
                        ...listFilter,
                        pending: false,
                        advanced: false,
                    });
                    setTrigger(trigger + 1);
                },
                color: "secondary",
                label: "Todas",
            },
        ],
    };

    return (
        <>
            <AppList config={listConfig} trigger={trigger} />
        </>
    );
};
