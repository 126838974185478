import React, {useState} from "react";
import {Interview, InterviewRestControllerApiFactory, Person} from "arteco-api-client-ts";
import {FormContent} from "../../../../components/form/form_inputs/form_buttons";
import {FormInput} from "../../../../components/form/form_inputs/form_input";
import {Box, Grid} from "@mui/material";
import {useForm} from "../../../../components/form/form_model";
import {useApiManager} from "../../../../components/utils/api";
import {useParams} from "react-router-dom";
import {useAuthManager} from "../../../../components/utils/auth";
import {AxiosResponse} from "axios";
import {parsePathId} from "../../../../components/form/form_utils";
import {PersonSummaryAlert} from "../person/summary";
import FormAutocomplete from "../../../../components/form/form_inputs/form_autocomplete";
import {types} from "../../../../components/form/types";


export const InterviewEditPage = () => {

	const params = useParams();
	const apiManager = useApiManager();

	const ctrl = apiManager.factory(InterviewRestControllerApiFactory);

	const personId = parsePathId(params, "personId");
	const [selectedPersonId, setSelectedPersonId] = useState<number | undefined>(personId);


	const form = useForm<Interview>({
		fetcher: (id) => {
			const prom = ctrl.getInterview(id);
			prom.then((resp: AxiosResponse<Interview>) => {
				setSelectedPersonId(resp.data.person?.id);
				return resp;
			});
			return prom;
		},
		updater: (bean) => ctrl.saveInterview(bean),
		remover: (id) => ctrl.deleteOrDismissInterview(id),
		factory: () => {
			return {
				person: {id: personId} as Person,
				interviewDate: new Date().toISOString(),
				privateComments: ""
			} as Interview;
		},
	});

	return (
		<>
			<FormContent form={form} title={"Ficha de la entrevista"}>

				<Box p={3} style={{marginBottom: "2em", backgroundColor: "rgba(232,232,232,0.55)"}}>

					<Grid container spacing={3}>
						<Grid item sm>

							{!personId && (
								<FormAutocomplete onChange={(t,p) => setSelectedPersonId((p as Person).id)} field={"person"} label={"Persona"} type={types.people} />
							)}

							<FormInput field={"interviewDate"} type={"date"} label={"Fecha de Entrevista"}/>

							<FormInput
								type={"text"}
								field={"privateComments"}
								label={"Comentarios privados"}
								placeholder={""}
								rows={10}/>
						</Grid>
						<Grid item sm>
							<PersonSummaryAlert personId={selectedPersonId}/>
						</Grid>
					</Grid>

				</Box>


			</FormContent>
		</>
	);
};
