import React, {useContext, useEffect, useState} from "react";
import {FormContext} from "../form_buttons";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {TypeConf, useType} from "../../types";
import {Box} from "@mui/system";
import {fieldMarginBottom} from "../../form_constants";

export interface FormSelectItem {
    value: string | number;
    label: string;
}

interface FormSelectProp<T> {
    field: string;
    label: string;
    type: TypeConf<T>;
    displayEmpty?: boolean;
    items?: T[];
    onChange?: (item: T) => void;
}

function FormSelect<T>(props: FormSelectProp<T>) {
    const { field, label, displayEmpty, type, items } = props;

    const typer = useType(type);

    const [elements, setElements] = useState<any[]>(items || []);

    const formContext = useContext(FormContext);
    const model = formContext.form;
    const _modelField = model.field(field);

    const [selectedValue, setSelectedValue] = useState<any>(_modelField.valueAsType(type) || "");

    model.register(field, () => {
        let result = elements.find((item) => type.valuer(item) === selectedValue);
        if (result && type.isEnum) {
            result = type.valuer(result);
        }
        return result;
    }, t => setSelectedValue(t));

    useEffect(() => {
        if (!items) {
            typer.supplier().then((array) => setElements(array));
        }
    }, [type]);

    useEffect(() => {
        let result = elements.find((item) => type.valuer(item) === selectedValue);

        if (typeof props.onChange === "function") {
            props.onChange(result);
        }
    }, [selectedValue]);

    return (
        <Box className={[ "form-input", "form-input-" + field].join(" ")} mb={fieldMarginBottom}>
            {elements && elements.length != 0 && (
                <FormControl fullWidth>
                    <InputLabel variant="filled" id="{field + '-lbl'}">
                        {label}
                    </InputLabel>
                    <Select
                        variant="filled"
                        labelId={field + "-lbl"}
                        label={label}
                        id={field}
                        fullWidth
                        displayEmpty={displayEmpty}
                        value={selectedValue}
                        onChange={(event) => {
                            const value = event.target.value as string;
                            setSelectedValue(value);
                        }}>
                        {displayEmpty && <MenuItem value="">&nbsp;</MenuItem>}
                        {elements &&
                            elements.length > 0 &&
                            elements.map((item, index) => (
                                <MenuItem
                                    value={type.valuer(item)}
                                    key={"select-" + field + "-" + index}>
                                    {type.labeler(item)}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
            )}
        </Box>
    );
}

export default FormSelect;
