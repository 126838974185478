import AdminInner from "../../../components/layout/admin_inner";
import {Box, Button, Grid, Modal, Tab, Typography} from "@mui/material";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import React, {HTMLAttributes, useEffect, useMemo, useState} from "react";
import {EmployeeRestControllerApiFactory, Holiday, Person, WorkCenter} from "arteco-api-client-ts";
import {useApiManager} from "../../../components/utils/api";
import {Calendar, Event, momentLocalizer, Views} from 'react-big-calendar'
import moment from 'moment'
import {dedicationModalStyle, employeeModalStyle} from "../modal";
import WikiPageRender from "../../../components/utils/wiki_page";
import {SECTION_HR} from "../../admin/employee/wiki/form";

// Setup the localizer by providing the moment (or globalize, or Luxon) Object
// to the correct localizer.

const localizer = momentLocalizer(moment) // or globalizeLocalizer

export const EmployeeWorkBooking = () => {
    const apiManager = useApiManager();
    const employeeCtrl = apiManager.factory(EmployeeRestControllerApiFactory);
    const [selectedTab, setSelectedTab] = useState<string>("");
    const [workCenters, setWorkCenters] = useState<WorkCenter[]>([]);
    const [workCenter, setWorkCenter] = useState<WorkCenter | undefined>(undefined);
    const [events, setEvents] = useState<Event[]>([]);
    const [holidays, setHolidays] = useState<Holiday[]>([]);
    const [date, setDate] = useState<Date>(new Date());
    const [showConditionModal, setShowConditionModal] = useState<boolean>(false);

    useEffect(() => {
        const date = new Date();
        // work centers
        apiManager
            .execute(employeeCtrl.getEmployeeWorkCenters())
            .then(centers => {
                setWorkCenters(centers);
                if (centers != null && centers.length > 0) {
                    setSelectedTab(centers[0].name);
                    setWorkCenter(centers[0]);
                }
            });
        // holidays
        apiManager
            .execute(employeeCtrl.getEmployeeHolidays(date.getFullYear()))
            .then(holidays => setHolidays(holidays));

    }, []);

    useEffect(() => {
        if (workCenter) {
            loadBookings(workCenter, date.getMonth()+1);
        }
    }, [date, workCenter]);

    const {views} = useMemo(
        () => ({
            views: {
                month: true,
            },
        }),
        []
    )

    const checkIfHoliday = (date: Date): boolean => {
        const m = moment(date);
        return holidays.find(h => {
            const m2 = moment(h.holidayDate);
            return m2.isSame(m) && (h.calendar == undefined || h.calendar==workCenter?.calendar);
        }) != undefined;
    }

    const loadBookings = (workCenter: WorkCenter, month: number) => {
        apiManager
            .execute(employeeCtrl.getEmployeeBookings(workCenter.id as number, month))
            .then(bookings => {
                const map = new Map<string, Event>();
                bookings.forEach((elem, i) => {
                    let event = map.get(elem.bookingDate);
                    if (!event) {
                        event = {
                            id: elem.id,
                            resourceId: elem.id,
                            allDay: true,
                            start: moment(elem.bookingDate).toDate(),
                            end: moment(elem.bookingDate).add(1, "d").toDate(),
                            count: elem.places || 1,
                            min: workCenter.minForOpen,
                            people: [elem.person],
                        } as Event;
                        map.set(elem.bookingDate, event);
                    } else {
                        (event as any).count = (event as any).count + 1;
                        (event as any).people.push(elem.person);
                    }
                });

                const eventsArr = Array.from(map.values())
                    .map(e => {
                        const people = (e as any).people.map((p: Person) => p.surnamesAndName).join(",\n");
                        return {...e, title: (e as any).count + "/" + workCenter.minForOpen + " por:\n" + people}
                    });

                setEvents(eventsArr);
            });
    }

    return (
        <AdminInner>
            <Box>
                <TabContext value={selectedTab}>
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <TabList onChange={(event: React.SyntheticEvent, newValue: string) => setSelectedTab(newValue)}>
                            {workCenters && workCenters.length > 0 && workCenters.map((workCenter, i) =>
                                <Tab key={"tab-" + i} label={workCenter.name} value={workCenter.name}/>
                            )}
                        </TabList>
                    </Box>
                    {workCenters && workCenters.length > 0 && workCenters.map((workCenter, i) =>
                        <TabPanel key={"tab-content-" + i} value={workCenter.name} sx={{padding: 0, paddingTop: 1}}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={4}>
                                    <Typography variant={"subtitle1"} sx={{fontWeight: "bold"}} pt={2}>
                                        DIRECCIÓN
                                    </Typography>
                                    <Typography variant={"body1"}>
                                        {workCenter.address}, {workCenter.postalCode}, {workCenter.city}
                                    </Typography>
                                    <Typography variant={"subtitle1"} sx={{fontWeight: "bold"}} pt={2}>
                                        INSTRUCCIONES
                                    </Typography>
                                    <Typography variant={"body1"}>
                                        {workCenter.instructions}
                                    </Typography>
                                    <Typography variant={"subtitle1"} sx={{fontWeight: "bold"}} pt={2}>
                                        PLAZAS
                                    </Typography>
                                    <Typography variant={"body1"}>
                                        {workCenter.places}
                                    </Typography>
                                    <Typography variant={"subtitle1"} sx={{fontWeight: "bold"}} pt={2}>
                                        MÍNIMO
                                    </Typography>
                                    <Typography variant={"body1"}>
                                        {workCenter.minForOpen}
                                    </Typography>

                                    <Typography variant={"subtitle1"} sx={{fontWeight: "bold"}} pt={2}>
                                        MÁS INFO
                                    </Typography>
                                    <ul>
                                        <li>Pulsa sobre el día que quieras para reservar.</li>
                                        <li>Se pondrá en verde cuando se alcance el cupo mínimo requerido.</li>
                                        <li>Puedes ver quién más se ha apuntado pulsando sobre el evento.</li>
                                        <li>Para cancelar avisa a <a href={"mailto:rrhh@arteco-consulting.com"}>RRHH</a>
                                        </li>
                                    </ul>
                                    <Typography variant={"subtitle1"} sx={{fontWeight: "bold"}} pt={2}>
                                        CONDICIONES
                                    </Typography>
                                    <Typography variant={"body1"} pt={2}>
                                        Consulta el resto de <Button size={"small"} variant={"text"} onClick={_ => setShowConditionModal(true)}>condiciones</Button>
                                        <Modal
                                            open={showConditionModal}
                                            onClose={_ => setShowConditionModal(false)}
                                            aria-labelledby="condition-modal-title"
                                            aria-describedby="condition-modal-description">
                                            <Box sx={dedicationModalStyle}>
                                                <Typography id="condition-modal-title" variant="h6" component="h2">
                                                    Condiciones de uso de Oficina
                                                </Typography>
                                                <Typography id="condition-modal-description" sx={{mt: 2}}>
                                                    <WikiPageRender code={"oficina.reserva.condiciones"} section={SECTION_HR}/>
                                                </Typography>
                                            </Box>
                                        </Modal>

                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <Box sx={{height: "600px"}}>
                                        <Calendar
                                            date={date}
                                            onNavigate={(date: Date) => {
                                                setDate(date);
                                                setWorkCenter(workCenter)
                                            }}
                                            localizer={localizer}
                                            defaultView={Views.MONTH}
                                            startAccessor="start"
                                            endAccessor="end"
                                            views={views}
                                            events={events}
                                            selectable={"ignoreEvents"}
                                            dayPropGetter={(date: Date, resourceId?: number | string): HTMLAttributes<HTMLDivElement> => {
                                                let color: string | undefined = undefined;
                                                const red = "#ffb0b0";
                                                const isHoliday = checkIfHoliday(date);
                                                if (date.getDay() == 0 || date.getDay() == 6 || isHoliday) color = red;
                                                return {style: {backgroundColor: color}}
                                            }}
                                            onSelectEvent={event => {
                                                alert(event.title);
                                            }}
                                            onSelectSlot={slotInfo => {
                                                const m = moment(slotInfo.start);
                                                const isHoliday = checkIfHoliday(slotInfo.start);
                                                const strDateApi = m.format("YYYY-MM-DD");
                                                const strDate = m.format("DD/MM/YYYY");
                                                if (isHoliday || m.isoWeekday() == 6 || m.isoWeekday() == 7) {
                                                    alert("Este día está marcado como no laboral o festivo");
                                                } else if (confirm("Deseas aplicar la reserva para el día "
                                                    + strDate + "?")) {
                                                    apiManager.execute(employeeCtrl.saveEmployeeBooking(workCenter.id as number, strDateApi))
                                                        .then(_ => {
                                                            alert("Se ha creado la reserva. Recuerda que debe haber un mínimo de "
                                                                + workCenter.minForOpen + " para poder abrir la oficina.");
                                                            loadBookings(workCenter, m.month());
                                                        });

                                                }
                                            }}
                                            eventPropGetter={event => {
                                                const count = (event as any).count;
                                                let color: string | undefined = undefined;
                                                if (count >= workCenter.places) {
                                                    color = "#fdcece";
                                                } else if (count < workCenter.minForOpen) {
                                                    color = "#fff2b7";
                                                } else {
                                                    color = "#c5ffc5";
                                                }
                                                return {
                                                    style: {
                                                        backgroundColor: color,
                                                        color: "black"
                                                    }
                                                }
                                            }}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>

                        </TabPanel>
                    )}
                </TabContext>
            </Box>
        </AdminInner>
    );
}