import React, {FormEvent, useEffect, useState} from "react";
import {useApiManager} from "../components/utils/api";
import {UserAuth, UserRestControllerApiFactory} from "arteco-api-client-ts";
import {Alert, Button, TextField} from "@mui/material";
import {isEmpty} from "../components/utils/functions";
import {AxiosResponse} from "axios";
import {LoginLayout} from "../components/layout/login_layout";

export default function Recover() {
    const apiManager = useApiManager();
    const ctrl = apiManager.factory(UserRestControllerApiFactory);

    const [email, setEmail] = useState("");

    const [fieldsNotEmpty, setFieldsNotEmpty] = useState({
        email: false,
    });
    const [foundError, setFoundError] = useState(false);
    const [invalidData, setInvalidData] = useState(true);
    const [formSent, setFormSent] = useState(false);
    const [emailSent, setEmailSent] = useState(false);

    const sendForm = (event: FormEvent) => {
        event.preventDefault();
        setFormSent(true);
        ctrl.recover(email)
            .then((resp: AxiosResponse<UserAuth>) => {
                setFoundError(false);
                setEmailSent(true);
            })
            .catch((err: any) => {
                setFoundError(true);
                setFormSent(false);
            });
    };

    useEffect(() => {
        setInvalidData(!(fieldsNotEmpty.email));
    }, [fieldsNotEmpty]);

    return (
        <LoginLayout title={"Recupera tu acceso"}>
            <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete={"off"}
                type="email"
                autoFocus
                onChange={(event) => {
                    if (isEmpty(event.target.value)) {
                        setFieldsNotEmpty({
                            ...fieldsNotEmpty,
                            email: false,
                        });
                    } else {
                        setEmail(event.target.value);
                        setFieldsNotEmpty({
                            ...fieldsNotEmpty,
                            email: true,
                        });
                    }
                }}
            />

            <Button
                fullWidth
                variant="contained"
                sx={{mt: 3, mb: 2}}
                onClick={sendForm} disabled={invalidData || formSent}>
                Solicitar nueva clave
            </Button>

            {foundError && (
                <Alert severity="error" sx={{mb:2}}>Email no registrado</Alert>
            )}
            {emailSent && (
                <Alert severity="success" sx={{mb:2}}>Se ha enviado un email con las instrucciones</Alert>
            )}
        </LoginLayout>
    );
}
