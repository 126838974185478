import {ListItem, ListItemIcon, ListItemText} from "@mui/material";
import * as React from "react";
import {useNavigate} from "react-router-dom";
import styles from "./sidebar_item.module.scss";


type SideBarItemProp = {
    label: React.ReactNode;
    href?: string;
    icon?: React.ReactElement;
    onClick?: () => void;
    divider?: boolean;
};

export default function SideBarItem(props: SideBarItemProp) {
    const navigate = useNavigate();
    const clickHandler = (event: any) => {
        if (props.onClick) {
            props.onClick();
        } else if (props.href) {
            let newWindowReq = false;
            if (event.button == 1) {
                newWindowReq = true;
            }
            const url = props.href;
            if (newWindowReq) {
                window.open(url);
            } else {
                navigate(props.href);
            }

        }
    };
    return (
        <>
            {props.href && (
                <ListItem divider={props.divider} button
                          onMouseUp={(event) => clickHandler(event)}
                          onClick={(event) => clickHandler(event)}>
                    {props.icon && <ListItemIcon sx={{minWidth:0}}>{props.icon}</ListItemIcon>}
                    <ListItemText sx={{paddingLeft:1}}  className={styles.item__text} primary={props.label}/>
                </ListItem>
            )}
            {!props.href && (
                <ListItem divider={props.divider} button
                          onMouseUp={(event) => clickHandler(event)}
                          onClick={(event) => clickHandler(event)}>
                    {props.icon && <ListItemIcon sx={{minWidth:0}}>{props.icon}</ListItemIcon>}
                    <ListItemText sx={{paddingLeft:1}} className={styles.item__text} primary={props.label}/>
                </ListItem>
            )}
        </>
    );
}
