import {Typography} from "@mui/material";
import React from "react";
import {H1, Typographies} from "../utils/typography_consts";

export interface AdminTitleProps {
    title: string;
}

const AdminTitle = (props: AdminTitleProps) => {
    return (
        <Typography style={{textTransform: "uppercase", marginBottom: "1em"}} component={H1} variant={Typographies.h5}>
            {props.title}
        </Typography>
    );
};

export default AdminTitle;
