import WikiPageRender from "../../../components/utils/wiki_page";
import {useParams} from "react-router-dom";
import AdminInner from "../../../components/layout/admin_inner";

const EmployeeWikiRender = () => {
    const params = useParams();
    const code = params["code"] || "code";
    const section = params["section"] || "section";

    return (
        <AdminInner>
            <WikiPageRender code={code} section={section}/>
        </AdminInner>
    );
}

export default EmployeeWikiRender;