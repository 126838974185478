import {Alert, Box, Grid, IconButton, List, ListItem, ListItemText, Tab, Typography} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {H4, Typographies} from "../../../../components/utils/typography_consts";
import {dateFromToString} from "../../../../components/utils/dates";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import React, {useEffect, useState} from "react";
import {Occupation_calendar} from "./occupation_calendar";
import {OccupationProcessed, PersonOccupation} from "./occupation_utils";
import {OccupationPersonYear,} from "arteco-api-client-ts";
import DedicationTable from "./dedication/dedication_table";
import AlertTitle from "@mui/material/AlertTitle";


export interface OccupationIndividualProps {
    occupation: OccupationProcessed;
    month: string;
    year: number;
}

export const OccupationIndividual = ({occupation, month, year}: OccupationIndividualProps) => {

    const [selectedTab, setSelectedTab] = useState<string>("dedication");
    const [selectedPersonId, setSelectedPersonId] = useState<number>();
    const [personRow, setPersonRow] = useState<PersonOccupation | undefined>();

    const changePerson = (occupationPersonYear: OccupationPersonYear): void => {
        const personId = occupationPersonYear.personId;
        setSelectedPersonId(personId);
    }

    useEffect(() => {
        if (occupation && month && selectedPersonId) {
            let occupationByPeopleByMonth = occupation.occupationByPeopleByMonth;
            if (occupationByPeopleByMonth) {
                const monthOccup = occupationByPeopleByMonth.get(month);
                if (monthOccup) {
                    const personOccup = monthOccup.get(selectedPersonId);
                    if (personOccup) {
                        setPersonRow(personOccup);
                    }
                }
            }
        }
    }, [occupation, selectedPersonId, month]);


    return (
        <Grid container wrap={"nowrap"} spacing={3}>
            <Grid item sm={4}>
                <div style={{
                    maxHeight: "70vh",
                    overflow: "auto",
                    backgroundColor: "rgba(243,243,243,0.37)",
                    borderRight: "1px solid gray",
                    borderBottom: "1px solid gray"
                }}>
                    <List>
                        {(occupation.peopleByMonth.get(month) || []).map((personYear, pIdx) => (
                            <ListItem
                                key={pIdx}
                                divider
                                button
                                selected={selectedPersonId == personYear.personId}
                                secondaryAction={
                                    <>
                                        <small style={{
                                            color: ((personYear.yearStats?.avgHourRate || 0) <= (personYear.yearStats?.salaries || 0) / 1000) ? "red" : "gray",
                                            marginLeft: "0.5em"
                                        }}>
                                            {(personYear.yearStats?.avgHourRate || 0)} / {((personYear.yearStats?.salaries || 0) / 1000).toFixed(1)}K
                                        </small>
                                        <IconButton style={{backgroundColor: "white"}} onClick={(e) => {
                                            window.open("/app/admin/employee/person/" + personYear.personId);
                                        }} edge="end" aria-label="delete">
                                            <PersonIcon/>
                                        </IconButton>
                                    </>

                                }
                                onClick={() => changePerson(personYear)}>
                                <ListItemText style={{whiteSpace: "nowrap", textOverflow: "ellipsis"}}>
                                    {pIdx + 1 + " - " + personYear.personName} &nbsp; | &nbsp;
                                    <small style={{color: "gray", marginLeft: "0.5em"}}>{personYear.personHelp}</small>
                                </ListItemText>
                            </ListItem>
                        ))}
                    </List>
                </div>
            </Grid>
            <Grid item sm sx={{padding: 1, backgroundColor: "white", zIndex: 10}}>
                {!personRow && (
                    <Alert sx={{m: 3}} icon={<ArrowBackIcon/>}>
                        <Typography variant={Typographies.body1} component={H4}>
                            Selecciona una persona
                        </Typography>
                    </Alert>
                )}
                {personRow && (
                    <>
                        <Alert sx={{marginY: 3}} severity="success">
                            <AlertTitle>Resumen empleado</AlertTitle>
                            <Grid container spacing={3}>
                                <Grid item>
                                    Vacaciones: {personRow.yearStats.takenVacations}/{personRow.yearStats.allowedVacations}
                                    <br/>
                                    Salario: {personRow.monthStats.companyCost?.toFixed(2)} <br/>
                                    Actualizado: {dateFromToString(personRow.monthStats.salaryUpdate)}{" "}({personRow.monthStats.salaryUpdateMonths}{" "}meses)
                                </Grid>
                            </Grid>
                        </Alert>


                        <TabContext value={selectedTab}>
                            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                                <TabList onChange={(event: React.SyntheticEvent, newValue: string) => {
                                    setSelectedTab(newValue);
                                }}>
                                    <Tab label="Dedicación" value="dedication"/>
                                    <Tab label="Calendario" value="calendar"/>
                                </TabList>
                            </Box>
                            <TabPanel value="dedication" sx={{padding: 0, paddingTop: 1}}>
                                <DedicationTable showPerson={false}
                                                 personId={personRow.personId}
                                                 occupation={occupation}
                                                 month={personRow.month.toString()}
                                                 year={year}/>

                            </TabPanel>
                            <TabPanel value="calendar" sx={{padding: 0, paddingTop: 1}}>
                                <Occupation_calendar personRow={personRow}></Occupation_calendar>
                            </TabPanel>
                        </TabContext>

                    </>
                )}
            </Grid>
        </Grid>
    );
}