import React from "react";
import {CandidateLevelTest, CandidateLevelTestRestControllerApiFactory} from "arteco-api-client-ts";
import {FormContent} from "../../../../components/form/form_inputs/form_buttons";
import {FormInput} from "../../../../components/form/form_inputs/form_input";
import {useForm, UseFormProps} from "../../../../components/form/form_model";
import {useApiManager} from "../../../../components/utils/api";
import FormFile from "../../../../components/form/form_inputs/form_file";


export const CandidateLevelTestEditPage = () => {
    const apiManager = useApiManager();
    const ctrl = apiManager.factory(CandidateLevelTestRestControllerApiFactory);
    const companyId = apiManager.companyId;


    const form = useForm({
        fetcher: (id) => ctrl.getCandidateLevelTest(id),
        updater: (bean) => ctrl.saveCandidateLevelTest(bean),
        remover: (id) => ctrl.deleteOrDismissCandidateLevelTest(id),
        factory: () => {
            return {company: {id: companyId}} as CandidateLevelTest;
        },

    } as UseFormProps<CandidateLevelTest>);

    return (
        <>
            <FormContent form={form} title={"Formulario de Tipo de Pruebas de Nivel"}>


                <FormInput type={"text"} field={"name"} label={"Nombre"}/>

                <FormFile
                    field={"attachment"}
                    label={"Prueba de nivel (máx 5Mb)"}
                    onDownload={() => ctrl.downloadCandidateLevelTest(form.getIdOrErr())}
                />

                <FormInput type={"text"} field={"description"}
                           label={"Descripción Pública"} rows={10}/>


            </FormContent>
        </>
    );
};