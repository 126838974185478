import React from "react";
import {FormContext} from "../form_buttons";
import classes from "./form_entities.module.scss";
import {Box, Grid} from "@mui/material";
import {FormInput} from "../form_input";
import {fieldMarginBottom} from "../../form_constants";

export interface FormEntitiesProps {
    hide?: boolean;
}

export const FormEntities = ({hide}:FormEntitiesProps) => {
    return (
        <FormContext.Consumer>
            {(contextValue) => (
                <>
                    {!hide && contextValue.form.hasId() && (
                        <Box className="form__entities"  mb={fieldMarginBottom}>
                            <Box className={classes.form__entities__box}>
                                <Grid container spacing={3}>
                                    <Grid item sm>
                                        <FormInput
                                            field={"id"}
                                            type={"number"}
                                            label={"Id"}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item sm>
                                        <FormInput
                                            field={"created"}
                                            type={"datetime"}
                                            label={"Creado"}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item sm>
                                        <FormInput
                                            field={"modified"}
                                            type={"datetime"}
                                            label={"Modificado"}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item sm>
                                        <FormInput
                                            field={"dismissed"}
                                            type={"datetime"}
                                            label={"Baja"}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    )}
                </>
            )}
        </FormContext.Consumer>
    );
};