import {CandidateSource, CandidateSourceRestControllerApiFactory,} from "arteco-api-client-ts";
import AppList, {AppListConfig} from "../../../../components/layout/list";
import {COLOR_GRIS} from "../../../../components/layout/colors";
import {Grid} from "@mui/material";
import {useApiManager} from "../../../../components/utils/api";

export const CandidateSourceListPage = () => {
    const apiManager = useApiManager();

    const listConfig: AppListConfig<CandidateSource> = {
        title: "Listado de orígenes de candidatos ",
        onClick: "/app/admin/recruitment/source",
        decorator: (bean: CandidateSource) => (
            <Grid container style={{color: bean.dismissed ? COLOR_GRIS : ""}}>
                <Grid item sm>
                    {bean.label}
                </Grid>
            </Grid>
        ),
        supplier: async (currentPage, size, term, showDismissed) => {
            const companyId = apiManager.companyId;
            const ctrl = apiManager.factory(CandidateSourceRestControllerApiFactory);
            return ctrl.pageCandidateSource(companyId, currentPage, size, undefined, term, showDismissed);
        },
    };

    return (
        <>
            <AppList config={listConfig} />
        </>
    );
};