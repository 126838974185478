import moment from "moment";

// export const JSON_DATETIME_PATTERN = "yyyy-MM-dd HH:mm:ss:SSSS";
export const ESP_DATE_PATTERN = "DD/MM/YYYY";

export function dateFromString(txt: string | undefined | null): Date | undefined {
    if (txt) {
        return moment(txt).toDate();
    }
    return undefined;
}

export function dateToString(date: Date | string | undefined | null, pattern?: string): string {
    let newDate: Date | undefined = undefined;
    if (typeof date === "string") {
        newDate = dateFromString(date);
    } else if (date instanceof Date) {
        newDate = date as Date;
    }
    if (newDate != undefined && !isNaN(newDate.getDate())) {
        return moment(newDate).locale('es').format(pattern || ESP_DATE_PATTERN);
    }
    return "";
}

export function dateFromToString(txt: string | undefined | null): string {
    const date = dateFromString(txt);
    return dateToString(date);
}
