import {useApiManager} from "../../../components/utils/api";
import {DirectoryPerson, EmployeeRestControllerApiFactory, Item} from "arteco-api-client-ts";
import {
	Box,
	Card,
	CardContent,
	CardHeader,
	CardMedia,
	FormControl,
	InputLabel,
	MenuItem,
	Modal, modalClasses,
	Select,
	Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import AdminInner from "../../../components/layout/admin_inner";
import {envVars} from "../../../components/utils/env_vars";
import {dateToString} from "../../../components/utils/dates";
import {abbreviate} from "../../../components/utils/functions";

export const EmployeeDirectoryPage = () => {

	const apiManager = useApiManager();
	const employeeApi = apiManager.factory(EmployeeRestControllerApiFactory);

	const [detailed, setDetailed] = useState<DirectoryPerson| undefined>(undefined);
	const [manager, setManager] = useState<Item | undefined>(undefined);
	const [managers, setManagers] = useState<Item[]>([]);
	const [people, setPeople] = useState<DirectoryPerson[]>([]);

	useEffect(() => {
		employeeApi.getEmployeeDirectory(undefined).then(resp => {
			const newManagers: Item[] = [];
			resp.data.content?.forEach(p => {
				if (p.manager && p.manager.value && !newManagers.find(m => m.value == p.manager?.value)) {
					newManagers.push(p.manager);
				}
			})

			setManagers(newManagers.sort((p1: Item ,p2: Item ): number => {
				let r : number = p1.label?.localeCompare(p2.label || "") || 0
				return r;
			}));
			setPeople(resp.data.content as []);
		});
	}, []);


	const modalStyle = {
		position: 'absolute' as 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 400,
		bgcolor: 'background.paper',
		border: '1px solid #666',
		boxShadow: 24,
		p: 4,
		borderRadius: '7px'
	};

	const tableThColor = {backgroundColor: "#8e8e8e", color: "white"};

	return (
		<AdminInner title={"Directorio"}>
			<div>
				<FormControl fullWidth sx={{mb: 2}}>
					<InputLabel id="selManager">Filtrar por manager</InputLabel>
					<Select
						variant={"outlined"}
						fullWidth
						label={"Manager"}
						labelId={"selManager"}
						onChange={e => setManager(managers.find(m => m.value == e.target.value))}>
						<MenuItem value={''}>Todos</MenuItem>
						{managers.map(manager => (
							<MenuItem value={manager.value}>{manager.label}</MenuItem>
						))}
					</Select>
				</FormControl>
			</div>

			<div style={{display: "flex", flexWrap: "wrap", }}>
				{people.map((bean, idx) => (
					<>
						{(!manager || bean.manager?.value == manager.value) && (

							<Card sx={{width: 200, cursor: "pointer", marginBottom: "1em", marginRight: "1em"}}
								  elevation={3}
								  onClick={_ => {
									  if (idx != undefined && idx > -1) {
										  if (detailed == idx) {
											  setDetailed(undefined)
										  } else {
											  setDetailed(bean);
										  }
									  }
								  }}
							>
								<CardHeader
									sx={{height: 100}}
									title={bean.surname + ", " + bean.name}
									subheader={abbreviate(bean.coverPhrase, 45) || "Sin presentación"}
								>
								</CardHeader>
								<CardMedia
									component="img"
									height="194"
									image={envVars.apiBasePath + "/pub/team/photo/" + bean.number + "?width=500"}
									alt={bean.surname + ", " + bean.name}
								/>
								<CardContent sx={{fontSize: "10px", height:100}}>
									{abbreviate(bean.bio,100) || "Sin biografía"}
								</CardContent>
							</Card>

						)}
					</>
				))}
			</div>
			<Modal
				open={detailed!=undefined}
				onClose={_ => setDetailed(undefined)}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={modalStyle}>
					<Typography id="modal-modal-title" variant="h6" component="h2">
						{detailed?.surname+", "+detailed?.name}
					</Typography>
					<Typography id="modal-modal-description" sx={{ mt: 2 }}>
						{detailed && (
							<table>
								<tr>
									<td align={"right"} style={tableThColor}>Email</td>
									<td width={"85%"}>{detailed.email}</td>
								</tr>
								<tr>
									<td align={"right"} style={tableThColor}>Alta</td>
									<td>{dateToString(detailed.hireDate)}</td>
								</tr>
								<tr>
									<td align={"right"} style={tableThColor}>Cumpleaños</td>
									<td>{dateToString(detailed.birthday)}</td>
								</tr>
								{detailed.manager && (
									<tr>
										<td align={"right"} style={tableThColor}>Mánager</td>
										<td>{detailed.manager?.label}</td>
									</tr>
								)}
								<tr>
									<td align={"right"} style={tableThColor}>Formación máxima</td>
									<td>{detailed.maxTrainingLevel?.label}</td>
								</tr>
								<tr>
									<td align={"right"} style={tableThColor}>Biografía</td>
									<td>{detailed.bio || "---"}</td>
								</tr>
							</table>
						)}

					</Typography>
				</Box>
			</Modal>

		</AdminInner>
	);
}