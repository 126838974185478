import {Grid} from "@mui/material";
import React, {useEffect, useState} from "react";
import {CandidateEvent, CandidateEventRestControllerApiFactory,} from "arteco-api-client-ts";
import AppList, {AppListConfig} from "../../../../../components/layout/list";
import {useApiManager} from "../../../../../components/utils/api";
import {dateFromString, dateToString} from "../../../../../components/utils/dates";
import {useParams} from "react-router-dom";
import {useCandidate} from "../index";
import {parsePathId} from "../../../../../components/form/form_utils";

export const CandidateEventListPage = () => {
    const apiManager = useApiManager();
    const params = useParams();
    const candidateId = parsePathId(params, "candidateId", true);

    const eventApi = apiManager.factory(CandidateEventRestControllerApiFactory);

    const [candidate, setCandidate] = useCandidate(candidateId);

    const [enabled, setEnabled] = useState<boolean>(false);

    useEffect(() => {
        if (candidateId) {
            setEnabled(true);
        }
    }, [candidateId]);

    const listConfig: AppListConfig<CandidateEvent> = {
        title:
            "Eventos del candidato " +
            (candidate ? candidate.surnames + ", " + candidate.name : ""),
        onClick: "/app/admin/recruitment/candidate/" + candidateId + "/event",
        decorator: (bean: CandidateEvent) => (
            <Grid container >
                <Grid item sm>
                    {dateToString(dateFromString(bean.issueDate))}
                </Grid>
                <Grid item sm>
                    {bean.type}
                </Grid>
                <Grid item sm>
                    {bean.description ? bean.description.substr(0, 50) : ""}
                </Grid>
            </Grid>
        ),
        supplier: async (currentPage, size, term, showDismissed) => {
            if (!candidateId) throw new Error("No candidateId");
            return eventApi.pageCandidateEvent(candidateId, currentPage, size, undefined, term, showDismissed);
        },
    };

    return (
        <>
            <AppList config={listConfig} enabled={enabled} />
        </>
    );
};