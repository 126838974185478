import React, {useEffect, useState} from "react";
import {AppBar, Avatar, Box, Button, Menu, MenuItem, Toolbar,} from "@mui/material";
import {HOME_URL, LOGIN_URL, PROFILE_URL,} from "../../utils/urls";
import {AuthManager, useAuthManager} from "../../utils/auth";
import {COLOR_VERDE_ARTECO} from "../colors";
import {Link, NavigateFunction, useNavigate} from "react-router-dom";
import LogoutIcon from '@mui/icons-material/Logout';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import {UserAuthItem, UserAuthItemTypeEnum} from "arteco-api-client-ts";
import NavbarItem from "./navbar_item";
import {envVars} from "../../utils/env_vars";

export interface RoleInfo {
    title: string;
    description: string;
    target: string;
    isAdmin: boolean;
}

export const ROLE_MAP = new Map<UserAuthItemTypeEnum, RoleInfo>();

ROLE_MAP.set(UserAuthItemTypeEnum.SUPERADMIN, {
    title: "SUPERADMIN",
    description: "Accede al panel de control para los mantenimientos del sistema",
    target: "/app/admin/super"
} as RoleInfo);

ROLE_MAP.set(UserAuthItemTypeEnum.ADMIN, {
    title: "ADMIN",
    description: "Gestiona todas los módulos de la empresa",
    target: "/app/admin"
} as RoleInfo);

ROLE_MAP.set(UserAuthItemTypeEnum.ADMIN_BILLING, {
    title: "FACTURACIÓN",
    description: "Módulo de facturación de la empresa",
    target: "/app/admin/billing",
    isAdmin: true
} as RoleInfo);

ROLE_MAP.set(UserAuthItemTypeEnum.ADMIN_EMPLOYEE, {
    title: "RRHH",
    description: "Módulo de gestión de recursos humanos",
    target: "/app/admin/employee",
    isAdmin: true
} as RoleInfo);

ROLE_MAP.set(UserAuthItemTypeEnum.ADMIN_RECRUITMENT, {
    title: "SELECCIÓN",
    description: "Módulo de gestión de los procesos de selección",
    target: "/app/admin/recruitment",
    isAdmin: true
} as RoleInfo);

ROLE_MAP.set(UserAuthItemTypeEnum.ADMIN, {
    title: "ADMIN",
    description: "Accede a la sección de backups",
    target: "/app/admin/company",
    isAdmin: true
} as RoleInfo);

ROLE_MAP.set(UserAuthItemTypeEnum.EMPLOYEE, {
    title: "EMPLEADO",
    description: "Accede al Portal del Empleado",
    target: "/app/employee"
} as RoleInfo);

ROLE_MAP.set(UserAuthItemTypeEnum.PUBLIC, {
    title: "PUBLICO",
    description: "Accede a la sección de usuarios generales",
    target: "/app/profile"
} as RoleInfo);

export const tryGoToDefaultPage = (auth: UserAuthItem | undefined, authManager: AuthManager, navigate : NavigateFunction) => {
    if (auth && auth.type) {
        const info = ROLE_MAP.get(auth.type);
        if (info && info.target) {
            authManager.saveSelectedAuthority(auth);
            navigate(info.target);
        }
    }
}

const Navbar = () => {
    const basePath = envVars.apiBasePath;
    const authManager = useAuthManager();
    const [menuElementReference, setMenuElementReference] = useState<any | undefined>();

    const showUserMenu = Boolean(menuElementReference);
    const navigate = useNavigate();

    useEffect(() => {
        const auths = authManager.getAuthorities();
        if (!authManager.getSelectedAuthority() && auths && auths.length > 0) {
            const auth = auths[0];
            tryGoToDefaultPage(auth, authManager, navigate);
        }
    }, []);

    const showPopover = (event: any) => {
        setMenuElementReference(event.currentTarget);
    };

    return (
        <>
            <Box sx={{flexGrow: 1}}>
                <AppBar position="static"
                        style={{backgroundColor: basePath && basePath.indexOf("arteco-consulting.com") > -1 ? "undefined" : "#4d0707"}}>
                    <Toolbar>

                        <Link to={HOME_URL} style={{marginRight: "2em"}}>
                            <img width="112" src="/img/logo_verde.png" alt="Arteco logo"/>
                        </Link>


                        {authManager.isLogged() && (
                            <Box sx={{flexGrow: 1, mx: 2, overflow: "auto", maxWidth: "100%"}}>
                                <NavbarItem map={ROLE_MAP} role={UserAuthItemTypeEnum.SUPERADMIN}/>
                                <NavbarItem map={ROLE_MAP} role={UserAuthItemTypeEnum.ADMIN_BILLING}/>
                                <NavbarItem map={ROLE_MAP} role={UserAuthItemTypeEnum.ADMIN_EMPLOYEE}/>
                                <NavbarItem map={ROLE_MAP} role={UserAuthItemTypeEnum.ADMIN_RECRUITMENT}/>
                                <NavbarItem map={ROLE_MAP} role={UserAuthItemTypeEnum.ADMIN}/>
                                <NavbarItem map={ROLE_MAP} role={UserAuthItemTypeEnum.EMPLOYEE}/>
                            </Box>
                        )}


                        {authManager.isLogged() && (

                            <>
                                <Avatar
                                    onClick={showPopover}
                                    alt={authManager.getUsername()}
                                    src={authManager.getImageSrc()}
                                    style={{
                                        cursor: "pointer",
                                        border: `2px solid ${COLOR_VERDE_ARTECO} `,
                                    }}
                                />
                                <Menu
                                    open={showUserMenu}
                                    onClose={() => setMenuElementReference(null)}
                                    anchorEl={menuElementReference}>
                                    <MenuItem onClick={() => {
                                        navigate(PROFILE_URL);
                                    }}>
                                        <AccountBoxIcon sx={{mx: 1}}/> Perfil [{authManager.getUsername()}]
                                    </MenuItem>
                                    <MenuItem onClick={() => authManager.doLogout()}>
                                        <LogoutIcon sx={{mx: 1}}/> Salir
                                    </MenuItem>
                                </Menu>
                            </>

                        )}
                        {!authManager.isLogged() && (
                            <Button onClick={() => navigate(LOGIN_URL)}>Login</Button>
                        )}

                    </Toolbar>
                </AppBar>
            </Box>

        </>
    );
};

export default Navbar;
