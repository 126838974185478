import {useApiManager} from "../../components/utils/api";
import {EmployeeRestControllerApiFactory} from "arteco-api-client-ts";
import {useEffect, useState} from "react";


export const useLastEvaluation = (): [boolean, React.Dispatch<React.SetStateAction<boolean>>] => {
	const apiManager = useApiManager();
	const employeeCtrl = apiManager.factory(EmployeeRestControllerApiFactory);
	const [forceEvaluation, setForceEvaluation] = useState<boolean>(false);

	useEffect(() => {
		apiManager.execute(employeeCtrl.getLastEvaluationDays())
		.then(lastEvaluationDays => {
			const days = parseInt(lastEvaluationDays as any);
			if (!isFinite(days) || days > 30) {
				setForceEvaluation(true);
			}
		});
	}, []);

	return [forceEvaluation, setForceEvaluation];
}