import React, {FormEvent, useEffect, useState} from "react";
import {useApiManager} from "../components/utils/api";
import {UserAuth, UserRestControllerApiFactory} from "arteco-api-client-ts";
import {Alert, Button, TextField} from "@mui/material";
import {SELECT_ROLE_URL} from "../components/utils/urls";
import {isEmpty} from "../components/utils/functions";
import {useAuthManager} from "../components/utils/auth";
import {useNavigate} from "react-router-dom";
import {LoginLayout} from "../components/layout/login_layout";

export default function Login() {
    const apiManager = useApiManager();
    const authManager = useAuthManager();
    const navigate = useNavigate();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const [fieldsNotEmpty, setFieldsNotEmpty] = useState({
        username: false,
        password: false,
    });
    const [foundError, setFoundError] = useState("");
    const [invalidData, setInvalidData] = useState(true);
    const [formSent, setFormSent] = useState(false);

    const sendForm = (event: FormEvent) => {
        event.preventDefault();
        setFormSent(true);


        const api = apiManager.factoryRaw(UserRestControllerApiFactory, username, password);
        api.info().then(resp => {
            setFoundError("");
            let user: UserAuth = resp.data;
            authManager.saveCredentials(username, password, user);
            navigate(SELECT_ROLE_URL);
        }, _ => {
            setFoundError("Usuario o password no válidos");
            setFormSent(false);
        })

    };

    useEffect(() => {
        setInvalidData(!(fieldsNotEmpty.username && fieldsNotEmpty.password));
    }, [fieldsNotEmpty]);

    return (
        <LoginLayout title={"Inicio de sesión"}>
            <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label="Nombre de usuario"
                name="username"
                autoComplete={"off"}
                autoFocus
                onChange={(event) => {
                    if (isEmpty(event.target.value)) {
                        setFieldsNotEmpty({
                            ...fieldsNotEmpty,
                            username: false,
                        });
                    } else {
                        setUsername(event.target.value);
                        setFieldsNotEmpty({
                            ...fieldsNotEmpty,
                            username: true,
                        });
                    }
                }}
            />
            <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Contraseña"
                type="password"
                id="password"
                autoComplete={"off"}
                onKeyUp={event => {
                    if (event.key === 'Enter') {
                        sendForm(event);
                    }
                }}
                onChange={(event) => {
                    if (isEmpty(event.target.value)) {
                        setFieldsNotEmpty({
                            ...fieldsNotEmpty,
                            password: false,
                        });
                    } else {
                        setPassword(event.target.value);
                        setFieldsNotEmpty({
                            ...fieldsNotEmpty,
                            password: true,
                        });
                    }
                }}
            />

            <Button
                fullWidth
                variant="contained"
                sx={{mt: 3, mb: 2}}
                onClick={sendForm} disabled={invalidData || formSent}>
                Entrar
            </Button>

            {foundError && (
                <Alert severity="error" sx={{mb: 2}}>Usuario o contraseña incorrectos</Alert>
            )}
        </LoginLayout>
    );
}
