import DataGrid, {Column, SelectColumn, TextEditor} from "react-data-grid";
import {Box, Button, ButtonGroup, Typography} from "@mui/material";
import React, {useContext, useState} from "react";
import {FormContext} from "../form_buttons";
import {H3, Typographies} from "../../../utils/typography_consts";

export interface FormDataGridProps<T> {
    title: string;
    field: string;
    columns: Column<T>[];
    onRemove?: (ids: number[]) => Promise<void>;
    onAppend?: () => Promise<T>;
}

export interface FormDataGridColumn<T> {
    editable?: boolean;
    key: string;
    name: string;
    width: number | string;
}

function FormDataGrid<T>(props: FormDataGridProps<T>) {
    const formContext = useContext(FormContext);
    const model = formContext.form;
    const modelField = model.field(props.field);

    const [lines, setLines] = useState<Array<T>>((modelField.value() as T[]) || []);
    const [selectedLines, setSelectedLines] = useState<ReadonlySet<number>>(() => new Set());

    model.register(props.field, () => {
        return lines;
    }, t => setLines(t));

    const columns = new Array<Column<T>>();
    columns.push(SelectColumn);
    props.columns.forEach((col) =>
        columns.push({
            ...col,
            editor: TextEditor,
        })
    );

    return (
        <>
            <Typography variant={Typographies.h4} component={H3}>
                {props.title}
            </Typography>

            <DataGrid
                rows={lines}
                columns={columns}
                selectedRows={selectedLines}
                onSelectedRowsChange={setSelectedLines}
                onRowsChange={(newRows) => {
                    const newArr = new Array<T>();
                    lines.forEach((oldRow: any) => {
                        const newRow =
                            newRows.find((newRow: any) => newRow["id"] === oldRow["id"]) || oldRow;
                        newArr.push(newRow);
                    });
                    setLines(newArr);
                }}
                rowKeyGetter={(optRow: any) => optRow["id"]}
            />

            <Box my={4}>
                <ButtonGroup>
                    {props.onAppend && (

                            <Button
                                size={"small"}
                                color={"secondary"}
                                variant={"contained"}
                                onClick={() => {
                                    let promise =
                                        typeof props.onAppend === "function"
                                            ? props.onAppend()
                                            : undefined;
                                    if (promise && "then" in promise) {
                                        promise.then((newBean) => {
                                            const newLines = Object.assign([], lines);
                                            newLines.push(newBean);
                                            setLines(newLines);
                                        });
                                    }
                                }}>
                                Añadir fila
                            </Button>

                    )}
                    {props.onRemove && (
                            <Button
                                disabled={!selectedLines.size}
                                size={"small"}
                                color={"secondary"}
                                variant={"contained"}
                                onClick={() => {
                                    if (
                                        confirm("Seguro que desea borrar las líneas seleccionadas?")
                                    ) {
                                        const ids = new Array<number>();
                                        selectedLines.forEach((val) => ids.push(val));
                                        let promise =
                                            typeof props.onRemove === "function"
                                                ? props.onRemove(ids)
                                                : undefined;
                                        if (promise && "then" in promise) {
                                            promise.then(() => {
                                                const newLines = lines.filter(
                                                    (line: any) => !ids.includes(line["id"])
                                                );
                                                setLines(newLines);
                                            });
                                        }
                                    }
                                }}>
                                Borrar seleccionadas
                            </Button>
                    )}
                </ButtonGroup>
            </Box>
        </>
    );
}

export default FormDataGrid;
