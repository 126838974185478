import {Box, Button, Checkbox, FormControlLabel, Grid, TextField, Typography} from "@mui/material";

import React, {useState} from "react";
import {DedicationRestControllerApiFactory} from "arteco-api-client-ts";
import AdminInner from "../../../../../components/layout/admin_inner";
import {H1, Typographies} from "../../../../../components/utils/typography_consts";
import {useApiManager} from "../../../../../components/utils/api";


export const DedicationBatchPage = () => {
	const apiManager = useApiManager();
	const ctrl = apiManager.factory(DedicationRestControllerApiFactory);
	const companyId = apiManager.companyId;

	const [value, setValue] = useState<string>("");
	const [separator, setSeparator] = useState<string>("TAB");
	const [disabled, setDisabled] = useState<boolean>(false);
	const [sent, setSent] = useState<boolean>(true);

	return (
		<>
			<AdminInner>

				<Typography variant={Typographies.h3} component={H1}>
					Creación de dedicaciones por lote
				</Typography>

				<Grid container spacing={2}>
					<Grid item xs={1}>
						<TextField
							sx={{marginTop: 2}}
							variant="filled"
							label={"Separador"}
							type={"text"}
							rows={1}
							multiline={false}
							fullWidth
							autoComplete={"off"}
							value={separator}
							onChange={(event) => {
								const value = event.target.value;
								setSeparator(value);
							}}
						/>
					</Grid>
					<Grid item xs={1}>
						<FormControlLabel sx={{marginTop: 2}}
										  control={<Checkbox checked={sent} onChange={_ => setSent(!sent)}/>}
										  label="Enviadas"/>
					</Grid>
				</Grid>

				<TextField
					sx={{marginTop: 2}}
					variant="filled"
					label={"Una dedicación por línea"}
					type={"text"}
					rows={20}
					multiline={true}
					fullWidth
					autoComplete={"off"}
					value={value}
					onChange={(event) => {
						const value = event.target.value;
						setValue(value);
					}}
				/>

				<Box sx={{my: 1}}>
					<b>Formato:</b><br/>
					<ul>
						<li>
							{`año ${separator} mes ${separator} cliente_id OR name ${separator} manager ${separator} persona_id OR (surname, name) OR @username ${separator}`}
							{` grupoPers ${separator} grupoFact ${separator} concepto ${separator} rol ${separator}`}
							{` tarifa ${separator} horas ${separator} ajuste ${separator} validado`}
						</li>
					</ul>
					<br/>
					<b>Notas:</b><br/>
					<ul>
						<li>
							En los ids se puede buscar por un término, si sólo hay una ocurrencia se usará,
							si no dará error
						</li>
					</ul>

				</Box>

				<Button
					sx={{marginBottom: 1, marginTop: 1}}
					variant={"contained"}
					disabled={disabled}
					onClick={() => {
						setDisabled(true);
						apiManager
						.executeRaw(ctrl.dedicationBatchCreateDedication(companyId, value, separator))
						.then(summary => {
							alert(summary.data)
						})
						.catch(reason => {
							alert(reason);
							setDisabled(false);
						});
					}}>
					Guardar
				</Button>
			</AdminInner>

		</>
	);
}