import React from "react";

import {Parameter, SuperParamRestControllerApiFactory} from "arteco-api-client-ts";

import {FormContent} from "../../../components/form/form_inputs/form_buttons";
import {FormInput} from "../../../components/form/form_inputs/form_input";
import {Grid} from "@mui/material";
import {useForm, UseFormProps} from "../../../components/form/form_model";

import {useApiManager} from "../../../components/utils/api";

export const ParamEditPage = () => {
    const manager = useApiManager();
    const ctrl = manager.factory(SuperParamRestControllerApiFactory);

    const form = useForm({
        fetcher: (id) => ctrl.getParameter(id),
        updater: (bean) => ctrl.saveParameter(bean),
        remover: (id) => ctrl.deleteOrDismissParameter(id),
        factory: () => {
            return {} as Parameter;
        },
    } as UseFormProps<Parameter>);

    return (
        <>
            <FormContent form={form} title={"Parámetros"}>
                <Grid container spacing={3}>
                    <Grid item sm>
                        <FormInput field={"name"} label={"Nombre"} />
                    </Grid>
                    <Grid item sm>
                        <FormInput field={"value"} label={"Valor"} />
                    </Grid>
                </Grid>
            </FormContent>
        </>
    );
};