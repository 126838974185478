import {Link, useLocation} from "react-router-dom";
import React from "react";
import {RoleInfo} from "./index";
import {UserAuthItemTypeEnum} from "arteco-api-client-ts";
import {useAuthManager} from "../../utils/auth";
import {COLOR_VERDE_ARTECO} from "../colors";

export interface NavbarItemProps {
    map: Map<UserAuthItemTypeEnum, RoleInfo>,
    role: UserAuthItemTypeEnum,
}

const NavbarItem = ({role, map}: NavbarItemProps) => {
    const location = useLocation();
    const authManager = useAuthManager();

    const roleInfo = map.get(role);
    const roleInfo_target = roleInfo?.target || "";
    const roleInfo_title = roleInfo?.title || "";
    const roleInfo_admin = roleInfo?.isAdmin || false;

    const userAdminRole = authManager.getAuthorities().find(a => a.type === UserAuthItemTypeEnum.ADMIN);
    const userReqRole = authManager.getAuthorities().find(a => a.type === role);

    const showRoleInfo = userReqRole || (roleInfo_admin && !!userAdminRole);
    const mark = location.pathname.startsWith(roleInfo_target);
    const auth = userReqRole || userAdminRole;

    return (
        <>
            {showRoleInfo && (
                <Link style={{
                    marginRight: "1em",
                    textDecoration: "none",
                    color: mark ? COLOR_VERDE_ARTECO.toString() : "white",
                }}
                      onClick={_ => auth && authManager.saveSelectedAuthority(auth)}
                      to={roleInfo_target}>{roleInfo_title}</Link>
            )}
        </>
    )
}

export default NavbarItem;