import {Box, Grid, Link as MuiLink, Typography} from "@mui/material";
import {H2, PARAGRAPH, SPAN, Typographies} from "../../components/utils/typography_consts";
import {FormInput} from "../../components/form/form_inputs/form_input";
import FormSelect from "../../components/form/form_inputs/form_select";
import {types} from "../../components/form/types";
import FormFile from "../../components/form/form_inputs/form_file";
import FormError from "../../components/form/form_inputs/form_error";
import FormSelectButtons from "../../components/form/form_inputs/form_select_buttons";
import FormToggle from "../../components/form/form_inputs/form_toggle";
import {PRIVACY_URL} from "../../components/utils/urls";
import {FormContent} from "../../components/form/form_inputs/form_buttons";
import React, {useEffect, useState} from "react";
import {
    CandidateForm,
    CandidateFormSkillsList,
    CandidateRequestRestControllerApiFactory,
    Skill,
    SkillLevel,
    TrainingLevel
} from "arteco-api-client-ts";
import {useApiManager} from "../../components/utils/api";
import FormModel from "../../components/form/form_model";
import {CandidateRegion} from "./candidate_region";
import {CandidateSalary} from "./candidate_salary";

export interface PubCandidateFormProps {
    form: FormModel<CandidateForm>;
    mode: "c" | "s";
    candidateForm?: CandidateForm;
}

export const PubCandidateForm = ({form, candidateForm, mode}: PubCandidateFormProps) => {
    const MAX_INTEREST_CHARACTERS = 3000;

    const apiManager = useApiManager();
    const ctrl = apiManager.factory(CandidateRequestRestControllerApiFactory);
    const companyId = apiManager.companyId;

    const [skills, setSkills] = useState<Skill[]>();
    const [skillLevels, setSkillLevels] = useState<SkillLevel[]>();
    const [trainingLevels, setTrainingLevels] = useState<TrainingLevel[]>();
    const [isSpain, setIsSpain] = useState(true);

    useEffect(() => {
        ctrl.getCandidateSkills(companyId).then((response) => {
            let data: CandidateFormSkillsList = response.data;
            setSkills(data.skills);
            setSkillLevels(data.levels);
            setTrainingLevels(data.trainings);
        });
    }, []);

    return (
        <FormContent form={form} title={""} hideMetadata={true} hideTopButtons={true}>
            <Box>
                <Box>
                    <Typography my={2}
                                variant={Typographies.h5}
                                component={H2}>
                        Ficha del candidato
                    </Typography>
                    <Typography
                        variant={Typographies.body2}>
                        {" "}
                        Paso 1 / {mode == "c" ? 3 : 1}{" "}
                    </Typography>
                </Box>

                <Grid container spacing={3}>
                    <Grid item xs>
                        <FormInput field={"name"} label={"Nombre (obligatorio)"}/>
                    </Grid>
                    <Grid item xs>
                        <FormInput
                            field={"surnames"}
                            label={"Apellidos (obligatorio)"}
                        />
                    </Grid>
                </Grid>


                <Grid container spacing={3}>
                    <Grid item xs>
                        <FormInput field={"email"} label={"Email (obligatorio)"}/>
                    </Grid>
                    {mode == "c" && (
                        <>
                            <Grid item xs>
                                <FormInput field={"telephone"} label={"Teléfono"}/>
                            </Grid>
                            <Grid item xs>
                                <FormInput field={"password"} label={"Clave (obligatorio)"}/>
                            </Grid>
                        </>
                    )}
                </Grid>


                <CandidateRegion isSpain={isSpain} setIsSpain={setIsSpain}/>

                <Grid container spacing={3}>
                    <Grid item xs>
                        {trainingLevels && (
                            <FormSelect
                                type={types.trainingLevels}
                                field={"maxTrainingLevel"}
                                label={"Máxima formación académica (obligatorio)"}
                                items={trainingLevels}
                            />
                        )}
                        <FormFile
                            field={"cv"}
                            label={"Currículum (máx 5Mb) " + (mode == "c" ? "(obligatorio)" : "")}
                        />
                        <FormInput field={"linkedin"} label={"Linkedin"}
                                   helperText={mode != "c" ? "Si indica una URL de linkedin no es necesario adjuntar el CV" : undefined}/>
                        {mode == "c" && (
                            <>
                                <FormInput field={"github"} label={"Github"}/>
                                <FormInput field={"website"} label={"Website"}/>
                            </>
                        )}
                    </Grid>
                </Grid>
            </Box>

            {mode == "c" && (
                <Box>
                    <Box>
                        <Typography
                            my={2}
                            variant={Typographies.h5}
                            component={H2}>
                            Habilidades
                            <br/>
                            <Typography
                                variant={Typographies.body1}
                                component={SPAN}>
                                {"*Debes marcar al menos 1 campo en esta sección."}
                            </Typography>
                        </Typography>

                        <Typography
                            variant={Typographies.body2}>
                            Paso 2 /3
                        </Typography>
                    </Box>

                    <FormError field="skills"/>

                    {skills &&
                        skillLevels &&
                        skills.map((skill, skillIdx) => (
                            <FormSelectButtons
                                key={"skill-" + skillIdx}
                                label={skill.label}
                                field={"skills." + skill.id}
                                type={types.skillLevels}
                                items={skillLevels}
                            />
                        ))}
                </Box>
            )}


            <Box>
                {mode == "c" && (
                    <Box my={5}>
                        <Typography
                            variant={Typographies.h5}
                            component={H2}>
                            {" "}
                            Detalles{" "}
                        </Typography>
                        <Typography

                            variant={Typographies.body2}>
                            {" "}
                            Paso 3 / 3{" "}
                        </Typography>
                    </Box>
                )}

                <CandidateSalary mode={mode}/>


                {mode == "c" && (
                    <>
                        <Box sx={{mb: "1rem"}}>
                            <FormSelect field={"workType"} type={types.workTypes} label={"Tipo de jornada"}/>
                        </Box>

                        <Box
                            sx={{
                                "> div": {
                                    mb: "1rem",
                                },
                            }}>
                            <FormInput
                                field={"interest"}
                                label={"Interesado en (obligatorio)"}
                                rows={4}
                                inputProps={{
                                    min: 50,
                                    max: 3000,
                                }}
                                onKeyUp={(e) => {
                                    if (e.target.value.length >= MAX_INTEREST_CHARACTERS) {
                                        e.target.value = e.target.value.substr(
                                            0,
                                            MAX_INTEREST_CHARACTERS
                                        );
                                    }

                                    const interestLengthElem =
                                        document.getElementById("interestLength");
                                    if (interestLengthElem) {
                                        interestLengthElem.innerHTML = e.target.value.length;
                                    }
                                }}
                            />
                        </Box>

                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "flex-start",
                                mb: "2rem",
                            }}>
                            <Typography
                                variant={Typographies.body2}
                                component={PARAGRAPH}>
                                *Min/Max caracteres (50/3000). Contador:{" "}
                                <span id="interestLength">0</span>
                            </Typography>
                        </Box>

                        <Grid container spacing={3} sx={{padding: 3}}>
                            <Typography

                                variant={Typographies.h5}
                                component={H2}>
                                {" "}
                                Configura tus notificaciones{" "}
                            </Typography>
                            <Box>
                                <Grid container>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={3.5}
                                        order={{
                                            xs: 2,
                                            sm: 1,
                                        }}
                                        sx={{
                                            rowGap: {
                                                xs: "1rem",
                                            },
                                        }}>
                                        <FormToggle
                                            field={"receiveEmails"}
                                            label={"Ofertas de empleo"}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={7.5}
                                        order={{
                                            xs: 1,
                                            sm: 2,
                                        }}>
                                        <Typography

                                            variant={Typographies.body1}>
                                            {" "}
                                            Quiero recibir emails sobre oportunidades laborales
                                            que se adapten a mi perfil.{" "}
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid container>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={3.5}
                                        order={{
                                            xs: 2,
                                            sm: 1,
                                        }}
                                        sx={{
                                            rowGap: {
                                                xs: "1rem",
                                            },
                                        }}>
                                        <FormToggle
                                            field={"communityEmails"}
                                            label={"Emails de la comunidad"}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={7.5}
                                        order={{
                                            xs: 1,
                                            sm: 2,
                                        }}>
                                        <Typography

                                            variant={Typographies.body1}>
                                            {" "}
                                            Quiero recibir emails informativos sobre noticias y
                                            eventos de la comunidad IT.{" "}
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid container>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={3.5}
                                        order={{
                                            xs: 2,
                                            sm: 1,
                                        }}
                                        sx={{
                                            rowGap: {
                                                xs: "1rem",
                                            },
                                        }}>
                                        <FormToggle
                                            field={"servicesEmails"}
                                            label={"Emails de //Arteco"}
                                        />
                                    </Grid>

                                    <Grid
                                        item
                                        xs={12}
                                        sm={7.5}
                                        order={{
                                            xs: 1,
                                            sm: 2,
                                        }}>
                                        <Typography

                                            variant={Typographies.body1}>
                                            {" "}
                                            Quiero recibir emails sobre productos, servicios o
                                            formaciones de Arteco Consulting, s.l.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Box>
                            <Typography
                                variant={Typographies.body2}
                                component={PARAGRAPH}>
                                {" "}
                                <strong>
                                    * Al hacer click en guardar, confirmas que aceptas nuestra{" "}
                                </strong>{" "}
                                <MuiLink
                                    href={PRIVACY_URL}>
                                    política de privacidad.{" "}
                                </MuiLink>
                            </Typography>
                        </Box>
                    </>
                )}


            </Box>
        </FormContent>
    )
}