import React from "react";
import {WorkBooking, WorkBookingRestControllerApiFactory} from "arteco-api-client-ts";
import {FormContent} from "../../../../components/form/form_inputs/form_buttons";
import {FormInput} from "../../../../components/form/form_inputs/form_input";
import {useForm, UseFormProps} from "../../../../components/form/form_model";
import {useApiManager} from "../../../../components/utils/api";
import {Grid} from "@mui/material";
import {types} from "../../../../components/form/types";
import FormSelect from "../../../../components/form/form_inputs/form_select";
import FormAutocomplete from "../../../../components/form/form_inputs/form_autocomplete";

export const WorkBookingEditPage = () => {
    const apiManager = useApiManager();
    const ctrl = apiManager.factory(WorkBookingRestControllerApiFactory);
    const companyId = apiManager.companyId;

    const form = useForm({
        fetcher: (id) => ctrl.getWorkBooking(id),
        updater: (bean) => ctrl.saveWorkBooking(bean),
        remover: (id) => ctrl.deleteOrDismissWorkBooking(id),
        factory: () => {
            return { company: { id: companyId } } as any as WorkBooking;
        },
    } as UseFormProps<WorkBooking>);

    return (
        <>
            <FormContent form={form} title={"Ficha de la reserva"}>
                <Grid container spacing={3}>
                    <Grid item sm><FormSelect field={"workCenter"} label={"Centro de Trabajo"} type={types.workCenters}/></Grid>
                    <Grid item sm><FormAutocomplete field={"person"} label={"Persona"} type={types.people} /></Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item sm={3}><FormInput field={"bookingDate"} type={"date"} label={"Fecha de la reserva"}/></Grid>
                    <Grid item sm={3}><FormInput field={"places"} type={"number"} label={"Plazas"}/></Grid>
                </Grid>

            </FormContent>
        </>
    );
};