import React, {useState} from "react";

import {Grid, Typography} from "@mui/material";
import {FormInput} from "../../../../../components/form/form_inputs/form_input";
import {FormContent} from "../../../../../components/form/form_inputs/form_buttons";
import {Person, Salary, SalaryRestControllerApiFactory,} from "arteco-api-client-ts";
import {useForm, UseFormProps,} from "../../../../../components/form/form_model";
import {useApiManager} from "../../../../../components/utils/api";
import {useNavigate, useParams} from "react-router-dom";
import {usePerson} from "../index";
import {parsePathId} from "../../../../../components/form/form_utils";
import FormSelect from "../../../../../components/form/form_inputs/form_select";
import {types} from "../../../../../components/form/types";
import {AxiosResponse} from "axios";

export const SalaryEditPage = () => {
	const params = useParams();
	const personId = parsePathId(params, "personId");
	const manager = useApiManager();
	const ctrl = manager.factory(SalaryRestControllerApiFactory);
	const navigate = useNavigate();

	const [person, setPerson] = usePerson(personId);
	const [showRateWarning, setShowRateWarning] = useState(false);

	const checkRateWarning = (resp: AxiosResponse<Salary>) => {
		const salary = resp.data;
		if (salary.hourlyCost && salary.avgHourRate && (salary.hourlyCost > salary.avgHourRate)) {
			setShowRateWarning(true);
		} else {
			setShowRateWarning(false);
		}
		return resp;
	}

	const form = useForm({
		fetcher: (id) => ctrl.getSalary(id).then(checkRateWarning),
		updater: (bean) => ctrl.saveSalary(bean).then(checkRateWarning),
		remover: (id) => ctrl.deleteOrDismissSalary(id),
		factory: () => {
			return {
				person: {id: personId} as Person,
				vacations: 23,
				pctSegSoc: 0.35,
				hours: {},
			} as Salary;
		},
		extraActions: [
			{
				label: "Duplicar",
				action: () => {
					const id = form.getId();
					if (confirm("Guarde lo cambios antes de duplicar.\n" +
						"La pantalla se posicionará sobre el registro nuevo.\n\n" +
						"¿Seguro que desea duplicar el registro?") && id) {
						manager.executeRaw(ctrl.duplicateSalary(id)).then((resp) => {
							navigate("/app/admin/employee/person/" + personId + "/salary/" + resp.data.id);
							window.location.reload();
						});
					}
				},
			},
		]
	} as UseFormProps<Salary>);

	return (
		<>
			<FormContent
				form={form}
				title={
					"Ficha de Salario para [" +
					(person ? person.surnames + ", " + person.name : "") +
					"]"
				}>

				<Typography mb={1}>CONDICIONES</Typography>
				<Grid container spacing={3}>
					<Grid item sm={3}>
						<FormInput field={"startDate"} type={"date"} label={"Fecha Inicio"}/>
					</Grid>
					<Grid item sm={3}>
						<FormInput field={"annualSalary"} type={"text"} label={"Salario Anual"}/>
					</Grid>
					<Grid item sm={3}>
						<FormInput field={"pctSegSoc"} type={"text"} label={"% Seg Soc."}/>
					</Grid>
					<Grid item sm={3}>
						<FormInput field={"vacations"} type={"number"} label={"Vacaciones"}/>
					</Grid>
				</Grid>

				<Grid container spacing={3} sx={{pb: 2}}>
					<Grid item sm={3}>
						<FormSelect field={"role"} type={types.role} label={"Rol"}/>
					</Grid>
					<Grid item sm={3}>
						<FormSelect field={"roleLevel"} type={types.roleLevel} label={"Nivel del rol"}/>
					</Grid>
					<Grid item sm={3}>
						<FormInput field={"avgHourRate"} type={"text"} label={"Rate €/h"}/>
					</Grid>
					<Grid item sm={3}>
						<FormInput disabled field={"hourlyCost"} type={"text"} label={"Cost €/h"}/>
						{showRateWarning && (
							<small style={{color: "red"}}>
								La tarifa no cubre los gastos del empleado
							</small>
						)}
					</Grid>
				</Grid>

				<Typography mb={1}>HORARIO</Typography>
				<Grid container spacing={3} sx={{pb: 2}}>
					<Grid item sm>
						<FormInput field={"hours.MONDAY"} type={"text"} label={"Lunes"}/>
					</Grid>
					<Grid item sm>
						<FormInput field={"hours.TUESDAY"} type={"text"} label={"Martes"}/>
					</Grid>
					<Grid item sm>
						<FormInput field={"hours.WEDNESDAY"} type={"text"} label={"Miércoles"}/>
					</Grid>
					<Grid item sm>
						<FormInput field={"hours.THURSDAY"} type={"text"} label={"Jueves"}/>
					</Grid>
					<Grid item sm>
						<FormInput field={"hours.FRIDAY"} type={"text"} label={"Viernes"}/>
					</Grid>
				</Grid>

				<FormInput field={"comments"} type={"text"} label={"Comentarios"} rows={3}/>
			</FormContent>
		</>
	);
};