import React, {ReactNode} from "react";
import {Card, CardContent, Grid, Typography} from "@mui/material";

export interface AdminCardProps {
    title: string;
    children: ReactNode;
}

export const AdminCard = ({title, children}: AdminCardProps) => {
    return (
        <Grid item sx={{marginY: 1}} xs={12} sm={6}>
            <Card elevation={3} sx={{width: "100%", height: "100%"}}>
                <CardContent>
                    <Typography variant="h6" component="div">
                        {title}
                    </Typography>
                    {children}
                </CardContent>
            </Card>
        </Grid>

    );
}