import * as React from "react";
import SidebarLayout from "../../components/layout/sidebar_layout";


export const AdminLayout = () => {

    return (
        <SidebarLayout/>
    );
};
