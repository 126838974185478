import {Evaluation, Interview, InterviewRestControllerApiFactory} from "arteco-api-client-ts";
import AppList, {AppListConfig} from "../../../../components/layout/list";
import {useApiManager} from "../../../../components/utils/api";
import {Grid} from "@mui/material";
import {COLOR_DISMISSED} from "../../../../components/layout/colors";
import {useParams} from "react-router-dom";
import {dateToString} from "../../../../components/utils/dates";
import {parsePathId} from "../../../../components/form/form_utils";
import {abbreviate} from "../../../../components/utils/functions";

export const InterviewListPage = () => {
	const apiManager = useApiManager();
	const params = useParams();
	const personId = parsePathId(params, "personId");

	const listConfig: AppListConfig<Evaluation> = {
		title: "Listado de entrevistas",
		onClick: personId
			? "/app/admin/employee/person/" + personId + "/interview"
			: "/app/admin/employee/interview",
		decorator: (bean: Interview) => (
			<Grid container style={{color: bean.dismissed ? COLOR_DISMISSED : "inherit"}}>
				<Grid item sm={2}>
					{dateToString(bean.interviewDate)}
				</Grid>
				<Grid item sm={5}>
					{bean.person.surnames + ", " + bean.person.name}
				</Grid>
				<Grid item sm>
					<small>{abbreviate(bean.privateComments, 40)}</small>
				</Grid>
			</Grid>
		),
		supplier: async (currentPage, size, term, showDismissed) => {
			const companyId = apiManager.companyId;
			const ctrl = apiManager.factory(InterviewRestControllerApiFactory);
			return ctrl.pageInterview(companyId, currentPage, size, undefined, personId,
				undefined, undefined, undefined, term, showDismissed);
		},
	};

	return (
		<>
			<AppList config={listConfig}/>
		</>
	);
};