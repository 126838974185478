import React, {useState} from "react";

import {FormContent} from "../../../components/form/form_inputs/form_buttons";
import {useForm, UseFormProps} from "../../../components/form/form_model";
import {Company, Permission, PermissionRolEnum, SuperUserRestControllerApiFactory, User,} from "arteco-api-client-ts";
import {FormInput} from "../../../components/form/form_inputs/form_input";
import {Button, Grid, Typography} from "@mui/material";
import {useApiManager} from "../../../components/utils/api";
import FormSelect from "../../../components/form/form_inputs/form_select";
import {types} from "../../../components/form/types";
import {H3, Typographies} from "../../../components/utils/typography_consts";
import {useLocation, useNavigate} from "react-router-dom";
import {AxiosResponse} from "axios";

export const UserEditPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const apiManager = useApiManager();
    const ctrl = apiManager.factory(SuperUserRestControllerApiFactory);

    const [permCompany, setPermCompany] = useState<Company>();
    const [permissions, setPermissions] = useState<Permission[]>();
    const [permType, setPermType] = useState<PermissionRolEnum>();

    const form = useForm({
        fetcher: (id) =>
            ctrl.getUser(id).then((res: AxiosResponse<User>) => {
                setPermissions(res.data.permissions || []);
                return res;
            }),
        updater: (bean) => ctrl.saveUser(bean),
        remover: (id) => ctrl.deleteOrDismissUser(id),
        factory: () => {
            return {} as User;
        },
    } as UseFormProps<User>);

    return (
        <>
            <FormContent form={form} title={"Ficha de usuario"}>
                <Grid container spacing={2}>
                    <Grid item sm={6}>
                        <FormInput field={"username"} label={"Nombre de usuario"}/>
                    </Grid>
                    <Grid item sm={6}>
                        <FormInput type={"date"} field={"dismissed"} label={"Eliminado"}/>
                    </Grid>
                </Grid>

                <FormInput field={"email"} type={"email"} label={"Correo electrónico"}/>
                <FormInput
                    field={"passHash"}
                    label={"Hash de clave"}
                    helperText={"Puede cambiar la contraseña si ésta no empieza por '$'"}
                />

                {permissions && (
                    <>
                        <Typography variant={Typographies.h3} component={H3}>
                            Permisos del usuario
                        </Typography>
                        {permissions.map((permission, pIdx) => (
                            <Grid container spacing={3} key={pIdx}>
                                <Grid item sm>
                                    {permission.company?.name}
                                </Grid>
                                <Grid item sm>
                                    {permission.rol}
                                </Grid>
                                <Grid item sm>
                                    <Button
                                        variant={"contained"}
                                        size={"small"}
                                        onClick={() => {
                                            if (
                                                permission.id &&
                                                confirm("Seguro que deseas quitar el permiso?")
                                            ) {
                                                apiManager
                                                    .execute(ctrl.deletePermissionUser(permission.id))
                                                    .then(() => navigate(location.pathname));
                                            }
                                        }}>
                                        Quitar
                                    </Button>
                                </Grid>
                            </Grid>
                        ))}
                        <Grid container spacing={3}>
                            <Grid item sm>
                                <FormSelect
                                    field={"permissionSelectCompany"}
                                    label={"Empresa"}
                                    type={types.companies}
                                    onChange={(item) => {
                                        setPermCompany(item);
                                    }}
                                />
                            </Grid>
                            <Grid item sm>
                                <FormSelect
                                    field={"permissionSelectRole"}
                                    label={"Rol"}
                                    type={types.userPermission}
                                    onChange={(item) => {
                                        setPermType(
                                            item
                                                ? (types.userTypes.valuer(
                                                    item
                                                ) as PermissionRolEnum)
                                                : undefined
                                        );
                                    }}
                                />
                            </Grid>
                            <Grid item sm>
                                <Button
                                    variant={"contained"}
                                    style={{marginTop: "8px"}}
                                    size={"small"}
                                    onClick={() => {
                                        if (permType && permCompany) {
                                            permissions.push({
                                                user: {id: form.getIdOrErr()} as User,
                                                company: permCompany,
                                                rol: permType,
                                            } as Permission);
                                            form.save().then(() => navigate(location.pathname));
                                        }
                                    }}>
                                    Añadir
                                </Button>
                            </Grid>
                        </Grid>
                    </>
                )}
            </FormContent>
        </>
    );
};