import {Chip, Grid, ListItemText} from "@mui/material";
import AppList, {AppListConfig} from "../../../components/layout/list";
import {EmployeeRestControllerApiFactory, Payroll} from "arteco-api-client-ts";
import {useApiManager} from "../../../components/utils/api";
import {dateFromToString} from "../../../components/utils/dates";
import {numberToString} from "../../../components/utils/numbers";
import {fileLocalSave} from "../../../components/form/form_inputs/form_file";

export const EmployeePayrollPage = () => {
    const apiManager = useApiManager();
    const employeeApi = apiManager.factory(EmployeeRestControllerApiFactory);

    const listConfig: AppListConfig<Payroll> = {
        title: "Listado de nóminas",
        onClick: (payroll) => {
            if (!payroll.id) throw new Error("No payroll id");
            employeeApi.viewEmployeePayroll(payroll.id).then((resp) => fileLocalSave(resp));
        },
        decorator: (bean: Payroll) => (
            <ListItemText
                primary={"Período de " + dateFromToString(bean.periodFrom) + " a " + dateFromToString(bean.periodTo)}
                secondary={<>
                    Coste empresa: {numberToString(bean.costeEmpresa)}
                    <Chip sx={{float:"right",top:"-1em", position:"relative"}} label={numberToString(bean.totalLiquido)} />
                </>}>
            </ListItemText>
        ),
        supplier: async (currentPage, size, term, showDismissed) => {
            return employeeApi.getEmployeePayrolls(currentPage, undefined, undefined);
        },
    };

    return (
        <>
            <AppList config={listConfig} searchable={false} appendable={false}/>
        </>
    );
};