import React from "react";
import {CandidateState, CandidateStateRestControllerApiFactory} from "arteco-api-client-ts";
import {FormContent} from "../../../../components/form/form_inputs/form_buttons";
import {FormInput} from "../../../../components/form/form_inputs/form_input";
import {useForm, UseFormProps} from "../../../../components/form/form_model";
import {useApiManager} from "../../../../components/utils/api";
import {Grid} from "@mui/material";
import FormToggle from "../../../../components/form/form_inputs/form_toggle";


export const CandidateStateEditPage = () => {
    const apiManager = useApiManager();
    const ctrl = apiManager.factory(CandidateStateRestControllerApiFactory);
    const companyId = apiManager.companyId;


    const form = useForm({
        fetcher: (id) => ctrl.getCandidateState(id),
        updater: (bean) => ctrl.saveCandidateState(bean),
        remover: (id) => ctrl.deleteOrDismissCandidateState(id),
        factory: () => {
            return {company: {id: companyId}} as CandidateState;
        },

    } as UseFormProps<CandidateState>);

    return (
        <>
            <FormContent form={form} title={"Formulario de Estado de Candidato"}>

                <Grid container spacing={3}>
                    <Grid item sm>
                        <FormInput type={"text"} field={"name"} label={"Nombre"}/>
                    </Grid>
                    <Grid item sm={3}>
                        <FormInput type={"text"} field={"position"} label={"Posición"}/>
                    </Grid>
                    <Grid item sm={2}>
                        <FormToggle field={"defaultState"} label={"Inicial"}/>
                    </Grid>
                </Grid>

                <FormInput type={"text"} field={"description"}
                           label={"Descripción"} rows={10}/>

            </FormContent>
        </>
    );
};