import React from "react";
import {CandidateSource, CandidateSourceRestControllerApiFactory,} from "arteco-api-client-ts";

import {FormContent} from "../../../../components/form/form_inputs/form_buttons";
import {FormInput} from "../../../../components/form/form_inputs/form_input";
import {Grid} from "@mui/material";
import {useForm, UseFormProps} from "../../../../components/form/form_model";
import {useApiManager} from "../../../../components/utils/api";

export const CandidateSourceEditPage = () => {
    const apiManager = useApiManager();
    const ctrl = apiManager.factory(CandidateSourceRestControllerApiFactory);

    const form = useForm({
        fetcher: (id) => ctrl.getCandidateSource(id),
        updater: (bean) => ctrl.saveCandidateSource(bean),
        remover: (id) => ctrl.deleteOrDismissCandidateSource(id),
        factory: () => {
            return {
                company: {
                    id: apiManager.companyId,
                },
            } as CandidateSource;
        },
    } as UseFormProps<CandidateSource>);

    return (
        <>
            <FormContent form={form} title={"Formulario de orígenes de candidatos"}>
                <Grid container spacing={3}>
                    <Grid item sm>
                        <FormInput type={"text"} field={"label"} label={"Etiqueta"}/>
                    </Grid>
                    <Grid item sm>
                        <FormInput field={"value"} type={"text"} label={"Valor"}/>
                    </Grid>
                </Grid>

                <FormInput field={"description"} type={"text"} label={"Descripción"} rows={2}/>
            </FormContent>
        </>
    );
};