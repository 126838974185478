import {Box, Typography} from "@mui/material";
import {Typographies} from "../components/utils/typography_consts";
import React from "react";
import AdminFooter from "../components/layout/footer";
import {Link} from "react-router-dom";

const ERROR_404_IMG = "/img/errors/error_404.jpg";

const NotFoundPage = () => {
    return (
        <>
            <Box sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}>

                <Typography variant={Typographies.h2} sx={{textAlign: "center"}} color="primary">
                    What are you looking for?
                </Typography>
                <Link to={"/"}>
                    <img style={{marginTop: "2em"}}
                         width={600}
                         src={ERROR_404_IMG}
                         alt="Imagen de ejemplo seleccionada de las páginas del libro"/>
                </Link>
            </Box>
            <AdminFooter></AdminFooter>
        </>
    );
};

export default NotFoundPage;