import {Typography} from "@mui/material";
import AdminInner from "../../../components/layout/admin_inner";
import {H1, Typographies} from "../../../components/utils/typography_consts";

export const AdminAdminPage = () => {
    return (
        <AdminInner>

            <Typography variant={Typographies.h3} component={H1}>
                Bienvenido al Módulo de Admin
            </Typography>
            <Typography variant={"body1"}>
                Selecciona una opción del menú lateral para comenzar
            </Typography>

        </AdminInner>
    );
};