import {Parameter, SuperParamRestControllerApiFactory} from "arteco-api-client-ts";
import AppList, {AppListConfig} from "../../../components/layout/list";
import {Grid} from "@mui/material";
import {useApiManager} from "../../../components/utils/api";

export const ParamListPage = () => {
    const apiManager = useApiManager();

    const listConfig: AppListConfig<Parameter> = {
        title: "Parámetros ",
        onClick: "/app/admin/super/param",
        decorator: (bean: Parameter) => (
            <Grid container >
                <Grid item sm>
                    {bean.name}
                </Grid>
                <Grid item sm>
                    {bean.value?.substr(0, 30)}...
                </Grid>
            </Grid>
        ),
        supplier: async (currentPage, size, term, showDismissed) => {
            const ctrl = apiManager.factory(SuperParamRestControllerApiFactory);
            return ctrl.pageParameter(currentPage, size, undefined, term, showDismissed);
        },
    };

    return (
        <>
            <AppList config={listConfig} />
        </>
    );
};