export const COLOR_VERDE_ARTECO = "#29DEBA";
export const COLOR_VERDE_ARTECO_CLARO = "rgba(41, 222, 186, 0.26)";
export const COLOR_AZUL_OSCURO = "#26364D";
export const COLOR_AZUL_ELECTRICO = "#2445B8";
export const COLOR_GRIS = "#C4C4C4";
export const COLOR_GRIS_CLARO = "#E4E4E4";
export const COLOR_DISMISSED = "#B4B4B4";
export const COLOR_BLANCO = "#FFF";
export const COLOR_NEGRO = "#000";
export const COLOR_ROJO = "#FF0000";
export const COLOR_ROJO_CLARO = "#EF6664";

