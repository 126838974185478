import {useApiManager} from "../../../../components/utils/api";

import React, {useEffect, useState} from "react";

import {Box, List, ListItem, Tab} from "@mui/material";
import AdminInner from "../../../../components/layout/admin_inner";
import "./occupation.module.scss";
import {loadAndProcessOccupation, OccupationProcessed} from "./occupation_utils";
import {TabContext, TabList, TabPanel} from "@mui/lab";

import {DedicationSummary} from "./dedication_summary";
import {OccupationIndividual} from "./occupation_individual";
import {useLocation, useParams, useSearchParams} from "react-router-dom";


export const OccupationPage = () => {
    const apiManager = useApiManager();
    const [searchParams, setSearchParams] = useSearchParams();

    const [selectedMainTab, setSelectedMainTab] = useState<string>("group");
    const [occupation, setOccupation] = useState<OccupationProcessed | undefined>();
    const [selectedMonth, setSelectedMonth] = useState<string>((new Date().getMonth() + 1).toString());
    const year = parseInt(searchParams.get("year") || "0") || new Date().getFullYear();

    useEffect(() => {
        loadAndProcessOccupation(apiManager, year)
            .then(occupation => setOccupation(occupation));
    }, []);


    const changeMonth = (month: string): void => {
        setSelectedMonth(month);
    }


    return (
        <>
            <AdminInner title={"Cuadro de ocupación " + year}>

                {!occupation &&
                    <>Loading....</>
                }
                {occupation && (
                    <>
                        <List style={{display: "flex", flexDirection: "row", padding: 0}}>
                            {occupation.months.map((month, yIdx) => (
                                <ListItem
                                    key={yIdx}
                                    button
                                    divider
                                    selected={selectedMonth == month}
                                    color={"default"}
                                    onClick={() => changeMonth(month)}>
                                    {month}
                                </ListItem>
                            ))}
                        </List>


                        <TabContext value={selectedMainTab}>
                            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                                <TabList onChange={(event: React.SyntheticEvent, newValue: string) => {
                                    setSelectedMainTab(newValue);
                                }}>
                                    <Tab label="Grupal" value="group"/>
                                    <Tab label="Individual" value="individual"/>
                                </TabList>
                            </Box>
                            <TabPanel sx={{p: 0, minHeight: "100%"}} value="group">
                                <DedicationSummary occupation={occupation} year={year} month={selectedMonth}/>
                            </TabPanel>
                            <TabPanel sx={{p: 0, minHeight: "100%"}} value="individual">
                                <OccupationIndividual occupation={occupation} year={year} month={selectedMonth}/>
                            </TabPanel>
                        </TabContext>

                    </>
                )}

            </AdminInner>
        </>
    );
};