import React, {useState} from "react";
import {Person, PersonRestControllerApiFactory} from "arteco-api-client-ts";
import {FormContent} from "../../../../components/form/form_inputs/form_buttons";
import {FormInput} from "../../../../components/form/form_inputs/form_input";
import {Box, Grid, Typography} from "@mui/material";
import {useForm, UseFormProps} from "../../../../components/form/form_model";
import {useApiManager} from "../../../../components/utils/api";
import {EmployeeDataFields} from "../../../../components/layout/shared/person/employee_data";
import {H3, Typographies} from "../../../../components/utils/typography_consts";
import {useNavigate} from "react-router-dom";
import {AxiosResponse} from "axios";
import FormAutocomplete from "../../../../components/form/form_inputs/form_autocomplete";
import {types} from "../../../../components/form/types";
import FormToggle from "../../../../components/form/form_inputs/form_toggle";
import {PersonSummaryAlert} from "./summary";
import FormSelect from "../../../../components/form/form_inputs/form_select";

export const PersonEditPage = () => {
    const navigate = useNavigate();
    const apiManager = useApiManager();
    const ctrl = apiManager.factory(PersonRestControllerApiFactory);
    const companyId = apiManager.companyId;

    const [photoResponse, setPhotoResponse] = useState<AxiosResponse<string[]> | undefined>(undefined);

    const updatePhotoImg = (resp: AxiosResponse<Person>) => {
        if (resp.data && resp.data.id && resp.data.photo?.id) {
                apiManager
                    .executeRaw(ctrl.viewPhotoPerson(resp.data.id))
                    .then(p => setPhotoResponse(p));
        } else {
            setPhotoResponse(undefined);
        }
        return resp;
    }

    const form = useForm({
        fetcher: (id) => ctrl.getPerson(id).then(updatePhotoImg),
        updater: (bean) => ctrl.savePerson(bean).then(updatePhotoImg),
        remover: (id) => ctrl.deleteOrDismissPerson(id),
        factory: () => {
            return {company: {id: companyId}} as Person;
        },
        extraActions: [
            {
                label: "Salarios",
                action: () => {
                    form.withId(id => navigate("/app/admin/employee/person/" + id + "/salary"))
                },
            },
            {
                label: "Ausencias",
                action: () => {
                    form.withId(id => navigate("/app/admin/employee/vacations?personId=" + id));
                },
            },
            {
                label: "Archivos",
                action: () => {
                    form.withId(id => navigate("/app/admin/employee/person/" + id + "/document"))
                },
            },
            {
                label: "Eval Interna",
                action: () => {
                    form.withId(id => navigate("/app/admin/employee/person/" + id + "/evaluation"));
                },
            },
            {
                label: "Eval Externa",
                action: () => {
                    form.withId(id => navigate("/app/admin/employee/person/" + id + "/support"));
                },
            },
            {
                label: "Eventos",
                action: () => {
                    form.withId(id => navigate("/app/admin/employee/person/" + id + "/event"));
                },
            },
        ],
        idParamName: "personId"
    } as UseFormProps<Person>);

    return (
        <>
            <FormContent form={form} title={"Formulario de Persona"}>
                <EmployeeDataFields photoDownloader={() => ctrl.viewPhotoPerson(form.getIdOrErr())}/>

                <Box my={5}>
                    <Typography variant={Typographies.h3} component={H3} pb={3}>
                        Datos privados
                    </Typography>

                    <FormInput type={"text"} field={"help"}
                               label={"Ayuda para listado"} rows={1}/>


                    <Grid container spacing={3}>
                        <Grid item sm={2}><FormSelect field={"calendar"} type={types.calendars} label={"Calendario"} /></Grid>
                        <Grid item sm={4}><FormAutocomplete field={"manager"} label={"Manager"}
                                                            type={types.people}/></Grid>
                        <Grid item sm={2}><FormInput field={"testEndDate"} type={"date"} label={"Fin pruebas"}/></Grid>
                        <Grid item sm={2}><FormInput field={"deductionEndDate"} type={"date"} label={"Fin de I+D+i"}/></Grid>
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid item sm={2}><FormToggle field={"skipEvaluation"} label={"No evaluar"}/></Grid>
                        <Grid item sm={2}><FormToggle field={"skipMonthReminder"} label={"No aviso mensual"}/></Grid>
                        <Grid item sm={2}><FormToggle field={"inSocialSecurityRebate"} label={"Bonificable I+D+i"}/></Grid>
                        <Grid item sm={3}><FormToggle field={"inRiskPreventionPlan"} label={"En Plan de Prevención"}/></Grid>
                    </Grid>

                    <Grid container spacing={3}>

                        <Grid item sm={3}><FormInput field={"developerStartDate"} type={"date"} label={"Programando desde"}/></Grid>
                        <Grid item sm={3}><FormInput field={"preventionTrainingDate"} type={"date"} label={"Última formación en Prev."}/></Grid>
                        <Grid item sm={3}><FormInput field={"jobEvaluationDate"} type={"date"} label={"Última evaluación del Puesto"}/></Grid>
                    </Grid>

                    <FormInput type={"text"} field={"observation"}
                               label={"Comentarios privados"} rows={7}/>

                    <Grid container spacing={3}>

                        <Grid item sm={6}>
                            <FormInput type={"number"} field={"hiringCost"}
                                       label={"Costes de alta"} rows={1}/>

                            <FormInput type={"text"} field={"hiringCostDesc"}
                                       label={"Descripción de costes de alta"} rows={3}/>
                        </Grid>

                        <Grid item sm={6}>
                            <FormInput type={"number"} field={"monthlyCost"}
                                       label={"Costes mensuales"} rows={1}/>

                            <FormInput type={"text"} field={"monthlyCostDesc"}
                                       label={"Descripción de costes mensuales"} rows={3}/>
                        </Grid>
                        <Grid item sm={6}>

                        </Grid>
                    </Grid>

                    <PersonSummaryAlert personId={form.propModel.value?.id} showPersonData={false}/>
                </Box>
            </FormContent>
        </>
    );
};