import {Payroll, PayrollRestControllerApiFactory} from "arteco-api-client-ts";
import AppList, {AppListConfig} from "../../../../components/layout/list";
import {Grid} from "@mui/material";
import {dateFromToString} from "../../../../components/utils/dates";
import {useApiManager} from "../../../../components/utils/api";
import {numberToString} from "../../../../components/utils/numbers";

export const PayrollListPage = () => {
    const apiManager = useApiManager();

    const listConfig: AppListConfig<Payroll> = {
        title: "Nóminas ",
        onClick: "/app/admin/employee/payroll",
        decorator: (bean: Payroll) => (
            <Grid container>
                <Grid item sm={4}>
                    {dateFromToString(bean.periodFrom)} - {dateFromToString(bean.periodTo)}
                </Grid>
                <Grid item sm>
                    {bean.person.surnames}, {bean.person.name}
                </Grid>
                <Grid item sm>
                    {bean.person.id!=236 && (
                        <>
                            {numberToString(bean.costeEmpresa)}
                        </>
                    )}
                </Grid>
            </Grid>
        ),
        supplier: async (currentPage, size, term, showDismissed) => {
            const companyId = apiManager.companyId;
            const ctrl = apiManager.factory(PayrollRestControllerApiFactory);
            return ctrl.pagePayroll(companyId, currentPage, size, undefined, term, showDismissed);
        },
    };

    return (
        <>
            <AppList config={listConfig} />
        </>
    );
};