import React, {FormEvent, useEffect, useState} from "react";
import {useApiManager} from "../components/utils/api";
import {UserAuth, UserRestControllerApiFactory} from "arteco-api-client-ts";
import {Alert, Button, TextField} from "@mui/material";
import {isEmpty} from "../components/utils/functions";
import {useSearchParams} from "react-router-dom";
import {AxiosResponse} from "axios";
import {LoginLayout} from "../components/layout/login_layout";

export default function Redefine() {
    const apiManager = useApiManager();
    const ctrl = apiManager.factory(UserRestControllerApiFactory);

    const [searchParams, setSearchParams] = useSearchParams();
    const token = searchParams.get("token");

    const [password, setPassword] = useState("");
    const [passwordAlt, setPasswordAlt] = useState("");

    const [fieldsNotEmpty, setFieldsNotEmpty] = useState({
        password: false,
        passwordAlt: false,
    });
    const [foundError, setFoundError] = useState(false);
    const [invalidData, setInvalidData] = useState(true);
    const [formSent, setFormSent] = useState(false);
    const [emailSent, setEmailSent] = useState(false);

    const sendForm = (event: FormEvent) => {
        event.preventDefault();
        if (password === passwordAlt && token) {
            setFormSent(true);
            ctrl.redefine(token, password)
                .then((resp: AxiosResponse<UserAuth>) => {
                    setFoundError(false);
                    setEmailSent(true);
                })
                .catch((err: any) => {
                    setFoundError(true);
                    setFormSent(false);
                });
        } else {
            setFoundError(true);
        }
    };

    useEffect(() => {
        setInvalidData(!(fieldsNotEmpty.password));
    }, [fieldsNotEmpty]);

    return (
        <LoginLayout title={"Indica la nueva contraseña"}>
            <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Contraseña"
                type="password"
                id="password"
                autoComplete={"off"}
                onChange={(event) => {
                    if (isEmpty(event.target.value)) {
                        setFieldsNotEmpty({
                            ...fieldsNotEmpty,
                            password: false,
                        });
                    } else {
                        setPassword(event.target.value);
                        setFieldsNotEmpty({
                            ...fieldsNotEmpty,
                            password: true,
                        });
                    }
                }}
            />

            <TextField
                margin="normal"
                required
                fullWidth
                name="passwordAlt"
                label="Contraseña (repetición)"
                type="password"
                id="passwordAlt"
                autoComplete={"off"}
                onChange={(event) => {
                    if (isEmpty(event.target.value)) {
                        setFieldsNotEmpty({
                            ...fieldsNotEmpty,
                            passwordAlt: false,
                        });
                    } else {
                        setPasswordAlt(event.target.value);
                        setFieldsNotEmpty({
                            ...fieldsNotEmpty,
                            passwordAlt: true,
                        });
                    }
                }}
            />

            <Button
                fullWidth
                variant="contained"
                sx={{mt: 3, mb: 2}}
                onClick={sendForm} disabled={invalidData || formSent}>
                Enviar
            </Button>

            {foundError && (
                <Alert severity="error" sx={{mb: 2}}>Compruebe que las contraseñas son iguales</Alert>
            )}
            {emailSent && (
                <Alert severity="success" sx={{mb: 2}}>Se ha modificado la contraseña correctamente</Alert>
            )}
        </LoginLayout>
    );
}
