import React from "react";
import {RedmineMapping, RedmineMappingRestControllerApiFactory} from "arteco-api-client-ts";
import {FormContent} from "../../../../components/form/form_inputs/form_buttons";
import {FormInput} from "../../../../components/form/form_inputs/form_input";
import {useForm, UseFormProps} from "../../../../components/form/form_model";
import {useApiManager} from "../../../../components/utils/api";

export const RedmineMappingEditPage = () => {
    const manager = useApiManager();
    const ctrl = manager.factory(RedmineMappingRestControllerApiFactory);
    const companyId = manager.companyId;

    const form = useForm({
        fetcher: (id) => ctrl.getRedmineMapping(id),
        updater: (bean) => ctrl.saveRedmineMapping(bean),
        remover: (id) => ctrl.deleteOrDismissRedmineMapping(id),
        factory: () => {
            return {
                company: {
                    id: companyId,
                }
            } as RedmineMapping;
        },
    } as UseFormProps<RedmineMapping>);

    return (
        <>
            <FormContent form={form} title={"Formulario de mapeo"}>

                <FormInput field={"category"} label={"Categoria"}/>

                <FormInput field={"value"} label={"Valor"}/>

                <FormInput field={"redmineValue"} label={"Valor en Redmine"}/>

            </FormContent>
        </>
    );
};
