import {
    Grid,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Switch, Table, TableBody,
    TableCell, TableHead,
    TableRow,
    TextField,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {AxiosPromise} from "axios";
import AdminInner from "../admin_inner";
import AdminTitle from "../admin_title";
import {useApiManager} from "../../utils/api";
import {useLocation, useNavigate} from "react-router-dom";
import {FormButtonProps} from "../../form/form_inputs/form_button";
import ListButtons from "./list_buttons";
import styles from "./list.module.scss";
import {COLOR_DISMISSED} from "../colors";

export interface AppPage<T> {
    content: T[];
    number: number;
    totalPages: number;
    size: number;
}

export interface AppListConfig<T> {
    title?: string;
    onClick: string | ((bean: T, idx?: number) => void);
    actions?: FormButtonProps[];
    headers?: ListHeader[];

    decorator(bean: T, idx: number): React.ReactNode;

    supplier(
        currentPage: number | undefined,
        size: number | undefined,
        term: string,
        showDismissed: boolean
    ): AxiosPromise;
}

export interface ListHeader {
    title: string;
    align?: "left" | "right" | "center";
}

interface AppListBodyProps<T> {
    config: AppListConfig<T>;
    trigger?: number;
    enabled?: boolean;
    searchable?: boolean;
    appendable?: boolean;
    paginable?: boolean;
    onAppend?: () => void;
}

export interface AppListConfProps<T> extends AppListBodyProps<T> {
    inner?: boolean;
}


const memory = new Map<string, any>();

function AppList<T>({
                        config,
                        trigger,
                        enabled = true,
                        searchable = true,
                        appendable = true,
                        paginable = true,
                        onAppend,
                        inner = true,
                    }: AppListConfProps<T>) {

    return (
        <>
            {inner && (
                <AdminInner>
                    <AppListBody config={config}
                                 trigger={trigger}
                                 searchable={searchable}
                                 appendable={appendable}
                                 paginable={paginable}
                                 enabled={enabled}
                                 onAppend={onAppend}/>
                </AdminInner>
            )}
            {!inner && (
                <AppListBody config={config}
                             trigger={trigger}
                             searchable={searchable}
                             appendable={appendable}
                             paginable={paginable}
                             enabled={enabled}
                             onAppend={onAppend}/>
            )}
        </>

    );
}

export default AppList;


function AppListBody<T>({
                            config,
                            trigger,
                            enabled,
                            searchable,
                            appendable,
                            paginable,
                            onAppend,
                        }: AppListBodyProps<T>) {

    const {decorator, title, supplier, onClick, actions} = config;

    const navigate = useNavigate();
    const location = useLocation();

    const apiManager = useApiManager();

    let initCurrentPage = 0;
    let initSize = 20;
    let initTerm = "";
    let initShowDismissed = false;
    if (memory.has(location.pathname)) {
        const params = memory.get(location.pathname);
        initCurrentPage = params.currentPage;
        initSize = params.size;
        initTerm = params.term;
        initShowDismissed = params.showDismissed;
    }

    const [contentFetch, setContentFetch] = useState<T[]>();
    const [totalPages, setTotalPages] = useState<number | undefined>(0);
    const [currentPage, setCurrentPage] = useState<number | undefined>(initCurrentPage);
    const [size, setSize] = useState<number | undefined>(initSize);
    const [term, setTerm] = useState<string>(initTerm);
    const [showDismissed, setShowDismissed] = useState<boolean>(initShowDismissed);

    useEffect(() => {
        if (enabled) {
            apiManager
                .execute(supplier(currentPage, size, term, showDismissed))
                .then((data: AppPage<T>) => {
                    memory.set(location.pathname, {currentPage, size, term, showDismissed});
                    setContentFetch(data.content);
                    setTotalPages(data.totalPages);
                    //setCurrentPage(data.number);
                    //setSize(data.size);
                });
        }
    }, [currentPage, showDismissed, term, size, trigger, enabled]);

    const clickHandler = (event: any, value: any, idx: number) => {
        let newWindowReq = false;

        if (event.button == 1 || event.shiftKey || event.metaKey || event.altKey) {
            newWindowReq = true;
        }
        if (typeof onClick === "string") {
            const url = `${onClick}/${value["id"]}`;
            if (newWindowReq) {
                window.open(url, "_blank");
            } else {
                navigate(url);
            }
        } else if (typeof onClick === "function") {
            onClick(value, idx);
        } else {
            throw (
                "Type of detailsUrl is unknown " + JSON.stringify(onClick)
            );
        }
    }

    return (
        <>
            <Grid container>
                {title &&
                    <Grid item xs={7}>
                        <AdminTitle title={title}/>
                    </Grid>
                }
                {searchable && (
                    <>
                        <Grid item xs style={{textAlign: "right"}}>
                            <Switch
                                checked={showDismissed}
                                onChange={(event) => {
                                    setCurrentPage(0);
                                    setShowDismissed(event.target.checked);
                                }}
                                name="showDismissed"
                                inputProps={{"aria-label": "Mostrar eliminados"}}
                            />
                        </Grid>
                        <Grid item xs={3} style={{textAlign: "right"}}>
                            <TextField
                                placeholder="Buscar"
                                color="secondary"
                                variant={"standard"}
                                size={"small"}
                                value={term}
                                onChange={(event) => {
                                    setCurrentPage(0);
                                    setTerm(event.target.value);
                                }}
                            />
                        </Grid>
                    </>
                )}
            </Grid>

            {paginable &&
                <ListButtons
                    onClick={onClick}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    totalPages={totalPages}
                    actions={actions}
                    appendable={appendable}
                    onAppend={onAppend}/>
            }

            {!config.headers && (
                <List dense className={styles.list} component={"div"}>
                    {contentFetch != null &&
                        contentFetch.map((value: any, idx) => (
                            <ListItemButton
                                dense
                                key={idx + "list-item"}
                                className={styles.listitem}
                                onClick={(event) => clickHandler(event, value, idx)}>
                                {decorator(value, idx)}
                            </ListItemButton>
                        ))}
                    {!contentFetch && (
                        <ListItemButton>
                            <ListItemText primary={"Cargando..."}/>
                        </ListItemButton>
                    )}
                    {contentFetch != null && contentFetch.length == 0 && (
                        <ListItemButton>
                            <ListItemText primary={"No hay registros"}/>
                        </ListItemButton>
                    )}
                </List>
            )}

            {config.headers && config.headers.length && (
                <Table className={styles.list}>
                    <TableHead>
                        <TableRow>
                            {config.headers.map((h, i) => (
                                <TableCell key={"h-" + i} align={h.align}>{h.title}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {contentFetch != null &&
                            contentFetch.map((value: any, idx) =>
                                <TableRow onClick={(event) => clickHandler(event, value, idx)}
                                          sx={{cursor: "pointer"}}
                                          key={idx + "list-item"}
                                          className={styles.listitem}
                                          style={{color: value.dismissed ? COLOR_DISMISSED : ""}}>
                                    {decorator(value, idx)}
                                </TableRow>
                            )}
                        {!contentFetch && (
                            <TableRow>
                                <TableCell colSpan={100}>Cargando...</TableCell>
                            </TableRow>
                        )}
                        {contentFetch != null && contentFetch.length == 0 && (
                            <TableRow>
                                <TableCell colSpan={100}>No hay registros</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            )}

            {paginable &&
                <ListButtons
                    onClick={onClick}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    totalPages={totalPages}
                    actions={actions}
                    appendable={appendable}
                    onAppend={onAppend}/>
            }
        </>
    )
}