import React, {ReactNode} from "react";
import {Box, Paper} from "@mui/material";

import AdminTitle from "./admin_title";

export interface AdminInnerProps extends AdminInnerSimpleProps {
    title?: string;
    withoutPaper?: boolean;
    children: ReactNode;
    elevation?: number
    padding?: number
}

const AdminInner = ({title, children, withoutPaper = false, elevation, padding}: AdminInnerProps) => {
    return (
        <>
            {!withoutPaper && (
                <Paper elevation={elevation} sx={{p: 3, minHeight: "70vh"}}>
                    <AdminInnerSimple title={title} padding={padding} children={children}/>
                </Paper>
            )}
            {withoutPaper && (
                <>
                    <AdminInnerSimple title={title} padding={padding} children={children}/>
                </>
            )}
        </>
    );
};

export default AdminInner;

interface AdminInnerSimpleProps {
    title?: string;
    children: ReactNode;
    padding?: number
}

const AdminInnerSimple = ({title, padding, children}: AdminInnerSimpleProps) => {
    return (
        <Box p={padding} sx={{minHeight:"100%"}}>
            {title && <AdminTitle title={title}/>}
            <Box sx={{minHeight:"100%"}}>{children}</Box>
        </Box>
    );
}