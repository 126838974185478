import * as React from "react";
import {Box, Container, Paper, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import {HOME_URL} from "../utils/urls";
import AdminFooter from "./footer";


export interface LoginLayoutProps {
    children?: React.ReactNode;
    title: string
}

export const LoginLayout = ({children, title}: LoginLayoutProps) => {
    return (

        <Container maxWidth={"sm"}>

            <Paper elevation={3} sx={{p: 4, mt:6}}>

                <Box sx={{mt: 6, textAlign:"center"}}>
                    <Link to={HOME_URL}>
                        <img width="200" src="/img/logo_azul.png" alt="Arteco logo"/>
                    </Link>
                </Box>
                <Box sx={{textAlign:"center"}}>
                    <Typography component="h1" variant="h5" sx={{my: 3, textTransform:"uppercase"}}>
                        {title}
                    </Typography>
                </Box>
                <Box component="form" noValidate sx={{mt: 1}}>
                    {children}
                </Box>
                <Box>
                    <Link to="/login" style={{marginRight:"1em"}}>
                        Acceder
                    </Link>
                    <Link to="/recover" style={{marginRight:"1em"}}>
                        Recuperar
                    </Link>
                    <Link to="/signup" style={{marginRight:"1em"}}>
                        Registrarse
                    </Link>
                </Box>

            </Paper>

            <AdminFooter/>
        </Container>

    )
}