import React, {useEffect, useState} from "react";
import {useApiManager} from "../components/utils/api";
import {UserAuth, UserRestControllerApiFactory} from "arteco-api-client-ts";
import {Alert} from "@mui/material";
import {useAuthManager} from "../components/utils/auth";
import {useNavigate, useSearchParams} from "react-router-dom";
import {AxiosResponse} from "axios";
import {LoginLayout} from "../components/layout/login_layout";

export default function Validate() {
    const apiManager = useApiManager();
    const ctrl = apiManager.factory(UserRestControllerApiFactory);
    const authManager = useAuthManager();
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();
    const token = searchParams.get("token") || undefined;

    const [validated, setValidated] = useState(false);
    const [requesting, setRequesting] = useState(true);

    useEffect(() => {
        if (token) {
            ctrl.validate(token)
                .then((resp: AxiosResponse<UserAuth>) => {
                    let user: UserAuth = resp.data;
                    setValidated(true);
                    setRequesting(false);
                })
                .catch((err: any) => {
                    setValidated(false);
                    setRequesting(false);
                });
        } else {
            setValidated(false);
            setRequesting(false);
        }
    }, [token]);


    return (
        <LoginLayout title={"Valida tu cuenta"}>
            {!requesting && (
                <>
                    {validated && (
                        <Alert severity="success" sx={{my: 3}}>Usuario registrado correctamente</Alert>
                    )}
                    {!validated && (
                        <Alert severity="error" sx={{my: 3}}>Su cuenta no ha podido ser validada</Alert>
                    )}
                </>
            )}
        </LoginLayout>

    );
}
