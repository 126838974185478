export const BUTTON = "button";
export const CAPTION = "caption";
export const H1 = "h1";
export const H2 = "h2";
export const H3 = "h3";
export const H4 = "h4";
export const H5 = "h5";
export const H6 = "h6";
export const INHERIT = "inherit";
export const SUBTITLE1 = "subtitle1";
export const SUBTITLE2 = "subtitle2";
export const BODY1 = "body1";
export const BODY2 = "body2";
export const OVERLINE = "overline";
export const PARAGRAPH = "p";
export const SPAN = "span";

export enum Typographies {
    button = "button",
    caption = "caption",
    h1 = "h1",
    h2 = "h2",
    h3 = "h3",
    h4 = "h4",
    h5 = "h5",
    h6 = "h6",
    inherit = "inherit",
    subtitle1 = "subtitle1",
    subtitle2 = "subtitle2",
    body1 = "body1",
    body2 = "body2",
    overline = "overline",
}
