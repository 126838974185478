import React, {useContext, useState} from "react";
import {FormContext} from "../form_buttons";
import {FormControlLabel, Switch} from "@mui/material";
import {Box} from "@mui/system";
import {fieldMarginBottom} from "../../form_constants";

interface FormToggleProp {
    field: string;
    label: string;
}

const FormToggle = (props: FormToggleProp) => {
    const {field, label} = props;

    const formContext = useContext(FormContext);
    const model = formContext.form;
    const modelField = model.field(field);

    const [modelValue, setModelValue] = useState<boolean>(modelField.valueAsBoolean());
    model.register(field, () => modelValue, t => setModelValue(t));

    return (
        <Box className={["form-input", "form-input-" + field].join(" ")} mb={fieldMarginBottom}>
            <FormControlLabel
                control={
                    <Switch
                        checked={modelValue}
                        onChange={(event) => setModelValue(event.target.checked)}
                        id={field}
                    />
                }
                label={label}
            />
        </Box>
    );
};

export default FormToggle;
