import {Grid} from "@mui/material";
import {FormInput} from "../../../form/form_inputs/form_input";
import FormSelect from "../../../form/form_inputs/form_select";
import {types} from "../../../form/types";
import {FormAddress} from "../../../form/form_inputs/form_address";
import React from "react";
import {AxiosPromise} from "axios";
import FormFilePicture from "../../../form/form_inputs/form_file_picture";
import FormAutocomplete from "../../../form/form_inputs/form_autocomplete";

export interface EmployeeDataProps {
    lockFields?: boolean;
    photoDownloader: () => AxiosPromise<string[]>;
}

export const EmployeeDataFields = ({ lockFields = false, photoDownloader}: EmployeeDataProps) => {

    return (
        <>
            <FormAutocomplete field={"workCenter"} label={"Centro de Trabajo"} disabled={lockFields}
                              type={lockFields ? types.employeeWorkCenters : types.workCenters}/>


            <Grid container spacing={3}>
                <Grid item sm>
                    <FormInput
                        type={"text"}
                        field={"surnames"}
                        label={"Apellidos"}
                        disabled={lockFields}
                    />
                </Grid>
                <Grid item sm>
                    <FormInput
                        type={"text"}
                        field={"name"}
                        label={"Nombre"}
                        disabled={lockFields}
                    />
                </Grid>
                <Grid item sm>
                    <FormInput field={"nif"} type={"text"} label={"Nif"} disabled={lockFields} />
                </Grid>
            </Grid>

            <Grid container spacing={3}>
                <Grid item sm>
                    <FormInput
                        field={"email"}
                        type={"text"}
                        label={"Email"}
                        disabled={lockFields}
                    />
                </Grid>
                <Grid item sm>
                    <FormInput
                        field={"hireDate"}
                        type={"date"}
                        label={"Alta"}
                        disabled={lockFields}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={3}>
                <Grid item sm>
                    <FormInput field={"emailAlt"} type={"text"} label={"Email personal"} />
                </Grid>
                <Grid item sm>
                    <FormInput field={"emailCustomer"} type={"text"} label={"Email en cliente"} />
                </Grid>
            </Grid>

            <Grid container spacing={3}>
                <Grid item sm>
                    <FormInput field={"telephone"} type={"text"} label={"Teléfono"} />
                </Grid>
                <Grid item sm>
                    <FormInput field={"birth"} type={"date"} label={"Fec Nac"} />
                </Grid>
            </Grid>

            <Grid container spacing={3}>
                <Grid item sm>
                    <FormSelect
                        field={"maxTrainingLevel"}
                        label={"Máxima formación académica"}
                        type={lockFields ? types.employeeTrainingLevels : types.trainingLevels}
                    />
                </Grid>
                <Grid item sm>
                    <FormInput field={"bankAccount"} type={"text"} label={"Cuenta de Banco"} />
                </Grid>
            </Grid>

            <FormAddress field={"address"} lockFields={lockFields} />

            <FormInput field={"linkedin"} type={"text"} label={"Url de Linkedin"}/>
            <FormInput field={"github"} type={"text"} label={"Url de Github"}/>

            <Grid container spacing={3} mt={2}>
                <Grid item sm={3}>
                    <FormFilePicture field={"photo"}
                                     label={"Foto"}
                                     photoDownloader={photoDownloader}
                                     helpText={"La foto debe ser jpg con resolución de 320x320"}/>
                </Grid>
                <Grid item sm>
                    <FormInput field={"coverPhrase"} type={"text"} label={"Frase de presentación"}
                               helperText={"La presentación se mostrará en el directorio"} />

                    <FormInput field={"bioPhrase"} type={"text"} label={"Biografía"}
                               helperText={"La bio aparecerá cuando alguien consulte más información"}
                               placeholder={"Descríbete en 80 palabras"} rows={3} />
                </Grid>
            </Grid>

        </>
    );
};