import {Grid} from "@mui/material";
import FormSelect from "../../components/form/form_inputs/form_select";
import {types} from "../../components/form/types";
import React from "react";

export interface CandidateRegionProps {
    isSpain: boolean;
    setIsSpain: (b: boolean) => void;
}

export const CandidateRegion = ({isSpain, setIsSpain}: CandidateRegionProps) => {
    return (
        <Grid container spacing={3}>
            <Grid item xs>
                <FormSelect
                    type={types.countries}
                    field={"country"}
                    label={"País"}
                    onChange={(country) => {
                        if (!country) {
                            country = {
                                label: "España",
                                value: "ES",
                            };
                        }

                        if (country?.value === "ES") {
                            setIsSpain(true);
                        } else {
                            setIsSpain(false);
                        }
                    }}
                />
            </Grid>
            {isSpain && (
                <Grid item xs>
                    <FormSelect
                        type={types.regions}
                        field={"region"}
                        label={"Región (obligatorio)"}
                    />
                </Grid>
            )}
        </Grid>
    )
}