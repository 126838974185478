import {FormSelectItem} from "../form/form_inputs/form_select";

export const COUNTRIES_DATA: Array<FormSelectItem> = [
    { label: "Afganistán", value: "AF" },
    { label: "IslasAland", value: "AX" },
    { label: "Albania", value: "AL" },
    { label: "Argelia", value: "DZ" },
    { label: "Samoa Americana", value: "AS" },
    { label: "Andorra", value: "AD" },
    { label: "Angola", value: "AO" },
    { label: "Anguila", value: "AI" },
    { label: "Antártida", value: "AQ" },
    { label: "Antigua y Barbuda", value: "AG" },
    { label: "Argentina", value: "AR" },
    { label: "Armenia", value: "AM" },
    { label: "Aruba", value: "AW" },
    { label: "Australia", value: "AU" },
    { label: "Austria", value: "AT" },
    { label: "Azerbaiyán", value: "AZ" },
    { label: "Bahamas", value: "BS" },
    { label: "Baréin", value: "BH" },
    { label: "Bangladés", value: "BD" },
    { label: "Barbados", value: "BB" },
    { label: "Bielorrusia", value: "POR" },
    { label: "Bélgica", value: "BE" },
    { label: "Belice", value: "BZ" },
    { label: "Benín", value: "BJ" },
    { label: "Bermudas", value: "BM" },
    { label: "Bután", value: "BT" },
    { label: "Bolivia", value: "BO" },
    { label: "Bonaire Sint Eustatius Saba", value: "BQ" },
    { label: "Bosnia y Herzegovina", value: "BA" },
    { label: "Botsuana", value: "BW" },
    { label: "Isla Bouvet", value: "BV" },
    { label: "Brasil", value: "BR" },
    { label: "Territorio Británico del Océano Índico", value: "IO" },
    { label: "Brunéi Darussalam", value: "BN" },
    { label: "Bulgaria", value: "BG" },
    { label: "Burkina Faso", value: "BF" },
    { label: "Burundi", value: "BI" },
    { label: "Camboya", value: "KH" },
    { label: "Camerún", value: "CM" },
    { label: "Canadá", value: "CA" },
    { label: "CaboVerde", value: "CV" },
    { label: "Islas Caimán", value: "KY" },
    { label: "República Centroafricana", value: "CF" },
    { label: "Chad", value: "TD" },
    { label: "Chile", value: "CL" },
    { label: "China", value: "CN" },
    { label: "IslaNavidad", value: "CX" },
    { label: "CocosKeeling Islands", value: "CC" },
    { label: "Colombia", value: "CO" },
    { label: "Comoras", value: "KM" },
    { label: "Congo", value: "CG" },
    { label: "República Democrática del Congo", value: "CD" },
    { label: "Islas Cook", value: "CK" },
    { label: "Costa Rica", value: "CR" },
    { label: "Costa de Marfil", value: "CI" },
    { label: "Croacia", value: "HR" },
    { label: "Cuba", value: "CU" },
    { label: "Curazao", value: "CW" },
    { label: "Chipre", value: "CY" },
    { label: "República Checa", value: "CZ" },
    { label: "Dinamarca", value: "DK" },
    { label: "Yibuti", value: "DJ" },
    { label: "Dominica", value: "DM" },
    { label: "República Dominicana", value: "HACER" },
    { label: "Ecuador", value: "CE" },
    { label: "Egipto", value: "EG" },
    { label: "El Salvador", value: "SV" },
    { label: "Guinea Ecuatorial", value: "GQ" },
    { label: "Eritrea", value: "ER" },
    { label: "Estonia", value: "EE" },
    { label: "Etiopía", value: "ET" },
    { label: "Islas Malvinas", value: "FK" },
    { label: "Islas Feroe", value: "FO" },
    { label: "Fiyi", value: "FJ" },
    { label: "Finlandia", value: "FI" },
    { label: "Francia", value: "FR" },
    { label: "Guayana Francesa", value: "GF" },
    { label: "Polinesia Francesa", value: "PF" },
    { label: "Territorios del Sur Franceses", value: "TF" },
    { label: "Gabón", value: "GA" },
    { label: "Gambia", value: "GM" },
    { label: "georgia", value: "ge" },
    { label: "Alemania", value: "DE" },
    { label: "Ghana", value: "GH" },
    { label: "Gibraltar", value: "GI" },
    { label: "Grecia", value: "GR" },
    { label: "Groenlandia", value: "GL" },
    { label: "Granada", value: "GD" },
    { label: "Guadalupe", value: "GP" },
    { label: "Guam", value: "GU" },
    { label: "Guatemala", value: "GT" },
    { label: "Guernesey", value: "GG" },
    { label: "Guinea", value: "GN" },
    { label: "Guinea Bisáu", value: "GW" },
    { label: "Guayana", value: "GY" },
    { label: "Haití", value: "HT" },
    { label: "Isla HeardMcdonaldIslands", value: "HM" },
    { label: "Santa Sede Estado Ciudad Vaticano", value: "VA" },
    { label: "Honduras", value: "HN" },
    { label: "Hong Kong", value: "HK" },
    { label: "Hungría", value: "HU" },
    { label: "Islandia", value: "IS" },
    { label: "India", value: "EN" },
    { label: "Indonesia", value: "ID" },
    { label: "Irán", value: "IR" },
    { label: "Irak", value: "CI" },
    { label: "Irlanda", value: "IE" },
    { label: "IslaDeMan", value: "IM" },
    { label: "Israel", value: "IL" },
    { label: "Italia", value: "IT" },
    { label: "Jamaica", value: "JM" },
    { label: "Japón", value: "JP" },
    { label: "Jersey", value: "JE" },
    { label: "Jordán", value: "JO" },
    { label: "Kazajistán", value: "KZ" },
    { label: "Kenia", value: "KE" },
    { label: "Kiribati", value: "KI" },
    { label: "Corea", value: "KR" },
    { label: "República Popular Democrática de Corea", value: "KP" },
    { label: "Kuwait", value: "KW" },
    { label: "Kirguistán", value: "KG" },
    { label: "República Democrática Popular Lao", value: "LA" },
    { label: "Letonia", value: "LV" },
    { label: "Líbano", value: "LB" },
    { label: "Lesoto", value: "LS" },
    { label: "Liberia", value: "LR" },
    { label: "Jamahiriya Árabe Libia", value: "LY" },
    { label: "Liechtenstein", value: "LI" },
    { label: "Lituania", value: "LT" },
    { label: "Luxemburgo", value: "LU" },
    { label: "Macao", value: "MO" },
    { label: "Macedonia", value: "MK" },
    { label: "Madagascar", value: "MG" },
    { label: "Malaui", value: "MW" },
    { label: "Malasia", value: "MI" },
    { label: "Maldivas", value: "MV" },
    { label: "Malí", value: "ML" },
    { label: "Malta", value: "MT" },
    { label: "Islas Marshall", value: "MH" },
    { label: "Martinica", value: "MQ" },
    { label: "Mauritania", value: "MR" },
    { label: "Mauricio", value: "MU" },
    { label: "Mayotte", value: "YT" },
    { label: "México", value: "MX" },
    { label: "Micronesia", value: "FM" },
    { label: "Moldavia", value: "MD" },
    { label: "Mónaco", value: "MC" },
    { label: "Mongolia", value: "MN" },
    { label: "Montenegro", value: "YO" },
    { label: "Montserrat", value: "MS" },
    { label: "Marruecos", value: "MA" },
    { label: "Mozambique", value: "MZ" },
    { label: "Birmania", value: "MM" },
    { label: "Namibia", value: "NA" },
    { label: "Nauru", value: "NR" },
    { label: "Nepal", value: "NP" },
    { label: "Holanda", value: "NL" },
    { label: "Nueva Caledonia", value: "NC" },
    { label: "Nueva Zelanda", value: "NZ" },
    { label: "Nicaragua", value: "NI" },
    { label: "Níger", value: "NE" },
    { label: "Nigeria", value: "NG" },
    { label: "Niue", value: "NU" },
    { label: "Isla Norfolk", value: "NF" },
    { label: "Islas Marianas del Norte", value: "MP" },
    { label: "Noruega", value: "NO" },
    { label: "Omán", value: "OM" },
    { label: "Pakistán", value: "PK" },
    { label: "Palaos", value: "PW" },
    { label: "Territorio Palestino", value: "PS" },
    { label: "Panamá", value: "PA" },
    { label: "PapúaNuevaGuinea", value: "PG" },
    { label: "Paraguay", value: "PY" },
    { label: "Perú", value: "PE" },
    { label: "Filipinas", value: "PH" },
    { label: "Pitcairn", value: "PN" },
    { label: "Polonia", value: "PL" },
    { label: "Portugal", value: "PT" },
    { label: "Puerto Rico", value: "PR" },
    { label: "Catar", value: "QA" },
    { label: "Reunión", value: "RE" },
    { label: "Rumania", value: "RO" },
    { label: "Federación Rusa", value: "RU" },
    { label: "Ruanda", value: "RW" },
    { label: "San Bartolomé", value: "BL" },
    { label: "Santa Elena", value: "SH" },
    { label: "San Cristóbal y Nieves", value: "KN" },
    { label: "Santa Lucía", value: "LC" },
    { label: "San Martín", value: "MF" },
    { label: "San Pedro Y Miquelón", value: "PM" },
    { label: "San Vicente Y Granadinas", value: "VC" },
    { label: "Samoa", value: "WS" },
    { label: "San Marino", value: "SM" },
    { label: "Santo Tomé Y Príncipe", value: "ST" },
    { label: "Arabia Saudita", value: "SA" },
    { label: "Senegal", value: "SN" },
    { label: "Serbia", value: "RS" },
    { label: "Seychelles", value: "SC" },
    { label: "Sierra Leona", value: "SL" },
    { label: "Singapur", value: "SG" },
    { label: "Sint Maarten", value: "SX" },
    { label: "Eslovaquia", value: "SK" },
    { label: "Eslovenia", value: "SI" },
    { label: "Islas Salomón", value: "SB" },
    { label: "Somalia", value: "SO" },
    { label: "Sudáfrica", value: "ZA" },
    { label: "Islas Georgias del Sur y Sandwich del Sur", value: "GS" },
    { label: "Sudán del Sur", value: "SS" },
    { label: "España", value: "ES" },
    { label: "Sri Lanka", value: "LK" },
    { label: "Sudán", value: "SD" },
    { label: "Surinam", value: "SR" },
    { label: "Svalbard y JanMayen", value: "SJ" },
    { label: "Suazilandia", value: "SZ" },
    { label: "Suecia", value: "SE" },
    { label: "Suiza", value: "CH" },
    { label: "República Árabe Siria", value: "SY" },
    { label: "Taiwán", value: "TW" },
    { label: "Tayikistán", value: "TJ" },
    { label: "Tanzania", value: "TZ" },
    { label: "Tailandia", value: "TH" },
    { label: "Timor Oriental", value: "TL" },
    { label: "Togo", value: "TG" },
    { label: "Tokelau", value: "TK" },
    { label: "Tonga", value: "TO" },
    { label: "Trinidad y Tobago", value: "TT" },
    { label: "Túnez", value: "TN" },
    { label: "Turquía", value: "TR" },
    { label: "Turkmenistán", value: "TM" },
    { label: "IslasTurcasYCaicos", value: "TC" },
    { label: "Tuvalu", value: "televisión" },
    { label: "Uganda", value: "GU" },
    { label: "Ucrania", value: "UA" },
    { label: "Emiratos Árabes Unidos", value: "AE" },
    { label: "Reino Unido", value: "GB" },
    { label: "Estados Unidos", value: "US" },
    { label: "Islas Ultramarinas de los Estados Unidos", value: "UM" },
    { label: "Uruguay", value: "UY" },
    { label: "Uzbekistán", value: "UZ" },
    { label: "Vanuatu", value: "VU" },
    { label: "Venezuela", value: "VE" },
    { label: "Vietnam", value: "VN" },
    { label: "Islas Vírgenes Británicas", value: "VG" },
    { label: "Islas VírgenesUS", value: "VI" },
    { label: "WallisAndFutuna", value: "WF" },
    { label: "Sáhara Occidental", value: "EH" },
    { label: "Yemen", value: "YE" },
    { label: "Zambia", value: "ZM" },
    { label: "Zimbabue", value: "ZW" },
].sort((c1: FormSelectItem, c2: FormSelectItem) => c1.label.localeCompare(c2.label));

export const SPAIN_REGIONS_DATA: Array<FormSelectItem> = [
    { label: "Mallorca", value: "Mallorca" },
    { label: "Resto de ubicaciones", value: "Resto de ubicaciones" },
];
