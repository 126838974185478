import AdminInner from "../../components/layout/admin_inner";
import {Box, Container, TextField} from "@mui/material";
import {useAuthManager} from "../../components/utils/auth";
import React from "react";

export const UserProfile = () => {
    const authManger = useAuthManager();

    return (
        <>
            <AdminInner title={"Detalle del Usuario"}>
                {authManger.isLogged() && (
                    <Container maxWidth={"xs"}>
                        <Box py={4} />
                        <TextField
                            fullWidth
                            label={"Username"}
                            value={authManger.getUsername()}
                            disabled
                        />

                        <Box py={3} />
                        <TextField
                            fullWidth
                            label={"Email"}
                            value={authManger.getEmail()}
                            disabled
                        />

                        <Box py={4} />
                    </Container>
                )}
            </AdminInner>
        </>
    );
};