import React from "react";
import {Grid} from "@mui/material";
import {AdminCompanyRestControllerApiFactory, Company} from "arteco-api-client-ts";
import {FormContent} from "../../../components/form/form_inputs/form_buttons";
import {FormInput} from "../../../components/form/form_inputs/form_input";
import {FormAddress} from "../../../components/form/form_inputs/form_address";
import {useForm, UseFormProps} from "../../../components/form/form_model";
import {useApiManager} from "../../../components/utils/api";

export const CompanyEditPage = () => {
    const manager = useApiManager();
    const ctrl = manager.factory(AdminCompanyRestControllerApiFactory);
    const companyId = manager.companyId;

    const form = useForm({
        fetcher: (id) => ctrl.getCompany(id),
        updater: (bean) => ctrl.saveCompany(bean),
        factory: () => {
            return {} as Company;
        },
        modelIdFetcher: () => companyId,
    } as UseFormProps<Company>);

    return (
        <>
            <FormContent form={form} title={"Ficha de empresa"}>
                <FormInput type={"text"} field={"name"} label={"Nombre"} />

                <Grid container spacing={3}>
                    <Grid item sm>
                        <FormInput field={"nif"} type={"text"} label={"Nif"} />
                    </Grid>
                    <Grid item sm>
                        <FormInput field={"email"} type={"email"} label={"Email"} />
                    </Grid>
                    <Grid item sm>
                        <FormInput field={"telephone"} type={"text"} label={"Teléfono"} />
                    </Grid>
                </Grid>

                <FormInput field={"bankAccount"} type={"text"} label={"Cuenta bancaria"} />
                <FormInput field={"website"} type={"text"} label={"Página web"} />

                <FormAddress field={"address"} />
            </FormContent>
        </>
    );
};