import AppList, {AppListConfig} from "../../../../../components/layout/list";
import {
    CandidateDocument,
    CandidateDocumentRestControllerApiFactory
} from "arteco-api-client-ts";
import React from "react";
import {useApiManager} from "../../../../../components/utils/api";
import {useNavigate, useParams} from "react-router-dom";
import {Button, Grid} from "@mui/material";
import {dateToString} from "../../../../../components/utils/dates";
import {useCandidate} from "../index";
import {fileLocalSave} from "../../../../../components/form/form_inputs/form_file";
import DownloadIcon from '@mui/icons-material/Download';
import {parsePathId} from "../../../../../components/form/form_utils";

export const CandidateDocumentListPage = () => {

    const params = useParams();
    const apiManager = useApiManager();
    const navigate = useNavigate();

    const candidateId = parsePathId(params, "candidateId");
    const [candidate, setCandidate] = useCandidate(candidateId);

    const documentCtrl = apiManager.factory(CandidateDocumentRestControllerApiFactory);

    const getCandidateId = (document: CandidateDocument | undefined): number | undefined => {
        if (candidate) {
            return candidate.id;
        } else if (document) {
            return document.candidate.id;
        } else {
            return undefined;
        }
    }

    const listConfig: AppListConfig<CandidateDocument> = {
        title: "Archivos de " + (candidate ? candidate.surnamesAndName : " Candidatos"),
        onClick: (document: CandidateDocument) => {
            const candidateId = getCandidateId(document);
            if (candidateId) {
                navigate("/app/admin/recruitment/candidate/" + candidateId + "/document/" + document.id);
            } else {
                alert("No candidateId to forward!");
            }
        },
        decorator: (bean: CandidateDocument) =>
            <Grid container>
                <Grid item sm>{dateToString(bean.created)}</Grid>
                {!candidateId &&
                    <Grid item sm>{bean.candidate.surnamesAndName}</Grid>
                }
                <Grid item sm>{bean.title}</Grid>
                <Grid item sm textAlign={"right"}>
                    <Button variant={"text"} size={"small"}
                            title={"Descargar"}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                if (bean.id) {
                                    apiManager.executeRaw(documentCtrl.downloadCandidateDocument(bean.id))
                                        .then((resp) => fileLocalSave(resp));
                                }
                            }}>
                        <DownloadIcon/>
                    </Button>
                </Grid>
            </Grid>,
        supplier: async (currentPage, size, term, showDismissed) => {
            return documentCtrl.pageCandidateDocument(
                candidateId as number,
                currentPage,
                size,
                undefined,
                term,
                showDismissed
            );
        },
    };

    return (
        <>
            <AppList config={listConfig} appendable={candidate != undefined} onAppend={() => {
                const candidateId = getCandidateId(undefined);
                if (candidateId) {
                    navigate("/app/admin/recruitment/candidate/" + candidateId + "/document/new");
                } else {
                    alert("No candidateId to forward!");
                }
            }}/>
        </>
    );
};