import {useParams} from "react-router-dom";
import {useForm} from "../../components/form/form_model";
import React, {useEffect, useState} from "react";
import {AppLayout} from "../../components/layout/layout";
import {Box, Button, Paper, Typography} from "@mui/material";
import {H1, PARAGRAPH, Typographies} from "../../components/utils/typography_consts";
import {HOME_ARTECO} from "../../components/utils/urls";
import {FormContent} from "../../components/form/form_inputs/form_buttons";
import FormFile, {fileLocalSave} from "../../components/form/form_inputs/form_file";
import {CandidateHeader} from "./header";
import {
    Attachment,
    CandidateLevelTest,
    CandidateTestForm,
    CandidateTestRestControllerApiFactory,
} from "arteco-api-client-ts";
import {useApiManager} from "../../components/utils/api";
import {AxiosPromise} from "axios";
import {parsePathId} from "../../components/form/form_utils";

interface CandidateTestFormWithId extends CandidateTestForm {
    id: number | undefined;
}

export const CandidateTestPage = () => {
    const params = useParams();
    const candidateId = parsePathId(params, "candidateId") as number;
    const testId = parsePathId(params, "testId") as number;
    const apiManager = useApiManager();
    const companyId = apiManager.companyId;
    const ctrl = apiManager.factory(CandidateTestRestControllerApiFactory);

    const [isSentForm, setIsSentForm] = useState<boolean>(false);
    const [testBean, setTestBean] = useState<CandidateLevelTest | undefined>(undefined);

    useEffect(() => {
        if (testId) {
            apiManager.execute(ctrl.getCandidateTest(testId))
                .then(test => setTestBean(test));
        }
    }, [testId]);


    const form = useForm<CandidateTestFormWithId>({
        updater: (testForm) => {
            if (!testForm.attachment || !testForm.attachment.content) {
                return new Promise<CandidateTestFormWithId>((resolve, reject) => reject("El adjunto es obligatorio"));
            }
            return ctrl.saveCandidateTest(testForm as CandidateTestForm) as AxiosPromise<CandidateTestFormWithId>
        },
        postUpdate: () => {
            setIsSentForm(true);
        },
        factory: () => {
            return {
                companyId,
                candidateId,
                testId,
                // attachment: {} as Attachment
            } as CandidateTestFormWithId;
        },
    });

    return (
        <AppLayout permitAnon={true} maxWidth="md">


            <CandidateHeader title={"Sube tu prueba de nivel"}
                             subTitle={"Born to be a Javalover?"}/>


            <Box>
                {isSentForm ? (
                    <Paper>
                        <Box p={3}>
                            <Typography my={4}
                                        variant={Typographies.h4}
                                        component={H1}>
                                Tu prueba ha sido enviada con éxito
                            </Typography>
                            <Typography py={3} component={PARAGRAPH}>
                                ¡Gracias por hacernos llegar tu prueba!
                                En breve procederemos a revisarla.
                            </Typography>

                            <Typography py={4} component={PARAGRAPH}>
                                Puedes continuar revisando más contenido en la página
                                &nbsp;<a href={HOME_ARTECO}>web de Arteco</a>.
                            </Typography>
                        </Box>
                    </Paper>

                ) : (

                    <>

                        <FormContent form={form} title={""} hideMetadata={true} hideTopButtons={true}>

                            {testBean && (
                                <>
                                    <Box py={3}>
                                        <Typography variant={"h5"}>
                                            {testBean?.name}
                                        </Typography>
                                    </Box>
                                    <Box py={3}>
                                        <Typography variant={"body1"}>
                                            {testBean?.description}
                                        </Typography>
                                    </Box>
                                    <Box py={3}>
                                        <Button variant={"outlined"}
                                                onClick={_ => {
                                                    if (!testId) throw new Error("No test id");
                                                    ctrl
                                                        .downloadCandidateTest(testId)
                                                        .then((resp) => fileLocalSave(resp));
                                                }}>
                                            Descargar práctica
                                        </Button>
                                    </Box>
                                </>
                            )}

                            <Typography py={3} variant={"h5"}>
                                Sube tu prueba de nivel
                            </Typography>

                            <FormFile
                                field={"attachment"}
                                label={"Prueba (máx 5Mb) (obligatorio)"}
                            />
                        </FormContent>
                    </>
                )}
            </Box>
        </AppLayout>
    );
}