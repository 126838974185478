import {configureStore, createSlice, PayloadAction,} from '@reduxjs/toolkit'
import {AuthState} from "./components/utils/auth";
import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux'

export const authSlice = createSlice({
    name: 'auth',
    initialState: {} as AuthState,
    reducers: {
        updateAuthRedux: (state, action: PayloadAction<AuthState>) => {
            Object.assign(state, action.payload);
        },
    },
})

export const {updateAuthRedux} = authSlice.actions;

export const store = configureStore({
    reducer: {
        auth: authSlice.reducer
    }
});


export type ReduxState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<ReduxState> = useSelector