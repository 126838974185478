import React, {useContext, useEffect, useState} from "react";
import {FormContext} from "../form_buttons";
import {Box, FormControl, TextField} from "@mui/material";
import {DatePicker, DateTimePicker} from "@mui/x-date-pickers";
import {fieldMarginBottom} from "../../form_constants";
import moment from "moment";

interface FormInputProp {
    field: string;
    type?: "text" | "number" | "date" | "datetime" | "password" | "email";
    label: string;
    rows?: number;
    disabled?: boolean;
    onChange?: (value: any) => void;
    onKeyUp?: (value: any) => void;
    inputProps?: object;
    helperText?: string;
    placeholder?: string;
    value?: any;
}

export const FormInput = ({
                              field,
                              type = "text",
                              label,
                              rows,
                              disabled,
                              onChange,
                              onKeyUp,
                              inputProps,
                              helperText,
                              placeholder,
                              value
                          }: FormInputProp) => {
    const formContext = useContext(FormContext);
    const form = formContext.form;
    const modelField = form.field(field);

    const initialValue: string = value
        ? value
        : type.indexOf("date") < 0
            ? (modelField.value() != undefined ? modelField.value() : "")
            : modelField.valueAsDate();

    // Use state sólo se ejecuta en el primer render. Hay que suscribirse al initial value
    // para que estén sincronizados
    const [modelValue, setModelValue] = useState(initialValue);
    useEffect(() => { setModelValue(initialValue)}, [initialValue] )

    form.register(field, () => modelValue, t => setModelValue(t));

    return (
        <>
            {type != "date" && type != "datetime" && (
                <Box
                    className={["form-input", "form-input-" + field].join(
                        " "
                    )} mb={fieldMarginBottom}>
                    <TextField
                        id={field}
                        variant="filled"
                        label={label}
                        type={type}
                        rows={rows || 1}
                        multiline={rows ? rows > 1 : undefined}
                        fullWidth
                        autoComplete={"off"}
                        disabled={disabled}
                        value={modelValue}
                        inputProps={inputProps}
                        onKeyUp={onKeyUp}
                        helperText={helperText}
                        placeholder={placeholder}
                        onChange={(event) => {
                            const value = event.target.value;
                            setModelValue(value);
                            if (onChange) {
                                onChange(value);
                            }
                        }}
                    />
                </Box>
            )}
            {type == "date" && (
                <Box
                    className={["form-input", "form-input-" + field].join(
                        " "
                    )} mb={1}>
                    <FormControl fullWidth>
                        <DatePicker
                            disabled={disabled}
                            value={modelValue}
                            inputFormat={"DD/MM/YYYY"}
                            label={label}
                            renderInput={(params) => <TextField id={field} {...params} />}
                            onChange={(value) => {
                                let strValue: string = '';
                                if (value) {
                                    strValue = moment(value).format('YYYY-MM-DD')
                                }
                                setModelValue(strValue);
                                if (onChange) {
                                    onChange(value);
                                }
                            }}
                        />
                    </FormControl>
                </Box>
            )}
            {type == "datetime" && (
                <Box
                    className={["form-input", "form-input-" + field].join(
                        " "
                    )} mb={1}>
                    <FormControl fullWidth>
                        <DateTimePicker
                            disabled={disabled}
                            value={modelValue}
                            label={label}
                            renderInput={(params) => <TextField id={field} {...params} />}
                            onChange={(value) => {
                                let strValue: string = '';
                                if (value) {
                                    strValue = moment(value).format('YYYY-MM-DDTHH:mm:ss')
                                }
                                setModelValue(strValue);
                                if (onChange) {
                                    onChange(value);
                                }
                            }}
                        />
                    </FormControl>
                </Box>
            )}
        </>
    );
};