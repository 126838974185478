import React, {useEffect, useState} from 'react'

import {EmpLearningRestControllerApiFactory, WikiPageTypeEnum} from "arteco-api-client-ts";
import {useApiManager} from "./api";
import ReactMarkdown from "react-markdown";
import {useAuthManager} from "./auth";
import {Button} from "@mui/material";
import rehypeRaw from 'rehype-raw';

export interface WikiPageRenderProps {
    code?: string;
    section?: string;
    content?: string;
    type?: WikiPageTypeEnum;
}

const WikiPageRender = ({code, section, content, type}: WikiPageRenderProps) => {
    const authManager = useAuthManager();
    const apiManager = useApiManager();
    const wikiPageCtrl = apiManager.factory(EmpLearningRestControllerApiFactory);
    const isAdmin = authManager.getAuthorities().find(r => r.type && r.type.indexOf("ADMIN") >= 0) != null;

    if (code && !section) {
        throw "Section is mandatory when code is set";
    }
    if (content && !type) {
        throw "Type is mandatory when content is set";
    }

    const [information, setInformation] = useState<string>(content || "");
    const [informationType, setInformationType] = useState<string>(type || WikiPageTypeEnum.PLAIN_TEXT);

    useEffect(() => {
        if (content) {
            setInformation(content);
        }
    }, [content])

    function loadPage() {
        if (code && section) {
            apiManager
                .execute(wikiPageCtrl.getPageLearning(code, section))
                .then(page => {
                    setInformation(page.content);
                    setInformationType(page.type || WikiPageTypeEnum.PLAIN_TEXT);
                }, e => {
                    setInformation("No se ha encontrado la página de Wiki con código [" + code + "] and section [" + section + "]");
                    setInformationType(WikiPageTypeEnum.PLAIN_TEXT);
                });
        }
    }

    useEffect(() => {
        loadPage();
    }, []);


    return (
        <>
            {isAdmin && code && section && (
                <Button size={"small"} variant={"contained"} onClick={_ => loadPage()}>Refresh</Button>
            )}
            {information && informationType && (
                <>
                    {informationType == WikiPageTypeEnum.MARKDOWN && (
                        <ReactMarkdown rehypePlugins={[rehypeRaw]} children={information}/>
                    )}
                    {informationType != WikiPageTypeEnum.MARKDOWN && (
                        <section>{information}</section>
                    )}
                </>
            )}
            {(!information || !informationType) && (
                <p>Loading...</p>
            )}
        </>
    )
}

export default WikiPageRender;