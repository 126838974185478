import {EvaluationPoint, EvaluationPointRestControllerApiFactory} from "arteco-api-client-ts";
import AppList, {AppListConfig} from "../../../../components/layout/list";
import {useApiManager} from "../../../../components/utils/api";
import {Grid} from "@mui/material";

export const EvaluationPointListPage = () => {
    const apiManager = useApiManager();

    const listConfig: AppListConfig<EvaluationPoint> = {
        title: "Preguntas de evaluación",
        onClick: "/app/admin/employee/evaluationpoint",
        decorator: (bean: EvaluationPoint) => (
            <Grid container>
                <Grid item sm={9}>
                    {bean.label}
                </Grid>
                <Grid item sm>
                    {bean.position}
                </Grid>
            </Grid>
        ),
        supplier: async (currentPage, size, term, showDismissed) => {
            const companyId = apiManager.companyId;
            const ctrl = apiManager.factory(EvaluationPointRestControllerApiFactory);
            return ctrl.pageEvaluationPoint(companyId, currentPage, size, undefined, term, showDismissed);
        },
    };

    return (
        <>
            <AppList config={listConfig} />
        </>
    );
};