import React, {ReactNode} from "react";
import {Grid} from "@mui/material";


export interface AdminCardsProps {
    children: ReactNode;
}

export const AdminCards = ({children}: AdminCardsProps) => {
    return (
        <Grid container spacing={3} sx={{marginY: 1}} alignItems={"stretch"}>
            {children}
        </Grid>
    )
}