import {Box} from "@mui/material";
import React from "react";
import {PersonOccupation} from "./occupation_utils";


export interface CalendarProps {
    personRow: PersonOccupation;
}

export const Occupation_calendar = ({personRow}: CalendarProps) => {
    return (
        <>

            <table style={{width: "100%"}}>
                <thead>
                <tr>
                    <th align={"left"}>FECHA</th>
                    <th align={"left"}>DÍA</th>
                    <th align={"left"}>TRABAJO</th>
                </tr>
                </thead>
                <tbody>
                {Array.from(personRow.personCalendar.values()).map((row, rowIdx) => (
                    <tr key={rowIdx}
                        style={{
                            color: row.peopleDay.weekend || row.personDay.holidayName ? "red" : "",
                        }}>
                        <td style={{paddingRight: "1em"}}>
                            {row.peopleDay.date}
                        </td>
                        <td style={{paddingRight: "2em"}}>
                            {row.peopleDay.weekday}
                        </td>
                        <td>
                            {row.personDay.holidayName && (
                                <Box color={"red"}>
                                    {row.personDay.holidayName.toUpperCase()}
                                </Box>
                            )}
                            {!row.personDay.holidayName && (
                                <>
                                    {!row.personDay.workedHours && !row.personDay.vacation && (
                                        <span>---</span>
                                    )}

                                    {!row.personDay.vacation && (
                                        <>{row.personDay.workedHours}</>
                                    )}
                                    {row.personDay.vacation && (
                                        <Box color={
                                            row.personDay.vacation.state ==
                                            "ACCEPTED"
                                                ? "primary.main"
                                                : "text.disabled"
                                        }>
                                            {row.personDay.vacation.type as string}
                                        </Box>
                                    )}

                                </>
                            )}

                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </>
    )
}