import React, {ReactNode} from "react";
import {Box} from "@mui/material";
import {FormEntities} from "../form_entities";
import FormModel, {Identifiable} from "../../form_model";
import AdminInner from "../../../layout/admin_inner";
import {FormButtons} from "../../form_buttons";

interface FormContentProp<T extends Identifiable> {
    form: FormModel<T>;
    children: ReactNode;
    title?: string;
    withoutPaper?: boolean;
    hideMetadata?: boolean;
    elevation?: number;
    padding?: number;
    hideTopButtons?: boolean;
}

export interface FormContext {
    form: FormModel<any>;
}

export const FormContext: React.Context<FormContext> = React.createContext({} as FormContext);

export function FormContent<T extends Identifiable>(props: FormContentProp<T>) {
    const form = props.form;
    const contextValue: FormContext = {form};

    FormContext.displayName = "Context-" + form.formId;


    return (
        <AdminInner title={props.title} withoutPaper={props.withoutPaper} elevation={props.elevation}
                    padding={props.padding}>
            {!form.isLoading() && props.hideTopButtons !== true && (
                <FormButtons form={form}/>
            )}
            {!form.isLoading() && (
                <>
                    <form id={props.form.formId}>
                        <FormContext.Provider value={contextValue}>
                            <FormEntities hide={props.hideMetadata}/>
                            {props.children}
                        </FormContext.Provider>
                    </form>

                    <Box id={props.form.formId + "_errs"}/>
                </>
            )}
            {form.isLoading() && <Box>Cargando....</Box>}

            {!form.isLoading() && (
                <FormButtons form={form}/>
            )}
        </AdminInner>
    );
}