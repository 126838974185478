import React from "react";
import {ApplicantState, ApplicantStateRestControllerApiFactory,} from "arteco-api-client-ts";

import {FormContent} from "../../../../components/form/form_inputs/form_buttons";
import {FormInput} from "../../../../components/form/form_inputs/form_input";
import {Grid} from "@mui/material";
import FormToggle from "../../../../components/form/form_inputs/form_toggle";
import {useForm, UseFormProps} from "../../../../components/form/form_model";
import {useApiManager} from "../../../../components/utils/api";
import {useSnackbar} from "notistack";

export const ApplicantStateEditPage = () => {
    const apiManager = useApiManager();
    const ctrl = apiManager.factory(ApplicantStateRestControllerApiFactory);
    const snack = useSnackbar();

    const form = useForm({
        fetcher: (id) => ctrl.getApplicantState(id),
        updater: (bean) => ctrl.saveApplicantState(bean),
        remover: (id) => ctrl.deleteOrDismissApplicantState(id),
        factory: () => {
            return {
                company: {
                    id: apiManager.companyId,
                },
            } as ApplicantState;
        },
        preUpdate: (bean) => {
            const count = (bean.levelTestDoing ? 1 : 0)
                + (bean.levelTestReview ? 1 : 0)
                + (bean.levelTestReviewed ? 1 : 0);
            if (count > 1) {
                snack.enqueueSnackbar("No se pueden marcar al mismo tiempo en revisión, pendiente de prueba de nivel o revisado", {variant: "warning"});
            }
            return count <= 1;
        }
    } as UseFormProps<ApplicantState>);

    return (
        <>
            <FormContent form={form} title={"Formulario de estados de los candidatos"}>
                <Grid container spacing={3}>
                    <Grid item sm={3}>
                        <FormInput type={"number"} field={"position"} label={"Posición"}/>
                    </Grid>
                    <Grid item sm>
                        <FormInput field={"name"} type={"text"} label={"Nombre"}/>
                    </Grid>
                    <Grid item sm={2}>
                        <FormToggle field={"finalized"} label={"Estado finalizador"}/>
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item sm={2}>
                        <FormToggle field={"levelTestDoing"} label={"Haciendo prueba"}/>
                    </Grid>
                    <Grid item sm={2}>
                        <FormToggle field={"levelTestReview"} label={"Prueba en revisión"}/>
                    </Grid>
                    <Grid item sm={2}>
                        <FormToggle field={"levelTestReviewed"} label={"Prueba revisada"}/>
                    </Grid>
                </Grid>

                <FormInput type={"text"} field={"color"} label={"Color"}/>


                <FormInput field={"description"} type={"text"} label={"Descripción"} rows={2}/>
            </FormContent>
        </>
    );
};