import React from "react";
import {PersonEvent, PersonEventRestControllerApiFactory,} from "arteco-api-client-ts";
import {useForm} from "../../../../../components/form/form_model";
import {useApiManager} from "../../../../../components/utils/api";
import {FormContent} from "../../../../../components/form/form_inputs/form_buttons";
import {FormInput} from "../../../../../components/form/form_inputs/form_input";
import {useParams} from "react-router-dom";
import {parsePathId} from "../../../../../components/form/form_utils";
import {usePerson} from "../index";

export const PersonEventEditPage = () => {
    const apiManager = useApiManager();
    const eventApi = apiManager.factory(PersonEventRestControllerApiFactory);
    const params = useParams();

    const personId = parsePathId(params, "personId", true);
    const companyId = apiManager.companyId;
    const [person, setPerson] = usePerson(personId);

    const form = useForm<PersonEvent>({
        fetcher: (id) => eventApi.getPersonEvent(id),
        updater: (bean) => eventApi.savePersonEvent(bean),
        remover: (id) => eventApi.deleteOrDismissPersonEvent(id),
        factory: () => {
            return {
                person: {
                    id: personId,
                },
            } as PersonEvent;
        }
    });

    return (
        <>
            <FormContent
                form={form}
                hideTopButtons={true}
                title={
                    "Ficha del evento para " +
                    (person ? person.surnamesAndName : "")
                }>
                <FormInput field={"issueDate"} label={"Fecha"} type={"datetime"}/>
                <FormInput field={"description"} label={"Descripción"} rows={4}/>
            </FormContent>
        </>
    );
};