import {Box, Button, Modal, Typography} from "@mui/material";
import {H1, Typographies} from "../../components/utils/typography_consts";
import AdminInner from "../../components/layout/admin_inner";
import {useEffect, useState} from "react";
import {useApiManager} from "../../components/utils/api";
import {EmployeeRestControllerApiFactory, PersonReminderList} from "arteco-api-client-ts";
import {employeeModalStyle} from "./modal";
import {useLastEvaluation} from "./employee_util";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

export const EmployeePage = () => {
	const apiManager = useApiManager();
	const employeeCtrl = apiManager.factory(EmployeeRestControllerApiFactory);
	const [reminders, setReminders] = useState<PersonReminderList[]>([]);
	const [modalOpen, setModalOpen] = useState<boolean>(true);
	const [reminderIdx, setReminderIdx] = useState<number>(0);

	const [forceEvaluation, setForceEvaluation] = useLastEvaluation();

	const shuffle = (array: PersonReminderList[]) => {

		let currentIndex = array.length, randomIndex;

		// While there remain elements to shuffle.
		while (currentIndex != 0) {

			// Pick a remaining element.
			randomIndex = Math.floor(Math.random() * currentIndex);
			currentIndex--;

			// And swap it with the current element.
			[array[currentIndex], array[randomIndex]] = [
				array[randomIndex], array[currentIndex]];
		}

		return array;

	}

	useEffect(() => {
		apiManager.execute(employeeCtrl.getEmployeeReminders())
		.then(reminders => {
			if (reminders && reminders.length) {
				setReminders(shuffle(reminders));
			}
		});
	}, []);

	return (
		<AdminInner>

			<Typography variant={Typographies.h3} component={H1}>
				Bienvenido al Portal del Empleado
			</Typography>
			<Typography variant={"body1"}>
				Selecciona una opción del menú lateral para comenzar
			</Typography>

			{forceEvaluation && (
				<Alert sx={{my:2}} color={"warning"}>
					<AlertTitle>Tarea pendiente</AlertTitle>
					<p>
						Hace tiempo que no completas una EVALUACIÓN.<br/>
						Cumplimenta una y se habilitará todas las opciones de nuevo.<br/>
						Gracias
					</p>
				</Alert>
			)}

			{reminders && reminders.length > 0 && (
				<>
					<Modal
						open={modalOpen}
						onClose={_ => setModalOpen(!modalOpen)}
						aria-labelledby="reminder-modal-title"
						aria-describedby="reminder-modal-description">
						<Box sx={employeeModalStyle}>
							<Typography id="reminder-modal-title" variant="h6" component="h2">
								Recuerda que...
							</Typography>
							<Box id="reminder-modal-description" sx={{mt: 2}}>
								<Box pb={3}>
									{(reminderIdx + 1) + " de " + reminders.length + ": "}
									{reminders[reminderIdx].content || ""}
								</Box>

								<Button variant={"contained"} color={"secondary"} sx={{marginRight: "1em"}}
										onClick={_ => setModalOpen(false)}>
									Cerrar
								</Button>
								{reminders.length > 1 && (
									<Button variant={"contained"}
											onClick={_ => setReminderIdx((reminderIdx + 1) % reminders.length)}>
										Ver otro
									</Button>
								)}
							</Box>
						</Box>
					</Modal>
				</>
			)}

		</AdminInner>
	);
};