import React from "react";
import {Vacation, VacationRestControllerApiFactory} from "arteco-api-client-ts";

import {FormContent} from "../../../../components/form/form_inputs/form_buttons";
import {FormInput} from "../../../../components/form/form_inputs/form_input";
import {Grid} from "@mui/material";
import FormAutocomplete from "../../../../components/form/form_inputs/form_autocomplete";
import {types} from "../../../../components/form/types";
import FormSelect from "../../../../components/form/form_inputs/form_select";
import {useForm, UseFormProps} from "../../../../components/form/form_model";
import {useApiManager} from "../../../../components/utils/api";

export const VacationEditPage = () => {
    const manager = useApiManager();
    const ctrl = manager.factory(VacationRestControllerApiFactory);

    const form = useForm({
        fetcher: (id) => ctrl.getVacation(id),
        updater: (bean) => ctrl.saveVacation(bean),
        remover: (id) => ctrl.deleteOrDismissVacation(id),
        factory: () => {
            return {
                imputationYear: new Date().getFullYear(),
            } as Vacation;
        },
    } as UseFormProps<Vacation>);

    return (
        <>
            <FormContent form={form} title={"Ausencias"}>
                <FormAutocomplete field={"person"} label={"Persona"} type={types.people} />
                <FormInput field={"title"} type={"text"} label={"Título"} />

                <Grid container spacing={3}>
                    <Grid item sm>
                        <FormInput field={"periodFrom"} type={"date"} label={"Desde"} />
                    </Grid>
                    <Grid item sm>
                        <FormInput field={"periodTo"} type={"date"} label={"Hasta"} />
                    </Grid>
                    <Grid item sm>
                        <FormInput
                            field={"imputationYear"}
                            type={"text"}
                            label={"Año de imputación"}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item sm>
                        <FormSelect field={"state"} label={"Estado"} type={types.vacationState}/>
                    </Grid>
                    <Grid item sm>
                        <FormSelect field={"type"} label={"Tipo"} type={types.vacationType}/>
                    </Grid>
                    <Grid item sm>
                        <FormAutocomplete field={"validator"} label={"Validador"} type={types.people}/>
                    </Grid>
                </Grid>
            </FormContent>
        </>
    );
};