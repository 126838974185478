export const employeeModalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
};


export const dedicationModalStyle = {...employeeModalStyle,
    width: "80%"
}