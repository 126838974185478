import {Grid, List, ListItem, ListItemText} from "@mui/material";
import {Document, EmpDocumentRestControllerApiFactory,} from "arteco-api-client-ts";
import {useApiManager} from "../../../components/utils/api";
import {dateFromToString} from "../../../components/utils/dates";
import {fileLocalSave} from "../../../components/form/form_inputs/form_file";
import AdminInner from "../../../components/layout/admin_inner";
import React, {useEffect, useState} from "react";

export const EmployeeDocumentListPage = () => {
    const apiManager = useApiManager();
    const documentApi = apiManager.factory(EmpDocumentRestControllerApiFactory);

    const [documents, setDocuments] = useState<Document[]>();

    useEffect(() => {
        apiManager.executeRaw(documentApi.listEmployeeDocuments()).then((resp) => setDocuments(resp.data));
    }, []);


    return (

        <AdminInner title={"Documentos para el empleado"}>
            <List>
                {documents &&
                    documents.map((doc, idx) => (
                        <ListItem
                            button
                            divider
                            key={idx}
                            onClick={() => {
                                if (!doc.id) throw new Error("No payroll id");
                                documentApi
                                    .viewEmployeeDocument(doc.id)
                                    .then((resp) => fileLocalSave(resp));
                            }}>
                            <ListItemText
                                secondary={dateFromToString(doc.modified)}
                                primary={doc.title}
                                key={idx + "-item-text"}
                            />
                        </ListItem>
                    ))}
            </List>
        </AdminInner>

    );
};