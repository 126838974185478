import AppList, {AppListConfig} from "../../list";
import {
    Applicant,
    ApplicantRestControllerApiFactory,
    Candidate,
    CandidateRestControllerApiFactory,
    Recruitment,
    RecruitmentRestControllerApiFactory,
} from "arteco-api-client-ts";
import {Grid} from "@mui/material";
import {useApiManager} from "../../../utils/api";
import React, {useEffect, useState} from "react";
import {COLOR_DISMISSED} from "../../colors";
import {useParams} from "react-router-dom";
import {dateToString} from "../../../utils/dates";
import {CustomNumberFormat} from "../../../utils/custom_number_format";
import {parsePathId} from "../../../form/form_utils";

const ApplicationsList = () => {
    const params = useParams();
    const recruitmentId = parsePathId(params, "recruitmentId", true);
    const candidateId = parsePathId(params, "candidateId", true);
    const apiManager = useApiManager();

    const applicantApi = apiManager.factory(ApplicantRestControllerApiFactory);
    const recruitmentApi = apiManager.factory(RecruitmentRestControllerApiFactory);
    const candidateApi = apiManager.factory(CandidateRestControllerApiFactory);

    const [recruitment, setRecruitment] = useState<Recruitment>();
    const [candidate, setCandidate] = useState<Candidate>();

    useEffect(() => {
        if (recruitmentId) {
            apiManager
                .executeRaw(recruitmentApi.getRecruitment(recruitmentId))
                .then((resp) => setRecruitment(resp.data));
        }
    }, [recruitmentId]);

    useEffect(() => {
        if (candidateId) {
            apiManager
                .executeRaw(candidateApi.getCandidate(candidateId))
                .then((resp) => setCandidate(resp.data));
        }
    }, [candidateId]);


    const listConfig: AppListConfig<Applicant> = {
        title:
            "Candidaturas " +
            (recruitment
                ? "del Proceso " + recruitment?.title
                : candidate
                    ? "de " + candidate?.surnames + ", " + candidate?.name
                    : ""),
        onClick: recruitment
            ? "/app/admin/recruitment/recruitment/" + recruitmentId + "/application"
            : "/app/admin/recruitment/candidate/" + candidateId + "/application",
        decorator: (bean: Applicant) => (
            <Grid container style={{color: bean.dismissed ? COLOR_DISMISSED : ""}}>
                <Grid item xs>
                    {candidateId ? bean.recruitment?.title : ""}
                    {!candidateId ? bean.candidate?.surnames + ", " + bean.candidate?.name : ""}
                </Grid>
                <Grid item xs>
                    {bean.state.name}
                </Grid>
                <Grid item xs>
                    {bean.candidate.type?.name || ""}
                </Grid>
                <Grid item xs title={"Salario Solicitado"}>
                    <CustomNumberFormat amount={bean.reqAnnualPayroll || bean.candidate.reqAnnualPayroll}/>
                </Grid>
                <Grid item xs title={"Puntos Candidatura"}>
                    {bean.rating || ""}
                </Grid>
                <Grid item xs title={"Puntos Prueba Técnica"}>
                    {bean.candidate.bestSolution?.points || ""}
                </Grid>
                <Grid item xs>
                    {bean.candidate.source?.label || ""}
                </Grid>

                <Grid item xs>
                    {candidateId ? dateToString(bean.nextEvent) : ""}
                    {!candidateId ? bean.comment ? bean.comment.substring(0, 50) : "" : ""}
                </Grid>
            </Grid>
        ),
        supplier: async (currentPage, size, term, showDismissed) => {
            return applicantApi.pageApplicant(
                apiManager.companyId,
                currentPage,
                size,
                undefined,
                candidateId,
                recruitmentId,
                term,
                showDismissed
            );
        },
    };

    return (
        <>
            <AppList config={listConfig}/>
        </>
    );
};

export default ApplicationsList;
