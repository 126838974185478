import NumberFormat from "react-number-format";
import {CSSProperties} from "react";


export interface CustomNumberFormatProps {
    amount?: number;
    style?: CSSProperties;
    scale?: number;
    thousandSeparator?: boolean
}

export const CustomNumberFormat = ({amount, style, scale = 2, thousandSeparator = true}: CustomNumberFormatProps) => {
    return (
        <>
            {amount &&
                <NumberFormat style={style} value={amount || 0}
                              displayType={'text'}
                              thousandSeparator={thousandSeparator}
                              decimalScale={scale} fixedDecimalScale={true}
                />
            }
        </>
    )
}