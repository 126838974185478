import * as React from 'react';
import {AppLayout} from "./components/layout/layout";
import {Outlet} from "react-router-dom";
import {Container, Typography} from "@mui/material";


export default function App() {
    return (
        <AppLayout>
            <Outlet/>
        </AppLayout>
    );
}

export const AppWelcomePage = () => {
    return (
        <>
            <Container maxWidth={"sm"} style={{textAlign: "center", padding: "3em"}}>
                <Typography variant={"h3"}>
                    Bienvenido a //Arteco
                </Typography>
                <p>Selecciona un rol de la barra de navegación superior</p>
            </Container>
        </>
    );
}