import React from "react";
import {Document, DocumentRestControllerApiFactory} from "arteco-api-client-ts";
import {FormContent} from "../../../../components/form/form_inputs/form_buttons";
import {FormInput} from "../../../../components/form/form_inputs/form_input";
import {Grid} from "@mui/material";
import FormFile from "../../../../components/form/form_inputs/form_file";
import {useForm, UseFormProps} from "../../../../components/form/form_model";
import {useApiManager} from "../../../../components/utils/api";

export const DocumentEditPage = () => {
    const apiManager = useApiManager();
    const ctrl = apiManager.factory(DocumentRestControllerApiFactory);
    const companyId = apiManager.companyId;

    const form = useForm({
        fetcher: (id) => ctrl.getDocument(id),
        updater: (bean) => ctrl.saveDocument(bean),
        remover: (id) => ctrl.deleteOrDismissDocument(id),
        factory: () => {
            return { company: { id: companyId } } as Document;
        },
    } as UseFormProps<Document>);

    return (
        <>
            <FormContent form={form} title={"Ficha de documento"}>
                <Grid container spacing={3}>
                    <Grid item sm>
                        <FormInput field={"title"} type={"text"} label={"Título"} />
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item sm>
                        <FormFile
                            field={"attachment"}
                            label={"Archivo"}
                            onDownload={() =>
                                apiManager.executeRaw(ctrl.viewDocument(form.getIdOrErr()))
                            }
                        />
                    </Grid>
                </Grid>
            </FormContent>
        </>
    );
};