import React, {useContext} from "react";
import {Alert, Box, Grid} from "@mui/material";
import {FormInput} from "../form_input";
import {fieldMarginBottom} from "../../form_constants";
import {FormContext} from "../form_buttons";

interface FormAddressProp {
    field: string;
    lockFields?: boolean;
}

export const FormAddress = ({field, lockFields = false}: FormAddressProp) => {

    const formContext = useContext(FormContext);
    const form = formContext.form;
    const modelField = form.field(field);
    if (!modelField.value()) {
        form.valueSet(field, {});
    }

    return (
        <Box className={["form-input", "form-input-" + field].join(" ")} mb={fieldMarginBottom}>
            {lockFields && (
                <Alert sx={{mb:2}} color={"info"}>El cambio de dirección debe ser notificado a RRHH</Alert>
            )}
            <Grid container spacing={3}>
                <Grid item sm>
                    <FormInput disabled={lockFields} field={field + ".address"} type={"text"} label={"Dirección de trabajo"}/>
                </Grid>
                <Grid item sm>
                    <FormInput disabled={lockFields} field={field + ".city"} type={"text"} label={"Ciudad"}/>
                </Grid>
                <Grid item sm>
                    <FormInput disabled={lockFields} field={field + ".province"} type={"text"} label={"Provincia"}/>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item sm>
                    <FormInput
                        disabled={lockFields}
                        field={field + ".postalCode"}
                        type={"text"}
                        label={"Código Postal"}
                    />
                </Grid>
                <Grid item sm>
                    <FormInput
                        disabled={lockFields}
                        field={field + ".countryCode"}
                        type={"text"}
                        label={"Código País"}
                    />
                </Grid>
                <Grid item sm>
                    <FormInput disabled={lockFields} field={field + ".country"} type={"text"} label={"País"}/>
                </Grid>
            </Grid>
        </Box>
    );
};